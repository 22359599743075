import ToastNotification from '../components/toast/index.vue'

export default {
  install: (app, options = {}) => {
    const toastComponent = app.component('ToastNotification', ToastNotification)

    app.config.globalProperties.$toastOptions = options

    app.config.globalProperties.$toast = {
      show(type, message, options = {}) {
        const mergedOptions = { ...app.config.globalProperties.$toastOptions, ...options }
        toastComponent.exposed.addToast(type, message, mergedOptions)
      },
      success(message, options = {}) {
        this.show('success', message, options)
      },
      info(message, options = {}) {
        this.show('info', message, options)
      },
      warning(message, options = {}) {
        this.show('warning', message, options)
      },
      error(message, options = {}) {
        this.show('error', message, options)
      },
    }

    app.provide('toast', app.config.globalProperties.$toast)
  },
}