<template>
  <sideModal ref="contentModal" modalClass="w-full lg:w-10/12 xl:w-5/12" :isHeader="true" :headerTitle="t('scheduleAssistant.components.content.sidebarTitle')" :isClosable="true" :closeController="isContentRefreshing || contentLoading" @closeErorr="responseHandlerService.handleWarning(t('scheduleAssistant.components.content.sidebarCannotBeClosed'))">
    <template v-slot:content>
      <div class="flex flex-col h-full">
        <div class="flex-grow overflow-auto thin-scrollbar p-4">
          <div v-if="contentLoading" class="h-full flex flex-grow items-center justify-center">
            <customLoading :loading="contentLoading"/>
          </div>
          <div v-else-if="contentError" class="h-full flex flex-grow items-center justify-center">
            <p class="text-red-500 text-center">
              {{ t('scheduleAssistant.components.content.contentLoadError') }} </p>
          </div>
          <div v-else-if="selectedContent" class="space-y-4" v-motion-fade>
            <imageComponent :imageUrls="selectedContent.imageUrls" :contentLoading="contentLoading" @uploadImage="handleImageUpload" @refreshImage="handleImageRefresh" class="w-full h-64 lg:h-80"/>
            <customInput :label="t('scheduleAssistant.components.content.titleLabel')" :disabled="true" v-model="selectedContent.title" containerClass="w-full" :description="t('scheduleAssistant.components.content.titleDescription')"/>
            <customInput :label="t('scheduleAssistant.components.content.categoryLabel')" :disabled="true" v-model="selectedContent.category" containerClass="w-full" :description="t('scheduleAssistant.components.content.categoryDescription')"/>
            <customInput :label="t('scheduleAssistant.components.content.contentLabel')" :disabled="true" v-model="selectedContent.content" type="textarea" :description="t('scheduleAssistant.components.content.contentDescription')" class="min-h-[150px]"/>
            <customInput :label="t('scheduleAssistant.components.content.metaDescriptionLabel')" :disabled="true" v-model="selectedContent.metaDescription" :description="t('scheduleAssistant.components.content.metaDescriptionDescription')"/>
            <customInput :label="t('scheduleAssistant.components.content.seoKeywordsLabel')" :disabled="true" v-model="seoKeywordsData" :description="t('scheduleAssistant.components.content.seoKeywordsDescription')"/>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer v-if="showFooter">
      <div class="flex justify-end items-center gap-4">
        <custom-button v-if="!store.state.automaticPublishing" :buttonText="t('scheduleAssistant.components.content.refreshButton')" :fullWidth="false" color="secondary" :isDisabled="isContentRefreshing" :disable-gray="true" :isLoading="isContentRefreshing" icon="refresh" :loading-text="t('scheduleAssistant.components.content.refreshing')" @click="refreshContent" size="sm"/>
        <customButton v-if="!store.state.automaticPublishing" :buttonText="t('scheduleAssistant.components.content.rejectButton')" :fullWidth="false" color="primary" :isDisabled="buttonLoading || isContentRefreshing" :disable-gray="true" :isLoading="buttonLoading" @click="showConfirmDialog = true" size="sm"/>
        <customButton v-if="!store.state.automaticPublishing" :buttonText="t('scheduleAssistant.components.content.confirmButton')" :fullWidth="false" color="secondary" :isDisabled="buttonLoading || isContentRefreshing" :disable-gray="true" :isLoading="buttonLoading" @click="confirm" size="sm"/>
      </div>
    </template>
  </sideModal>
  <customDialog v-model="showConfirmDialog" :title="t('scheduleAssistant.components.content.dialogTitle')" :message="t('scheduleAssistant.components.content.dialogMessage')" :showCancelButton="true" :cancelButtonText="t('scheduleAssistant.components.content.dialogCancelButton')" :confirmButtonText="t('scheduleAssistant.components.content.dialogConfirmButton')" @confirm="confirmDelete" @update:show="showConfirmDialog = $event" :show-note="true"/>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import { scheduleAssistant } from '@/networking/urlManager'
import SideModal from '@/components/modals/sideModal.vue'
import customButton from '@/components/buttons/customButton.vue'
import customInput from '@/components/inputs/customInput.vue'
import customLoading from '@/components/loadings/loading.vue'
import customDialog from '@/components/modals/customDialog.vue'
import imageComponent from '@/components/general/imageComponent.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const store = useStore()
const { t } = useI18n()

const emit = defineEmits(['confirmed', 'rejected', 'refreshed'])
const contentModal = ref(null)
const showConfirmDialog = ref(false)
const buttonLoading = ref(false)
const isContentRefreshing = ref(false)
const contentLoading = ref(false)
const selectedContent = ref(null)
const seoKeywordsData = ref('')
const contentError = ref(false)

const showFooter = computed(() => {
  return selectedContent.value && selectedContent.value.stateId === 1 || store.state.automaticPublishing
})

const getSeoKeywordsString = (seoKeywords) => {
  if (!seoKeywords) return ''
  return typeof seoKeywords === 'string' ? JSON.parse(seoKeywords).join(', ') : seoKeywords.join(', ')
}

const getImageUrls = (imageUrls) => {
  if (imageUrls && imageUrls.length > 0) return typeof imageUrls === 'string' ? JSON.parse(imageUrls) : imageUrls
}

const loadContent = async (rowId) => {
  contentLoading.value = true
  contentError.value = false
  try {
    const response = await axios.get(scheduleAssistant.getContent, { params: { assetId: store.state.assetId, rowId: rowId } })

    selectedContent.value = response.data.data
    seoKeywordsData.value = getSeoKeywordsString(selectedContent.value.seoKeywords)
    selectedContent.value.imageUrls = getImageUrls(selectedContent.value.imageUrl)
  } catch (error) {
    responseHandlerService.handleError(error,t('scheduleAssistant.components.content.loadContentError'))
    contentError.value = true
  } finally {
    contentLoading.value = false
  }
}

const handleImageUpload = async (file) => {
  const formData = new FormData()
  formData.append('image', file)
  formData.append('assetId', store.state.assetId)
  formData.append('contentId', selectedContent.value.id)

  try {
    const response = await axios.post(scheduleAssistant.uploadImage, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
    selectedContent.value.imageUrls = [response.data.imageUrl]
    responseHandlerService.handleSuccess(t('scheduleAssistant.components.content.imageUploadSuccess'))
  } catch (error) {
    responseHandlerService.handleError(error,t('scheduleAssistant.components.content.imageUploadError'))
  }
}

const handleImageRefresh = async () => {
  try {
    const response = await axios.post(scheduleAssistant.refreshImage, { category: selectedContent.value.category, assetId: store.state.assetId, contentId: selectedContent.value.id })
    selectedContent.value.imageUrls = response.data.data.imageUrls
    responseHandlerService.handleSuccess(t('scheduleAssistant.components.content.imageRefreshSuccess'))
  } catch (error) {
    responseHandlerService.handleError(error,t('scheduleAssistant.components.content.refreshImageError'))
  }
}

const refreshContent = async () => {
  isContentRefreshing.value = true
  contentLoading.value = true
  try {
    const response = await axios.post(scheduleAssistant.refreshContent, { id: selectedContent.value.id, assetId: store.state.assetId })
    selectedContent.value = response.data.data
    seoKeywordsData.value = getSeoKeywordsString(selectedContent.value.seoKeywords)
    selectedContent.value.imageUrls = getImageUrls(selectedContent.value.imageUrls)
    emit('refreshed', { content: selectedContent.value, result: true })
  } catch (error) {
    responseHandlerService.handleError(error,t('scheduleAssistant.components.content.refreshContentError'))
  } finally {
    isContentRefreshing.value = false
    contentLoading.value = false
  }
}

const confirm = async () => {
  buttonLoading.value = true
  try {
    await axios.post(scheduleAssistant.confirmContent, { id: selectedContent.value.id, assetId: store.state.assetId })
    emit('confirmed', { id: selectedContent.value.id, result: true })
    contentModal.value.close()
  } catch (error) {
    responseHandlerService.handleError(error,t('scheduleAssistant.components.content.confirmContentError'))
    emit('confirmed', { id: selectedContent.value.id, result: false })
  } finally {
    buttonLoading.value = false
  }
}

const confirmDelete = async () => {
  buttonLoading.value = true
  try {
    await axios.delete(scheduleAssistant.rejectContent, { params: { id: selectedContent.value.id, assetId: store.state.assetId } })
    emit('rejected', { id: selectedContent.value.id, result: true })
    showConfirmDialog.value = false
    contentModal.value.close()
  } catch (error) {
    responseHandlerService.handleError(error,t('scheduleAssistant.components.content.rejectContentError'))
    emit('rejected', { id: selectedContent.value.id, result: false })
  } finally {
    buttonLoading.value = false
  }
}

const show = (rowId) => {
  selectedContent.value = null
  contentLoading.value = false
  contentModal.value.show()
  loadContent(rowId)
}

defineExpose({ show })
</script>