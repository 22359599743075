<template>
  <div class="p-8 w-full">
    <stepController :steps="steps.filter(r => r.isActive)" :type="1"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { markRaw } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'

import stepController from '@/components/navigation/stepController.vue'
import informationStep from './components/information/index.vue'
import analyseStep from './components/analyse/index.vue'
import introductionStep from './components/introduction/index.vue'
import sourcesStep from './components/source/index.vue'

export default {
  name: 'createEntity',
  components: {
    stepController,
  },
  data() {
    return {
      steps: [
        {
          component: introductionStep,
          ref: 'introduction',
          label: this.$t('assets.components.create.steps.introduction'),
          step: 1,
          isActive: true,
        },
        {
          component: sourcesStep,
          ref: 'sources',
          label: this.$t('assets.components.create.steps.sources'),
          buttonText: this.$t('assets.components.create.steps.addSources'),
          buttonLoadingText: this.$t('assets.components.create.steps.addingSources'),
          step: 2,
          isActive: this.$store.state.assetServiceType === 1,
        },
        {
          component: informationStep,
          ref: 'information',
          label: this.$t('assets.components.create.steps.information'),
          step: 3,
          isActive: this.$store.state.assetServiceType === 1,
        },
        {
          component: analyseStep,
          ref: 'analyse',
          label: this.$t('assets.components.create.steps.analyse'),
          step: 4,
          isActive: true,
        },
      ].map((step) => markRaw(step)),
    }
  },
  methods: {
    ...mapActions(['setAssetInputLoading', 'setIntroductionList']),

    async getAsset(id) {
      await this.setAssetInputLoading(true)
      try {
        const res = await axios.get(assets.get, { params: { assetId: id } })
        const { asset, locations } = res.data.data

        await this.setIntroductionList({
          name: asset.name || '',
          website: asset.website || '',
          languageId: asset.languageId || 1,
          searchEngineLocations: {
            google: locations.google || [],
            bing: locations.bing || [],
            yandex: locations.yandex || [],
          },
          communicationId: asset.communicationId || 0,
        })
      } catch (error) {
        console.error(error)
      } finally {
        await this.setAssetInputLoading(false)
      }
    },
  },
  created() {
    const id = this.$route.params.id
    if (id) {
      this.getAsset(id)
    } else {
      console.error(this.$t('assets.components.create.errors.noIdParameter'))
    }
  },
}
</script>
