<template>
  <VueFinalModal v-model="isModalOpen" :clickToClose="!loading" :escToClose="!loading" @closed="handleModalClosed" class="flex justify-center items-center" contentClass="flex flex-col max-w-xl bg-white rounded-lg shadow-xl w-[600px]" contentTransition="vfm-slide-down">
    <div class="h-full flex flex-col w-full">
      <div class="w-full flex items-center justify-between sticky top-0 bg-secondary z-10 px-6 py-4 rounded-t-lg">
        <h2 class="text-xl font-bold text-white">
          {{ t('assets.components.create.components.serviceTypeModal.title') }} </h2>
      </div>

      <div class="flex flex-col space-y-4 w-full p-6 max-h-[550px] overflow-auto thin-scrollbar">
        <div class="flex flex-col space-y-4">
          <div v-for="(type, index) in assetTypes" :key="index" class="py-4 px-6 border-2 rounded-lg cursor-pointer w-full flex justify-between items-center" :class="{
              'border-primary shadow-lg': serviceType === type.value,
              'border-gray-300': serviceType !== type.value,
              'cursor-not-allowed opacity-50': type.disabled,
            }" @click="handleTypeClick(type)">
            <div class="flex items-center">
              <span class="material-symbols-outlined text-primary mr-2">
                {{ type.icon }}
              </span>
              <div>
                <h4 class="font-semibold">{{
                    t(`assets.components.create.components.serviceTypeModal.assetTypes.${type.text.toLowerCase()}.text`)
                  }} </h4>
                <p class="text-sm text-gray-500">{{
                    t(`assets.components.create.components.serviceTypeModal.assetTypes.${type.text.toLowerCase()}.description`)
                  }} </p>
              </div>
            </div>
            <div>
              <span v-if="serviceType === type.value" class="material-symbols-outlined text-primary">
                radio_button_checked
              </span> <span class="material-symbols-outlined text-gray-500" v-else>
                radio_button_unchecked
              </span>
            </div>
          </div>
        </div>

        <div class="flex items-center justify-between space-x-2 ">
          <customButton color="primary" type="button" variation="outline" :buttonText="t('assets.components.create.components.serviceTypeModal.buttons.cancel')" :isDisabled="false" :fullWidth="true" @click="$emit('update:modelValue', false)"/>
          <customButton @click="proceedToNextStep" :buttonText="t( 'assets.components.create.components.serviceTypeModal.buttons.nextStep')" :isDisabled="false" :additionalButtonClass="loading ? '!bg-red-500' : '!bg-secondary'" :fullWidth="true"/>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { VueFinalModal } from 'vue-final-modal'
import customButton from '@/components/buttons/customButton.vue'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
})

const emit = defineEmits(['update:modelValue', 'opened'])

const { t } = useI18n()

const store = useStore()
const router = useRouter()
const loading = ref(false)
const serviceType = ref(1)
const assetTypes = ref([
  { value: 1, text: 'Service', icon: 'linked_services', description: 'Service eklemek için', disabled: false },
  { value: 2, text: 'Crawler', icon: 'travel_explore', description: 'Crawler ile taramak için', disabled: false },
])

const isModalOpen = ref(props.modelValue)

watch(() => props.modelValue, (newValue) => {
  isModalOpen.value = newValue
})

watch(isModalOpen, (newValue) => {
  emit('update:modelValue', newValue)
})

const proceedToNextStep = async () => {
  await store.dispatch('setAssetServiceType', serviceType.value)
  emit('update:modelValue', false)
  await store.dispatch('resetAssetData')
  await router.push('/assets/create')
}


const handleModalClosed = () => {
  emit('update:modelValue', false)
}

const handleTypeClick = (type) => {
  if (type.disabled) {
    responseHandlerService.handleWarning(t('assets.components.create.components.serviceTypeModal.warnings.comingSoon'))
  } else {
    serviceType.value = type.value
  }
}
</script>