<template>
  <div :class="['w-full', containerClass]">
    <label v-if="label" :class="['text-sm font-semibold mb-1 block text-gray-800', labelClass]"> {{
        label
      }}<span v-if="required" class="text-primary">*</span> </label>
    <p v-if="description" :class="['text-xs mb-2 text-gray-500', descriptionClass]">
      {{ description }} </p>

    <div :class="['border rounded-lg p-3 bg-white', borderColorClass]">
      <div class="flex justify-between items-center mb-2">
        <span class="text-xs font-semibold text-primary">
          {{ visibleTags.length }}
          <span class="text-gray-500 font-light">
            {{ t('globalComponents.tagInput.tagsAdded') }}
          </span>
        </span>
        <button v-if="!isLoading && visibleTags.length > initialDisplayCount" @click="toggleShowAllTags" class="text-primary text-sm hover:underline">
          {{ showAllTags ? t('globalComponents.tagInput.showLess') : t('globalComponents.tagInput.showMore') }}
        </button>
      </div>

      <div :class="[
          'flex flex-wrap gap-2 mb-3 overflow-y-auto thin-scrollbar px-2',
          {'max-h-40': !showAllTags, 'max-h-80': showAllTags}
        ]">
        <template v-if="isLoading">
          <div v-for="i in 5" :key="i" class="animate-pulse flex items-center justify-between rounded-full px-2 py-1 bg-gray-200 w-24 h-6"></div>
        </template>
        <template v-else>
          <div v-for="(tag, index) in displayedTags" :key="tag.id || index" :class="[
              'flex items-center justify-between rounded-full px-2 py-1 text-xs font-medium',
              tagColorClass,
              { 'opacity-50': tag.state === 3 }
            ]">
            <span class="block text-black/80">{{ tag.name }}</span>
            <button v-if="tag.state !== 3" @click="removeTag(tag, index)" type="button" class="text-sm focus:outline-none flex items-center justify-center">
              <span class="material-symbols-outlined !text-[14px] text-lobster-600">close</span>
            </button>
          </div>
        </template>
      </div>

      <div class="flex items-center gap-2 justify-center">
        <div class="flex-1">
          <custom-input icon="add" :show-icon="true" v-model="tagName" @keyup.enter="addTag" :placeholder="t('globalComponents.tagInput.addKeyword')"/>
        </div>
        <button v-if="showSearchandFileUpload" @click="showConfirmDialog = true" class="rounded-md p-2 flex items-center justify-center focus:outline-none">
          <span class="material-symbols-outlined text-lobster-500 !text-[26px]">delete_forever</span>
        </button>
      </div>
    </div>
  </div>

  <customDialog v-if="showConfirmDialog" v-model="showConfirmDialog" :title="t('globalComponents.tagInput.deleteDialog.title')" :message="t('globalComponents.tagInput.deleteDialog.message')" :showCancelButton="true" :cancelButtonText="t('globalComponents.tagInput.deleteDialog.cancelButton')" :confirmButtonText="t('globalComponents.tagInput.deleteDialog.confirmButton')" @confirm="confirmDelete" @decline="showConfirmDialog = false"/>
</template>

<script setup>
import { ref, computed, watch, toRaw } from 'vue'
import { useI18n } from 'vue-i18n'
import customDialog from '@/components/modals/customDialog.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import CustomInput from '@/components/inputs/customInput.vue'

const props = defineProps({
  modelValue: { type: Array, default: () => [] },
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  isStateMode: { type: Boolean, default: true },
  maxCount: { type: Number, default: 200 },
  containerClass: { type: String, default: '' },
  labelClass: { type: String, default: '' },
  descriptionClass: { type: String, default: '' },
  showSearchandFileUpload: { type: Boolean, default: false },
  secondary: { type: Boolean, default: false },
  color: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary', 'indigo'].includes(value),
  },
  isLoading: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue'])

const { t } = useI18n()

const tagName = ref('')
const tags = ref([])
const showConfirmDialog = ref(false)
const showAllTags = ref(false)
const initialDisplayCount = 10

const colorClasses = computed(() => ({
  primary: {
    border: 'border-gray-300',
    input: 'focus:ring-primary focus:border-primary',
    button: 'bg-primary hover:bg-primary/90 focus:ring-primary text-white',
    tag: 'bg-lobster-100 text-primary',
    delete: 'bg-lobster-500 hover:bg-lobster-600 focus:ring-lobster-400 text-white',
  },
  secondary: {
    border: 'border-secondary/20',
    input: 'focus:ring-secondary focus:border-secondary',
    button: 'bg-secondary hover:bg-secondary/90 focus:ring-secondary text-white',
    tag: 'bg-secondary/20 text-secondary',
    delete: 'bg-lobster-500 hover:bg-lobster-600 focus:ring-lobster-400 text-white',
  },
  indigo: {
    border: 'border-indigo/20',
    input: 'focus:ring-indigo focus:border-indigo',
    button: 'bg-indigo hover:bg-indigo/90 focus:ring-indigo text-white',
    tag: 'bg-indigo/20 text-indigo',
    delete: 'bg-lobster-500 hover:bg-lobster-600 focus:ring-lobster-400 text-white',
  },
}))

const borderColorClass = computed(() => colorClasses.value[props.color].border)
const tagColorClass = computed(() => colorClasses.value[props.color].tag)
const deleteButtonColorClass = computed(() => colorClasses.value[props.color].delete)

const visibleTags = computed(() => tags.value.filter(tag => tag.state !== 3))

const displayedTags = computed(() => {
  return showAllTags.value ? visibleTags.value : visibleTags.value.slice(0, initialDisplayCount)
})

watch(
    () => props.modelValue,
    (newVal) => {
      tags.value = toRaw(newVal).map(tag => ({ ...tag, state: tag.state || 1 })) || []
    },
    { immediate: true, deep: true },
)

const updateModelValue = () => {
  emit('update:modelValue', tags.value.filter(tag => tag.state !== 3))
}

const addTag = () => {
  if (tagName.value.length < 2) {
    responseHandlerService.handleError(t('globalComponents.tagInput.errors.minCharacterLength'))
    return
  }
  if (props.maxCount && visibleTags.value.length >= props.maxCount) {
    responseHandlerService.handleError(t('globalComponents.tagInput.errors.maxKeywordLimit', { max: props.maxCount }))
    return
  }
  if (tagName.value && !tags.value.some((tag) => tag.name.toLowerCase() === tagName.value.toLowerCase() && tag.state !== 3)) {
    tags.value.push({ name: tagName.value, state: 2 })
    tagName.value = ''
    updateModelValue()
  } else responseHandlerService.handleError(t('globalComponents.tagInput.errors.keywordAlreadyAdded'))
}

const removeTag = (tag, index) => {
  if (props.isStateMode) {
    if (tag.state === 1) tags.value[index] = { ...tag, state: 3 }
    else if (tag.state === 2) tags.value.splice(index, 1)
  } else tags.value.splice(index, 1)

  updateModelValue()
}

const confirmDelete = () => {
  if (props.isStateMode) tags.value = tags.value.map(tag => tag.state === 1 ? { ...tag, state: 3 } : tag)
  else tags.value = []

  showConfirmDialog.value = false
  updateModelValue()
}

const toggleShowAllTags = () => {
  showAllTags.value = !showAllTags.value
}
</script>