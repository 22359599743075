<template>
  <div class="component-layout">
    <div class="max-w-7xl mx-auto bg-white p-4 rounded-md shadow-sm">
      <div class="flex justify-between items-center mb-4">
        <div>
          <h1 class="text-sm font-semibold text-secondary mb-1">{{ t('entity.components.newContent.title') }}</h1>
          <p class="text-xs text-gray-500">{{ t('entity.components.newContent.description') }}</p>
        </div>
        <CustomButton @click="openAiSuggestionModal('all')" color="indigo" icon="smart_toy" :button-text="t('entity.components.newContent.getAiSuggestion')" size="sm" :show-icon="true"/>
      </div>

      <div class="flex flex-col lg:flex-row gap-4">
        <div class="w-full lg:w-1/2 space-y-4">
          <CustomInput v-model="content.title" :label="t('entity.components.newContent.titleLabel')" :placeholder="t('entity.components.newContent.titlePlaceholder')" :loading="contentLoading" :showAiIcon="content.title !== ''" @iconClicked="openReviseModal('title')" labelIcon="title" labelIconClass="text-primary !text-[20px]"/>

          <CustomInput v-model="content.category" :label="t('entity.components.newContent.categoryLabel')" :placeholder="t('entity.components.newContent.categoryPlaceholder')" :loading="contentLoading" :showAiIcon="content.category !== ''" @iconClicked="openReviseModal('category')" labelIcon="category"/>

          <CustomInput v-model="content.body" type="textarea" :label="t('entity.components.newContent.bodyLabel')" :placeholder="t('entity.components.newContent.bodyPlaceholder')" :loading="contentLoading" :showAiIcon="content.body !== ''" @iconClicked="openReviseModal('body')" text-area-height="h-56" labelIcon="description"/>

          <CustomInput v-model="content.keywords" :label="t('entity.components.newContent.keywordsLabel')" :placeholder="t('entity.components.newContent.keywordsPlaceholder')" :loading="contentLoading" :showAiIcon="content.keywords !== ''" @iconClicked="openReviseModal('keywords')" labelIcon="key"/>

          <CustomInput v-model="content.metaDescription" :label="t('entity.components.newContent.metaDescriptionLabel')" :placeholder="t('entity.components.newContent.metaDescriptionPlaceholder')" :loading="contentLoading" :showAiIcon="content.metaDescription !== ''" @iconClicked="openReviseModal('metaDescription')" labelIcon="analytics"/>
        </div>

        <div class="w-full lg:w-1/2 space-y-4">
          <div class="bg-gray-50 rounded-md p-3">
            <h2 class="text-sm font-medium text-secondary flex items-center mb-2">
              <span class="material-symbols-outlined !text-[20px] mr-2 text-primary">image</span> {{ t('entity.components.newContent.imagesLabel') }} </h2>
            <ImageComponent :image-urls="content.images" :content-loading="contentLoading" :is-image-refreshable="true" :is-image-uploadable="false" @refresh-image="(image, index) => openReviseModal('image', index)" @upload-image="handleImageUpload"/>
          </div>

          <div class="bg-gray-50 rounded-md p-3">
            <h2 class="text-sm font-medium text-secondary flex items-center mb-2">
              <span class="material-symbols-outlined !text-[20px] mr-2 text-primary">lightbulb</span> {{ t('entity.components.newContent.tipsLabel') }} </h2>
            <ul class="text-xs text-gray-600 list-disc list-inside space-y-1">
              <li>{{ t('entity.components.newContent.tip1') }}</li>
              <li>{{ t('entity.components.newContent.tip2') }}</li>
              <li>{{ t('entity.components.newContent.tip3') }}</li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-4 flex justify-between items-center">
        <CustomButton @click="resetForm" color="accent" icon="restart_alt" :button-text="t('entity.components.newContent.resetForm')" size="sm" :show-icon="true"/>
        <CustomButton @click="shareContent" color="primary" icon="share" :button-text="t('entity.components.newContent.shareContent')" :loading-text="t('entity.components.newContent.contentIsSharing')" :is-disabled="!isFormValid " :is-loading="sharing" size="sm" :show-icon="true"/>
      </div>
    </div>
    <NewContentModal v-model="showNewContentModal" :field-to-suggest="fieldToSuggest" :is-trend-content="isTrendContent" :trend-keyword="trendKeyword" :trend-url="trendUrl" @response="handleSuggestionResponse" @content-loading="setContentLoading" @tags="setKeywords" @reset="resetForm"/>
    <ReviseContentModal v-model="showReviseModal" :selected-message="selectedMessage" :selected-type="selectedType" :selected-data="selectedData" :entity-id="entityId" :module-type-id="2" @confirm="handleReviseConfirm"/>
  </div>
</template>

<script setup>
import { ref, reactive, computed, nextTick, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import CustomButton from '@/components/buttons/customButton.vue'
import CustomInput from '@/components/inputs/customInput.vue'
import ImageComponent from '@/components/general/imageComponent.vue'
import NewContentModal from './components/newContentModal.vue'
import ReviseContentModal from '../reviseContentModal.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const store = useStore()
const router = useRouter()
const route = useRoute()

const showNewContentModal = ref(false)
const showReviseModal = ref(false)
const contentLoading = ref(false)
const saving = ref(false)
const sharing = ref(false)
const fieldToSuggest = ref('')
const selectedMessage = ref('')
const selectedType = ref('')
const selectedData = ref('')
const entityId = ref('')
const contentSaved = ref(false)
const isTrendContent = ref(false)
const trendKeyword = ref('')
const trendUrl = ref('')

const content = reactive({
  title: '',
  category: '',
  body: '',
  keywords: '',
  metaDescription: '',
  images: [],
})

const contentCategory = {
  title: 1,
  category: 2,
  body: 3,
  keywords: 4,
  metaDescription: 6,
  image: 5,
}

const isFormValid = computed(() => {
  return Object.values(content).every(value => {
    if (Array.isArray(value)) return value.length > 0
    return value.trim() !== ''
  })
})

onMounted(() => {
  const { keyword, url } = route.query
  if (keyword && url) {
    trendKeyword.value = keyword
    trendUrl.value = url
    isTrendContent.value = true
    openAiSuggestionModal('all')
  }
})

const resetForm = () => {
  Object.keys(content).forEach(key => {
    if (Array.isArray(content[key])) {
      content[key] = []
    } else {
      content[key] = ''
    }
  })
  contentSaved.value = false
  entityId.value = ''
  trendKeyword.value = ''
  trendUrl.value = ''
  isTrendContent.value = false
  clearUrlParams()
}

const clearUrlParams = () => {
  const currentQuery = { ...route.query }
  delete currentQuery.keyword
  delete currentQuery.url
  router.replace({ query: currentQuery })
}


const shareContent = async () => {
  sharing.value = true
  try {
    const response = await axios.post(entity.add, {
      assetId: store.state.assetId,
      entityId: entityId.value,
      title: content.title,
      category: content.category,
      content: content.body,
      imageUrls: content.images,
      metaDescription: content.metaDescription,
      seoKeywords: content.keywords,
    })
    entityId.value = response.data.entityId || response.data.optimizeId
    contentSaved.value = true
    responseHandlerService.handleSuccess(t('entity.components.newContent.responseHandlers.saveSuccess'))
    await router.push({ query: { tab: 'contents' } })
  } catch (error) {
    responseHandlerService.handleError(error, t('entity.components.newContent.responseHandlers.saveError'))
  } finally {
    sharing.value = false
  }
}

const openAiSuggestionModal = (field) => {
  fieldToSuggest.value = field
  showNewContentModal.value = true
}

const openReviseModal = (field, imageIndex = null) => {
  if (field === 'image') {
    selectedMessage.value = content.images
    selectedType.value = contentCategory.image
    selectedData.value = { imageIndex, imageUrls: content.images, keywords: content.keywords, content: content.body }
  } else {
    selectedMessage.value = content[field]
    selectedType.value = contentCategory[field]
    selectedData.value = { field }
  }
  showReviseModal.value = true
}

const handleSuggestionResponse = (responseData) => {
  nextTick(() => {
    if (responseData.contentDetail) {
      const { contentDetail, entityId: newEntityId } = responseData

      if (newEntityId) entityId.value = newEntityId

      if (fieldToSuggest.value === 'all' || fieldToSuggest.value === 'title') {
        content.title = contentDetail.title || content.title
      }
      if (fieldToSuggest.value === 'all' || fieldToSuggest.value === 'category') {
        content.category = contentDetail.category || content.category
      }
      if (fieldToSuggest.value === 'all' || fieldToSuggest.value === 'body') {
        content.body = contentDetail.content || content.body
      }
      if (fieldToSuggest.value === 'all' || fieldToSuggest.value === 'metaDescription') {
        content.metaDescription = contentDetail.metaDescription || content.metaDescription
      }
      if (fieldToSuggest.value === 'all' || fieldToSuggest.value === 'keywords') {
        content.keywords = contentDetail.seoKeywords && Array.isArray(contentDetail.seoKeywords) ?
            contentDetail.seoKeywords.map((k) => k).join(', ') : contentDetail.seoKeywords
      }
      if (fieldToSuggest.value === 'all' && contentDetail.pictureUrls) {
        content.images = contentDetail.pictureUrls
      }
    }
  })
}

const handleReviseConfirm = (responseData) => {
  if (responseData && responseData.data) {
    const { data, field } = responseData
    if (field === 'image') {
      content.images = data.imageUrls || content.images
      content.body = data.content || content
      content.keywords = data.keywords && Array.isArray(content.keywords) ? content.keywords.map(k => k).join(', ') : content.keywords
    } else {
      content[field] = data[field] || content[field]
    }
    if (responseData.optimizeId) {
      entityId.value = responseData.optimizeId
    }
  }
}

const setContentLoading = (val) => {
  contentLoading.value = val
}

const setKeywords = (val) => {
  content.keywords = val.join(', ')
}

const handleImageUpload = (file) => {
  content.images.push(URL.createObjectURL(file))
}
</script>