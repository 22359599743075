<template>
  <div :class="[containerClass, additionalContainerClass]">
    <canvas ref="canvas" width="60" height="60"></canvas>
    <p v-if="showLoadingText" :class="loadingTextClass">{{ loadingText }}</p>
  </div>
</template>

<script>
import { Rive, Layout } from '@rive-app/canvas'

export default {
  name: 'Loading',
  props: {
    src: {
      type: String,
      required: false,
      default: '/main.riv',
    },
    loadingText: {
      type: String,
      default: 'Yükleniyor',
    },
    showLoadingText: {
      type: Boolean,
      default: false,
    },
    fit: {
      type: String,
      default: 'contain',
    },
    alignment: {
      type: String,
      default: 'center',
    },
    containerClass: {
      type: String,
      default: 'flex flex-col justify-center items-center flex-grow h-full w-full',
    },
    additionalContainerClass: {
      type: String,
      default: '',
    },
    loadingTextClass: {
      type: String,
      default: 'font-semibold text-gray-500 text-sm',
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    rive: null,
  }),
  methods: {
    initRive() {
      if (this.rive || !this.loading) return

      // noinspection JSCheckFunctionSignatures
      this.rive = new Rive({
        canvas: this.$refs.canvas,
        src: this.src,
        layout: new Layout({
          fit: this.fit,
          alignment: this.alignment,
        }),
        artboard: 'loading',
        autoplay: true,
        onPause: () => {
          this.rive.play()
        },
      })

      this.rive.on('complete', () => {
        this.rive.play()
      })
    },
    destroyRive() {
      if (this.rive) {
        this.rive.pause()
        this.rive = null
      }
    },
  },
  watch: {
    loading(newVal) {
      if (newVal) {
        this.initRive()
      } else {
        this.destroyRive()
      }
    },
  },
  mounted() {
    this.initRive()
  },
}
</script>