<template>
  <div class="w-full bg-white flex items-center justify-center">
    <div class="flex flex-col justify-center items-center space-y-10 w-full">
      <img :src="require('@/assets/logo/logoA.svg')" alt="logo" class="h-20"/>

      <form @submit.prevent="handleResetPassword" class="space-y-4 w-3/4 lg:w-2/4">
        <div class="flex flex-col w-full space-y-4">
          <customInput icon="passkey" :show-icon="true" type="password" v-model="newPassword" :placeholder="t('auth.resetPassword.newPasswordPlaceholder')" additional-input-class="bg-slate-100 border-none" size="md"/>

          <customInput icon="passkey" :show-icon="true" type="password" v-model="confirmPassword" :placeholder="t('auth.resetPassword.confirmPasswordPlaceholder')" additional-input-class="bg-slate-100 border-none" size="md"/>
        </div>

        <div class="flex justify-end">
          <a @click="router.push('/')" class="text-xs text-primary hover:underline cursor-pointer"> {{ t('auth.backToLogin') }} </a>
        </div>

        <customButton type="submit" :buttonText="t('auth.resetPassword.resetPasswordButton')" :isLoading="isLoading" :loadingText="t('auth.resetPassword.resettingPassword')" :fullWidth="true" color="secondary" size="md"/>
      </form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { auth } from '@/networking/urlManager'
import customButton from '@/components/buttons/customButton.vue'
import customInput from '@/components/inputs/customInput.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const newPassword = ref('')
const confirmPassword = ref('')
const isLoading = ref(false)

const handleResetPassword = async () => {
  if (newPassword.value !== confirmPassword.value) {
    responseHandlerService.handleError(t('auth.resetPassword.passwordsDoNotMatch'))
    return
  }

  isLoading.value = true

  try {
    await axios.post(auth.resetPassword, {
      token: route.query.token,
      newPassword: newPassword.value,
    })

    responseHandlerService.handleSuccess(t('auth.resetPassword.passwordResetSuccess'))
    router.push('/')
  } catch (err) {
    responseHandlerService.handleError(err, t('auth.error.resetPassword'))
  } finally {
    isLoading.value = false
  }
}

</script>