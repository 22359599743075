<template>
  <span :class="computedIconClasses">
    {{ icon }}
  </span>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    required: true,
  },
  iconClass: {
    type: String,
    default: '',
  },
})

const computedIconClasses = computed(() => {
  return [
    'material-symbols-outlined',
    props.iconClass,
  ]
})
</script>