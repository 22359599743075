<template>
  <div class="component-layout bg-white p-6 rounded-lg shadow-lg" style="height: 65vh;">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-8 h-full">
      <!-- Sol Sütun: Açıklama Giriş Formu -->
      <div class="bg-white p-6 rounded-md shadow-md flex flex-col justify-between">
        <div>
          <h3 class="text-xl font-semibold text-gray-800 mb-4">Video Oluştur</h3>
          <div class="mb-4">
            <custom-input label="Açıklama" type="textarea" description="Videoda geçmesini istediğiniz konu için açıklama girin" v-model="description" placeholder="Video için açıklama girin" text-area-height="h-[30vh] resize-none"/>
          </div>
        </div>
        <CustomButton :buttonText="'Video Oluştur'" :isLoading="isGenerating" :isDisabled="!canGenerate" @click="generateVideo" color="primary" variation="solid" :fullWidth="true" size="sm"/>
      </div>

      <!-- Sağ Sütun: Video Önizleme -->
      <div class="bg-white rounded-md shadow-md flex flex-col max-h-[calc(65vh-3rem)]">
        <div class="flex justify-between items-center p-4 border-b">
          <h3 class="text-xl font-semibold text-gray-800">Video Önizleme</h3>
        </div>
        <div class="flex-grow overflow-y-auto thin-scrollbar">
          <div v-if="videoUrl" class="p-4">
            <video :src="videoUrl" controls class="w-full h-auto rounded-lg shadow-md" preload="metadata">
              Tarayıcınız video etiketini desteklemiyor.
            </video>
            <p class="mt-4 text-gray-700 whitespace-pre-line">
              {{ description }} </p>
          </div>
          <div v-else-if="isGenerating" class="flex items-center justify-center h-full">
            <div class="text-center">
              <div class="inline-block animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-gray-900"></div>
              <p class="mt-2 text-gray-600">Video oluşturuluyor...</p>
            </div>
          </div>
          <div v-else class="flex items-center justify-center h-full text-gray-500 italic p-4">
            Video oluşturulduktan sonra burada görüntülenecek
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import CustomButton from '@/components/buttons/customButton.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import CustomInput from '@/components/inputs/customInput.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const description = ref('')
const videoUrl = ref('')
const isGenerating = ref(false)

const canGenerate = computed(() => description.value.trim().length > 0)

const generateVideo = async () => {
  isGenerating.value = true
  videoUrl.value = ''
  try {
    const response = await axios.post(entity.createVideo, {
      request: description.value,
    })
    videoUrl.value = response.data.data
  } catch (error) {
    responseHandlerService.handleError(error)
  } finally {
    isGenerating.value = false
  }
}
</script>