<template>
  <div class="overflow-auto h-full w-full p-4">
    <ul class="list-none cursor-pointer" v-if="histories && histories.length > 0">
      <li v-for="(content, index) in histories"
          :key="index"
          @click="onclick(content)"
          class="border-b border-gray-200 py-2 flex justify-between items-center hover:bg-gray-300/10 transition-all duration-300 p-1">
        <div class="flex flex-col space-y-1">
          <p class="font-semibold text-ellipsis overflow-hidden">
            <span class="text-gray-950 text-xs">Başlık:</span>
            {{ content.title }}
          </p>
          <p class="text-xs text-primary font-semibold">
            <span class="text-gray-950">Durum:</span>
            {{ getContentType(content.entityTypeId) }}
          </p>
          <p class="text-xs text-primary font-semibold" v-if="content.revisedContetId">
            <span class="text-gray-950">Yapılan Değişiklikler:</span>
            {{ getRevizedType(content.revisedContetId) }} revize edildi
          </p>
        </div>
        <button @click.stop="onclick(content)"
                class="text-primary py-2 px-4 ">
          Seç
        </button>
      </li>
    </ul>
    <div v-else class="w-full flex items-center">
      <p class="font-semibold">
        İçerik geçmişinde veri bulunamadı.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "content-history-list",
  props: {
    histories: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      contentCategory: {
        title: 1,
        category: 2,
        content: 3,
        seoKeyword: 4,
        image: 5,
        metaDescription: 6,
      }
    }
  },
  methods: {
    getContentType(entityTypeId) {
      switch (entityTypeId) {
      case 1:
        return 'Yeni İçerik';
      case 2:
        return 'Mevcut İçerik';
      case 3:
        return 'Optimize Edilmiş İçerik';
      case 5:
        return 'Otomatik Optimize Edilmiş İçerik';
      case 6:
        return 'Revize Edilmiş İçerik';
      default:
        return '';
      }
    },
    getRevizedType(revisedContetId) {
      switch (revisedContetId) {
      case this.contentCategory.title :
        return 'Başlık';
      case this.contentCategory.category :
        return 'Kategori';
      case this.contentCategory.content :
        return 'İçerik'
      case  this.contentCategory.seoKeyword :
        return 'Anahtar Kelimeler'
      case this.contentCategory.image :
        return 'Görsel'
      case this.contentCategory.metaDescription :
        return 'SEO Açıklaması'
      }
    },
    onclick(content) {
      this.$emit('withdrawHistory', content.id);
    }
  },
}
</script>