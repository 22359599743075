// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex'
import axios from 'axios'
import { auth } from '@/networking/urlManager'

// View imports
import signIn from '@/views/auth/signIn/index.vue'
import resetPassword from '@/views/auth/resetPassword/index.vue'
import dashboard from '@/views/dashboard/index.vue'
import assets from '@/views/assets/index.vue'
import createAsset from '@/views/assets/components/create/index.vue'
import contentModerator from '@/views/entity/index.vue'
import contentAnalyse from '@/views/entity/components/reviewContent/index.vue'
import newContent from '@/views/entity/components/newContent/index.vue'
import scheduleAssistant from '@/views/scheduleAssistant/index.vue'
import users from '@/views/users/index.vue'
import socialMedia from '@/views/socialMedia/index.vue'
import settings from '@/views/settings/index.vue'

const routes = [
  {
    path: '/',
    name: 'signIn',
    component: signIn,
    meta: { requiresAuth: false },
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: resetPassword,
    meta: {
      requiresAuth: false,
      requiresToken: true,
    },
    beforeEnter: async (to, from, next) => {
      const token = to.query.token

      if (!token) {
        next({
          name: 'signIn',
          query: { error: 'Token bulunamadı' },
        })
        return
      }

      try {
        await axios.get(auth.validateResetToken, { params: { token } })
        next()
      } catch (error) {
        next({ name: 'signIn', query: { error: error.response?.data?.message || 'Geçersiz veya süresi dolmuş şifre sıfırlama linki' } })
      }
    },
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: '/assets',
    name: 'assets',
    component: assets,
    meta: { requiresAuth: true },
  },
  {
    path: '/assets/create',
    name: 'createAsset',
    component: createAsset,
    meta: { requiresAuth: true },
  },
  {
    path: '/assets/edit/:id',
    name: 'editAsset',
    component: createAsset,
    meta: { requiresAuth: true },
  },
  {
    path: '/content-moderator',
    name: 'contentModerator',
    component: contentModerator,
    meta: { requiresAuth: true },
  },
  {
    path: '/content-moderator/analyse/:id',
    name: 'contentDetail',
    component: contentAnalyse,
    meta: { requiresAuth: true },
  },
  {
    path: '/new-content',
    name: 'newContent',
    component: newContent,
    meta: { requiresAuth: true },
  },
  {
    path: '/content/scheduling/assistant',
    name: 'scheduleAssistant',
    component: scheduleAssistant,
    meta: { requiresAuth: true },
  },
  {
    path: '/social-media',
    name: 'socialMedia',
    component: socialMedia,
    meta: { requiresAuth: true },
  },
  {
    path: '/users',
    name: 'users',
    component: users,
    meta: { requiresAuth: true },
  },
  {
    path: '/settings',
    name: 'settings',
    component: settings,
    meta: { requiresAuth: true },
  },
  // 404 route
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: () => import('@/views/notFound/index.vue'),
    meta: { requiresAuth: false },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const store = useStore()
  const userData = store.state.userData

  if (to.meta.requiresAuth && !userData.token) {
    next({ name: 'signIn' })
    return
  }


  if (userData.token && (to.name === 'resetPassword' || to.name === 'signIn')) {
    next({ name: 'dashboard' })
    return
  }

  if (to.meta.requiresToken && !to.query.token) {
    next({ name: 'signIn' })
    return
  }

  next()
})

export default router