<template>
  <div :class="outerDivClass">
    <div
      v-if="type === 1"
      class="flex items-center justify-center bg-white w-full p-8 rounded-xl"
    >
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="flex items-center relative"
      >
        <div class="flex flex-col items-center">
          <div
            :class="{
              'bg-secondary': index <= currentStep,
              'bg-gray-200': index > currentStep,
            }"
            class="w-9 h-9 rounded-full flex items-center justify-center"
          >
            <span
              v-if="index < currentStep"
              class="material-symbols-outlined text-white"
            >
              check_circle
            </span>
            <span v-else class="material-symbols-outlined text-white">
              radio_button_checked
            </span>
          </div>
          <div
            v-if="step.label"
            class="absolute top-full w-full flex justify-center items-center"
          >
            <span class="whitespace-nowrap">
              {{ step.label }}
            </span>
          </div>
        </div>

        <div
          v-if="index < steps.length - 1"
          :class="{
            'bg-secondary': index < currentStep,
            'bg-gray-200': index >= currentStep,
          }"
          class="w-12 h-1"
        ></div>
      </div>
    </div>
    <div
      class="w-full"
      :class="
        type === 1
          ? 'min-h-screen sm:min-h-[450px] md:min-h-[500px] 2xl:min-h-[700px] w-full'
          : 'h-full w-full flex items-center justify-between space-x-4'
      "
    >
      <div
        v-if="type === 2"
        @click="prevStep"
        class="p-1 bg-white rounded-full flex items-center cursor-pointer"
      >
        <span class="material-symbols-outlined"> chevron_left </span>
      </div>

      <component
        :is="steps[currentStep] ? steps[currentStep].component : null"
        v-bind="steps[currentStep] ? steps[currentStep].props : {}"
        :step="steps[currentStep] ? steps[currentStep].step : null"
        :ref="steps[currentStep] ? steps[currentStep].ref : null"
        @successResponse="(value) => (isAnalyseCompleted = value)"
        v-if="!isLoading"
        @next="nextStep"
      />

      <div
        v-if="type === 2"
        @click="nextStep"
        class="bg-white rounded-full flex items-center cursor-pointer"
      >
        <span class="material-symbols-outlined"> chevron_right </span>
      </div>
    </div>

    <div
      v-if="type === 2"
      class="flex items-center justify-center bg-gray-300 p-1 rounded-xl space-x-2 !mb-2"
    >
      <div
        v-for="(step, index) in steps"
        :key="index"
        class="flex items-center relative cursor-pointer"
        @click="changeStep(index)"
      >
        <div class="flex flex-col items-center">
          <div
            :class="{
              'bg-primary': index === currentStep,
              'bg-gray-500/35': index !== currentStep,
            }"
            class="w-5 h-5 rounded-full flex items-center justify-center"
          >
            <span class="material-symbols-outlined text-gray-100">
              radio_button_checked
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="type === 1"
      class="flex items-center justify-between space-x-4 w-full"
    >
      <customButton
        @click="prevStep"
        v-if="!isAnalyseCompleted"
        :isDisabled="currentStep === 0 || assetButtonLoading"
        buttonText="Önceki"
        additionalButtonClass="!w-52"
      />
      <customButton
        v-if="currentStep < steps.length - 1"
        @click="nextStep"
        :isLoading="assetButtonLoading"
        :isDisabled="assetButtonLoading"
        :buttonText="getButtonText()"
        additionalButtonClass="!w-52"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import customButton from "../buttons/customButton.vue";

export default {
  components: {
    customButton,
  },
  props: {
    steps: {
      type: Array,
      required: true,
    },
    type: {
      type: Number,
      default: 1,
      required: true,
    },
    outerDivClass: {
      type: String,
      default: "w-full flex flex-col space-y-4 items-center justify-center",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      assetButtonLoading: (state) => state.assetData.loading,
      currentStep: (state) => state.assetData.currentStep,
      isDataReceived: (state) => state.assetData.isDataReceived,
    }),
  },
  data() {
    return {
      isAnalyseCompleted: false,
    };
  },
  methods: {
    ...mapActions(["setCurrentStep"]),

    getButtonText() {
      if (this.steps[this.currentStep].buttonText) {
        return this.isDataReceived
          ? "Sonraki"
          : this.steps[this.currentStep].buttonText;
      }
      return "Sonraki";
    },

    async nextStep() {
      if (this.currentStep < this.steps.length - 1) {
        const isValid =
          await this.$refs[this.steps[this.currentStep].ref].finalize();
        if (isValid) await this.setCurrentStep(this.currentStep + 1);
      } else await this.setCurrentStep(0);
    },

    prevStep() {
      if (this.currentStep > 0) this.setCurrentStep(this.currentStep - 1);
      else this.setCurrentStep(this.steps.length - 1);
    },

    async changeStep(index) {
      if (index > this.currentStep) {
        const isValid =
          await this.$refs[this.steps[this.currentStep].ref].finalize();
        if (isValid) {
          await this.setCurrentStep(index);
        }
      } else {
        await this.setCurrentStep(index);
      }
    },
  },
  created() {
    if (this.currentStep === undefined || this.currentStep === null) {
      this.setCurrentStep(0);
    }
  },
};
</script>
