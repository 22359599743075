<template>
  <div class="keyword-table h-full flex flex-col">
    <customTable :data="keywordsTableData" :columns="columns" :is-loading="isLoading" :show-search="false" max-height="40vh">
      <!-- Table Header Actions -->
      <template #table-actions>
        <div class="flex items-center space-x-2">
          <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.addKeywords')" @click="showAddModal = true" size="sm" icon="add" icon-position="right"/>
          <Tooltip :title="t('settings.components.configuration.components.keywordsTable.keywordsExport')" container-class="flex items-center justify-center">
            <template #content>
              <span v-if="keywordsTableData.length > 0" @click="exportKeywords" class="material-symbols-outlined text-secondary cursor-pointer !text-[22px] hover:text-primary ml-2" :class="{ 'opacity-50 cursor-not-allowed': isExporting }">
                ios_share
              </span>
            </template>
          </Tooltip>
        </div>
      </template>

      <!-- Extra Actions -->
      <template #extra-actions>
        <div class="flex items-center space-x-2">
          <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.downloadTemplate')" @click="downloadExcelTemplate" size="sm" icon="download" icon-position="right" color="secondary" :show-icon="true"/>
          <CustomButton :button-text="t('settings.components.configuration.components.keywordsTable.uploadExcel')" @click="triggerFileInput" size="sm" icon="upload" icon-position="right" :show-icon="true"/>
          <input type="file" ref="fileInput" style="display: none;" @change="handleFileUpload" accept=".xlsx, .xls"/>
        </div>
      </template>

      <!-- Row Actions -->
      <template #actions="{ item }">
        <div class="flex items-center justify-center space-x-2">
          <button @click.stop="handleEdit(item)" class="text-primary hover:text-primary/70">
            <span class="material-symbols-outlined !text-[20px]">edit</span>
          </button>
          <button @click.stop="removeKeyword(item)" class="text-red-500 hover:text-red-700">
            <span class="material-symbols-outlined !text-[20px]">delete</span>
          </button>
        </div>
      </template>

      <!-- Priority Display -->
      <template #priority="{ item }">
        <p class="text-center">{{ item.priority || '-' }}</p>
      </template>
    </customTable>

    <!-- Modals -->
    <add-keyword-modal v-model="showAddModal" @save="handleAddKeywords"/>

    <edit-keyword-modal v-model="showEditModal" :keyword="selectedKeyword" @save="handleEditKeyword"/>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import axios from '@/plugins/axiosInstance'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import * as XLSX from 'xlsx'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'

// Components
import customTable from '@/components/table/customTable.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import Tooltip from '@/components/general/tooltip.vue'
import AddKeywordModal from './components/addKeywordModal.vue'
import EditKeywordModal from './components/editKeywordModal.vue'

// Setup
const store = useStore()
const { t } = useI18n()

// Props & Emits
const props = defineProps({
  keywords: {
    type: Array,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:keywords'])

// Refs
const fileInput = ref(null)
const isExporting = ref(false)
const showAddModal = ref(false)
const showEditModal = ref(false)
const selectedKeyword = ref(null)

// Computed
const columns = computed(() => [
  {
    key: 'name',
    label: t('settings.components.configuration.components.keywordsTable.keyword'),
    width: '1/2',
  },
  {
    key: 'priority',
    label: t('settings.components.configuration.components.keywordsTable.priority'),
    width: '1/4',
    align: 'center',
  },
  {
    key: 'actions',
    label: t('settings.components.configuration.components.keywordsTable.actions'),
    width: '1/4',
    align: 'center',
  },
])

const keywordsTableData = computed(() =>
    props.keywords
        .filter(keyword => keyword.state !== 3)
        .reduce((acc, current) => {
          const existingIndex = acc.findIndex(item => item.name.toLowerCase() === current.name.toLowerCase())
          if (existingIndex >= 0) {
            if (current.state > acc[existingIndex].state) {
              acc[existingIndex] = current
            }
          } else {
            acc.push(current)
          }
          return acc
        }, [])
        .map((keyword) => ({
          id: keyword.id || keyword.keywordId,
          keywordId: keyword.keywordId,
          name: keyword.name,
          state: keyword.state,
          priority: keyword.priority,
        }))
        .sort((a, b) => b.state - a.state),
)

// Methods
const handleEdit = (keyword) => {
  selectedKeyword.value = { ...keyword }
  showEditModal.value = true
}

const handleEditKeyword = (editedKeyword) => {
  const updatedKeywords = props.keywords.map(k => {
    if ((k.id && k.id === editedKeyword.id) ||
        (k.keywordId && k.keywordId === editedKeyword.keywordId)) {

      // Değişiklik yapıldı mı kontrol et
      const isChanged = k.name !== editedKeyword.name || k.priority !== editedKeyword.priority

      // State 1 ise ve değişiklik yapıldıysa state'i 4 yap
      const newState = k.state === 1 && isChanged ? 4 : k.state

      return {
        ...k,
        name: editedKeyword.name,
        priority: editedKeyword.priority,
        state: newState,
      }
    }
    return k
  })

  emit('update:keywords', updatedKeywords)
  responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.keywordUpdated'))
}

const handleAddKeywords = (newKeywords) => {
  const updatedKeywords = [...props.keywords]
  let hasNewKeyword = false

  newKeywords.forEach(newKeyword => {
    const newKeywordLower = newKeyword.name.toLowerCase()
    const existingKeywordIndex = updatedKeywords.findIndex(k => k.name.toLowerCase() === newKeywordLower)

    if (existingKeywordIndex !== -1) {
      if (updatedKeywords[existingKeywordIndex].state === 3) {
        updatedKeywords[existingKeywordIndex].state = 1
        updatedKeywords[existingKeywordIndex].priority = newKeyword.priority
        responseHandlerService.successHandler(
            t('settings.components.configuration.components.keywordsTable.keywordReactivated',
                { keyword: newKeyword.name }),
        )
        hasNewKeyword = true
      } else {
        responseHandlerService.warningHandler(
            t('settings.components.configuration.components.keywordsTable.keywordAlreadyExists',
                { keyword: newKeyword.name }),
        )
      }
    } else {
      updatedKeywords.push({
        name: newKeyword.name,
        state: 2,
        priority: newKeyword.priority,
      })
      responseHandlerService.successHandler(
          t('settings.components.configuration.components.keywordsTable.newKeywordAdded',
              { keyword: newKeyword.name }),
      )
      hasNewKeyword = true
    }
  })

  if (hasNewKeyword) {
    emit('update:keywords', updatedKeywords)
  }
}

const removeKeyword = (keyword) => {
  const updatedKeywords = props.keywords.map(k => {
    if ((k.id && k.id === keyword.id) ||
        (k.keywordId && k.keywordId === keyword.keywordId)) {
      if (k.state === 1) {
        return { ...k, state: 3, priority: null }
      } else if (k.state === 2) {
        return null
      }
    }
    return k
  }).filter(k => k !== null)

  emit('update:keywords', updatedKeywords)

  if (keyword.state === 1) {
    responseHandlerService.successHandler(
        t('settings.components.configuration.components.keywordsTable.keywordDeactivated',
            { keyword: keyword.name }),
    )
  } else if (keyword.state === 2) {
    responseHandlerService.successHandler(
        t('settings.components.configuration.components.keywordsTable.keywordRemoved',
            { keyword: keyword.name }),
    )
  }
}

const exportKeywords = async () => {
  if (isExporting.value) return

  isExporting.value = true
  try {
    const response = await axios.post(
        settings.exportKeywords,
        { assetId: store.state.assetId },
        { responseType: 'arraybuffer' },
    )

    const blob = new Blob([response.data], {
      type: response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    })

    const fileName = `keywords_${dayjs().format('YYYY-MM-DD')}.xlsx`
    saveAs(blob, fileName)

    responseHandlerService.successHandler(t('settings.components.configuration.components.keywordsTable.exportSuccess'))
  } catch (error) {
    console.error('Export failed:', error)
    responseHandlerService.errorHandler(t('settings.components.configuration.components.keywordsTable.exportError'))
  } finally {
    isExporting.value = false
  }
}


const downloadExcelTemplate = () => {
  const template = [
    ['Keyword', 'Priority'],
    ['Example Keyword', 1],
    ['Another Keyword', 2],
  ]
  const ws = XLSX.utils.aoa_to_sheet(template)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, 'Keywords')
  XLSX.writeFile(wb, 'keyword_template.xlsx')
}

const triggerFileInput = () => {
  fileInput.value.click()
}

const handleFileUpload = (event) => {
  const file = event.target.files[0]
  if (file) {
    const reader = new FileReader()
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result)
      const workbook = XLSX.read(data, { type: 'array' })
      const sheetName = workbook.SheetNames[0]
      const worksheet = workbook.Sheets[sheetName]
      const json = XLSX.utils.sheet_to_json(worksheet)

      const updatedKeywords = [...props.keywords]
      let newKeywordsCount = 0
      let reactivatedKeywordsCount = 0

      json.forEach(row => {
        const newKeywordLower = row.Keyword.trim().toLowerCase()
        const existingKeywordIndex = updatedKeywords.findIndex(k => k.name.toLowerCase() === newKeywordLower)

        if (existingKeywordIndex !== -1) {
          if (updatedKeywords[existingKeywordIndex].state === 3) {
            updatedKeywords[existingKeywordIndex].state = 1
            updatedKeywords[existingKeywordIndex].priority = row.Priority
            reactivatedKeywordsCount++
          }
        } else {
          updatedKeywords.push({
            name: row.Keyword.trim(),
            state: 2,
            priority: row.Priority,
          })
          newKeywordsCount++
        }
      })

      emit('update:keywords', updatedKeywords)

      if (newKeywordsCount > 0) {
        responseHandlerService.successHandler(
            t('settings.components.configuration.components.keywordsTable.excelUploadNewKeywords',
                { count: newKeywordsCount }),
        )
      }
      if (reactivatedKeywordsCount > 0) {
        responseHandlerService.successHandler(
            t('settings.components.configuration.components.keywordsTable.excelUploadReactivatedKeywords',
                { count: reactivatedKeywordsCount }),
        )
      }
      if (newKeywordsCount === 0 && reactivatedKeywordsCount === 0) {
        responseHandlerService.warningHandler(
            t('settings.components.configuration.components.keywordsTable.excelUploadNoNewKeywords'),
        )
      }

      // Reset file input
      event.target.value = ''
    }
    reader.readAsArrayBuffer(file)
  }
}
</script>