<template>
  <div class="flex items-center space-x-2">
    <SearchBar v-if="showSearchbar" :icon="searchIcon" :placeholder="searchPlaceholder" :show-text="showSearchText" :text="searchText" @search="handleSearch"/>

    <SortMenu v-if="showSort" v-for="(option, index) in sortOptions" :key="`sort-${index}`" :option="option" :selected="selectedSort" @select="selectSortItem($event)"/>

    <FilterMenu v-if="showFilter" v-for="(option, index) in filterOptions" :key="`filter-${index}`" :option="option" :selected="selectedFilters[option.id]" @select="selectFilterItem(option.id, $event)"/>
  </div>
</template>

<script setup>
import { ref, provide, onMounted, onBeforeUnmount } from 'vue'
import SearchBar from './components/searchBar.vue'
import SortMenu from './components/sortMenu.vue'
import FilterMenu from './components/filterMenu.vue'

const props = defineProps({
  searchIcon: { type: String, default: 'search' },
  searchPlaceholder: { type: String, default: 'Search...' },
  showSearchbar: { type: Boolean, default: true },
  showSearchText: { type: Boolean, default: false },
  searchText: { type: String, default: 'Search' },
  showSort: { type: Boolean, default: true },
  showFilter: { type: Boolean, default: true },
  sortOptions: { type: Array, default: () => [] },
  filterOptions: { type: Array, default: () => [] },
})

const emit = defineEmits(['search', 'sort', 'filter'])

const openMenu = ref(null)
const selectedSort = ref(null)
const selectedFilters = ref({})

provide('openMenu', openMenu)

const lastSearchQuery = ref('')
const searchCooldown = ref(false)

const emitSearch = (query) => {
  emit('search', query)
}

const handleSearch = (query) => {
  if (query === lastSearchQuery.value) {
    return
  }

  emitSearch(query)
  lastSearchQuery.value = query
  searchCooldown.value = true

  setTimeout(() => {
    searchCooldown.value = false
  }, 5000)
}

const selectSortItem = (itemId) => {
  selectedSort.value = itemId
  emit('sort', { key: itemId, order: 'ASC' })
  openMenu.value = null
}

const selectFilterItem = (optionId, itemId) => {
  if (!selectedFilters.value[optionId]) {
    selectedFilters.value[optionId] = []
  }

  const option = props.filterOptions.find(opt => opt.id === optionId)

  if (option.multiSelect) {
    const index = selectedFilters.value[optionId].indexOf(itemId)
    if (index > -1) {
      selectedFilters.value[optionId].splice(index, 1)
    } else {
      selectedFilters.value[optionId].push(itemId)
    }
  } else {
    if (selectedFilters.value[optionId][0] === itemId) {
      selectedFilters.value[optionId] = []
    } else {
      selectedFilters.value[optionId] = [itemId]
    }
  }

  const filters = {}
  for (const [key, value] of Object.entries(selectedFilters.value)) {
    if (value.length > 0) {
      filters[key] = value.join(',')
    }
  }

  emit('filter', filters)

  if (!option.multiSelect) {
    openMenu.value = null
  }
}

const handleClickOutside = (event) => {
  if (!event.target.closest('.relative')) openMenu.value = null
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>