<template>
  <VueFinalModal v-model="showModal" @opened="fetchData" @closed="onModalClosed" class="flex justify-center items-center" content-class="flex flex-col p-6 bg-white rounded-lg shadow-xl max-w-4xl w-full h-[100vh] sm:h-[80vh]" contentTransition="vfm-fade">
    <div class="flex flex-col space-y-4 w-full">
      <header class="flex justify-between items-start">
        <div>
          <h2 class="text-2xl font-bold text-gray-800">{{ t('dashboard.components.keywordAnalysis.modals.volumeGraph.title') }}</h2>
          <p class="text-sm text-gray-600">{{ item.name }}
            <!--            <span >{{ getEngineName() }}</span>-->
          </p>
        </div>
        <button @click="closeModal" class="p-2 text-gray-600 hover:bg-gray-100 rounded-full transition-colors flex items-center">
          <span class="material-symbols-outlined">close</span>
        </button>
      </header>

      <!-- Chart container with larger height -->
      <div ref="chartContainer" class="h-[80vh] sm:h-[600px] w-full">
        <v-chart v-if="hasData" ref="chart" :option="chartOptions" autoresize class="chart"/>
        <div v-else-if="isLoading" class="flex space-x-2 items-center justify-center h-full w-full">
          <span class="material-symbols-outlined text-gray-500 !text-2xl animate-spin">progress_activity</span>
          <p class="text-center text-gray-500">{{ t('dashboard.components.keywordAnalysis.modals.volumeGraph.loading') }}</p>
        </div>
        <p v-else class="text-center text-gray-500">{{ t('dashboard.components.keywordAnalysis.modals.volumeGraph.noData') }}</p>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useStore } from 'vuex'
import VChart from 'vue-echarts'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { GridComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent } from 'echarts/components'
import { useI18n } from 'vue-i18n'

use([CanvasRenderer, BarChart, GridComponent, TooltipComponent, LegendComponent, ToolboxComponent, DataZoomComponent])

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  item: {
    type: [Object],
    required: false,
  },
})

const store = useStore()
const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const chartContainer = ref(null)
const chart = ref(null)
const isLoading = ref(false)
const keywordData = ref([])
const engineId = ref('')
const keywordName = ref('')

const hasData = computed(() => keywordData.value && keywordData.value.length > 0)

const sortedData = computed(() => {
  if (!hasData.value) return []
  return [...keywordData.value].sort((a, b) => new Date(a.year, a.month - 1) - new Date(b.year, b.month - 1))
})

const chartOptions = computed(() => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
  },
  xAxis: {
    type: 'category',
    data: sortedData.value.map(item => `${item.year}-${item.month.toString().padStart(2, '0')}`),
    axisLabel: {
      rotate: -45,
      rotateAlways: false,
    },
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: (val) => Math.round(val).toLocaleString(),
    },
  },
  grid: {
    left: '3%',
    right: '3%',
    bottom: '10%',
    containLabel: true,
  },
  series: [
    {
      name: t('dashboard.components.keywordAnalysis.modals.volumeGraph.searchVolume'),
      type: 'bar',
      data: sortedData.value.map(item => item.volume),
      itemStyle: {
        color: '#FC9F00',
      },
      smooth: true,
    },
  ],
  dataZoom: [
    {
      type: 'slider',
      start: 0,
      end: 100,
      xAxisIndex: [0],
      // Daha modern bir görünüm için stil ayarları
      handleStyle: {
        color: '#ffffff', // Tutamaç rengi
        borderColor: '#FC9F00', // Sınır rengi
        borderWidth: 2,
        shadowBlur: 4,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffsetX: 0,
        shadowOffsetY: 2,
      },
      fillerColor: 'rgba(252, 159, 0, 0.25)', // Seçim aralığı rengi
      backgroundColor: 'rgba(200, 200, 200, 0.3)', // Arka plan rengi
      borderColor: '#FC9F00', // Kenarlık rengi
      textStyle: {
        color: '#333', // Yazı rengi
      },
    },
    {
      type: 'inside',
      xAxisIndex: [0],
      zoomOnMouseWheel: true,
      moveOnMouseMove: true,
    },
  ],
}))


const fetchData = async () => {
  isLoading.value = true
  try {
    const response = await axios.get(dashboard.getMonthlyKeywordVolumes, {
      params: {
        assetId: store.state.assetId,
        keywordId: props.item.id,
      },
    })

    if (response.data && response.data.data) {
      keywordData.value = response.data.data.result
      engineId.value = response.data.data.engineId || ''
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  } finally {
    isLoading.value = false
  }
}

const closeModal = () => {
  showModal.value = false
}

const onModalClosed = () => {
  keywordData.value = []
}

const getEngineName = (engineNumber) => {
  const engines = {
    1: 'Google',
    2: 'Yandex',
    3: 'Bing',
  }
  return engines[engineNumber] || `Engine ${engineNumber}`
}

watch(() => props.item, fetchData)
</script>