<template>
  <div class="w-full p-4 space-y-4">
    <TransitionGroup name="list" tag="ul" class="space-y-3" v-if="props.memories && props.memories.length > 0">
      <li v-for="memory in props.memories" :key="memory.id" class="bg-white shadow-md rounded-lg overflow-hidden transition-all duration-300 hover:shadow-lg">
        <div class="p-4 flex justify-between items-start">
          <div class="space-y-2 flex-grow">
            <h3 class="font-semibold text-lg text-gray-800 line-clamp-2">{{ memory.description }}</h3>
            <div class="flex space-x-4 text-sm text-gray-600">
              <p><span class="font-medium">{{
                  t('settings.components.configuration.components.memoryHistory.components.memoryList.category')
                }}:</span> {{ getContentCategory(memory.contentTypeId) }}</p>
              <p><span class="font-medium">{{
                  t('settings.components.configuration.components.memoryHistory.components.memoryList.commentType')
                }}:</span> {{ getType(memory.commentTypeId) }}</p>
            </div>
          </div>
          <button @click="deleteMemory(memory.id)" class="text-red-500 hover:text-red-700 transition-colors duration-200 focus:outline-none" :disabled="memory.deleting">
            <span v-if="memory.deleting" class="material-symbols-outlined animate-spin">loop</span>
            <span v-else class="material-symbols-outlined">delete</span>
          </button>
        </div>
      </li>
    </TransitionGroup>
    <div v-else class="text-center py-8">
      <p class="text-xl font-semibold text-gray-500">
        {{ t('settings.components.configuration.components.memoryHistory.components.memoryList.noMemoryFound') }} </p>
    </div>
  </div>
</template>

<script setup>
import { TransitionGroup } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  memories: {
    type: Array,
    required: true,
  },
})

const emit = defineEmits(['deleteMemory'])

const { t } = useI18n()

const deleteMemory = (id) => {
  const memory = props.memories.find(memory => memory.id === id)
  if (memory) {
    emit('deleteMemory', id)
  }
}

const getContentCategory = (id) => {
  const categories = {
    1: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.title'),
    2: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.category'),
    3: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.content'),
    4: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.keyword'),
    5: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.image'),
    6: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.seoDescription'),
    7: t('settings.components.configuration.components.memoryHistory.components.memoryList.categories.modelTraining'),
  }
  return categories[id] || ''
}

const getType = (id) => {
  const types = {
    1: t('settings.components.configuration.components.memoryHistory.components.memoryList.types.general'),
    2: t('settings.components.configuration.components.memoryHistory.components.memoryList.types.special'),
  }
  return types[id] || ''
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>