<template>
  <select class="w-full rounded-lg border p-2 h-10" v-if="!load" @input="$emit('update:modelValue', $event.target.value)" :value="selected">
    <option value="" selected disabled> {{
        $t('assets.components.create.components.source.components.sourceCategory.select.notSelected')
      }}
    </option>

    <option v-for="item in List" :key="item.id" :value="item.id">
      {{ item.name }}
    </option>
  </select>

  <div v-if="load" class="w-full rounded-lg border p-2 flex items-center justify-start h-10">
    <span class="material-symbols-outlined animate-spin text-2xl">
      progress_activity
    </span>
    <h5 class="text-sm block ml-2">
      {{ $t('assets.components.create.components.source.components.sourceCategory.select.loading') }}</h5>
  </div>
</template>

<script>
import { mapState } from 'vuex'

// Networking
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'

export default {
  name: 'createEntity',
  props: {
    modelValue: [String, Number],
  },
  data() {
    return {
      List: [],
      load: true,
      selected: '',
    }
  },
  computed: {
    ...mapState({
      sources: (state) => state.assetData.sources,
    }),
  },
  methods: {
    getAll() {
      this.load = true
      axios
          .get(assets.categories)
          .then((res) => {
            this.List = res.data.data
            this.selected = this.modelValue
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.load = false
          })
    },
  },
  watch: {
    modelValue: {
      handler: function (newValue) {
        setTimeout(() => {
          this.selected = newValue
        }, 50)
      },
      immediate: true,
    },
  },
  created() {
    this.getAll()
  },
}
</script>
