import * as Sentry from '@sentry/vue'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// CSS imports
import './css/index.css'
import './assets/tailwind.css'
import 'material-symbols'
import 'material-symbols/outlined.css'
import 'vue-final-modal/style.css'
import 'viewerjs/dist/viewer.css'

// Vue plugins
import { MotionPlugin } from '@vueuse/motion'
import { createVfm } from 'vue-final-modal'
import VueViewer from 'v-viewer'
import socketPlugin from './plugins/socket'
import VueApexCharts from 'vue3-apexcharts'

// Locales
import i18n from '@/i18n'

import { responseHandlerService } from '@/plugins/responseHandlerService'

// Toast plugin import
import ToastPlugin from './plugins/toast-plugin'

const env = process.env.VUE_APP_MODE

const vfm = createVfm()

const viewerOptions = {
  defaultOptions: {
    inline: false,
    button: true,
    navbar: false,
    title: false,
    toolbar: true,
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: true,
    transition: true,
    fullscreen: true,
    keyboard: true,
  },
}

const toastOptions = {
  timeout: 4000,
  showProgress: true,
  isDarkMode: false,
  maxToasts: 2,
  newestOnTop: true,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter(t => t.type === toast.type).length !== 0) {
      return false
    }
    return toast
  },
}


const app = createApp(App)
app.config.globalProperties.$responseHandlerService = responseHandlerService

if (env === 'production') {
  Sentry.init({
    app,
    dsn: 'https://644968285a450ff9a19880365b3eb600@o4508159879675904.ingest.de.sentry.io/4508159939051600',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/yourserver\.io\/api/,
      'https://portal.lobsterlead.com',
      'https://portal.lobster.softwareoptimize.com',
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: env || 'development',
  })
}


const socketUrl = {
  development: 'http://localhost:3000/notification',
  production: 'https://api.lobsterlead.com/notification',
  test: 'https://api.lobster.softwareoptimize.com/notification',
}[env] || 'Invalid VUE_APP_MODE'

app.use(store)
    .use(router)
    .use(vfm)
    .use(MotionPlugin)
    .use(i18n)
    .use(ToastPlugin, toastOptions)
    .use(VueApexCharts)
    .use(VueViewer, viewerOptions)
    .use(socketPlugin, {
      url: socketUrl,
      options: {
        auth: {
          token: store.state.userData.token,
        },
        reconnection: true,
        reconnectionAttempts: 10,
        reconnectionDelay: 1000,
        transports: ['websocket'],
      },
    })

app.mount('#app')