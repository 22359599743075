<template>
  <CustomModal v-model="isOpen" :modalTitle="'Etkinlikler'" @update:modelValue="closeModal">
    <div v-for="event in events" :key="event.id" :class="['event-item text-sm p-2 rounded mb-2 cursor-pointer flex items-center', getEventClass(event)]" @click="$emit('open-content-modal', event.id)">
      <span class="material-symbols-outlined !text-[18px] mr-2">{{ getPlatformIcon(event.platform) }}</span>
      <div>
        <div class="font-semibold text-xs">{{ event.title }}</div>
        <div class="text-[10px]">{{ formatEventTime(event.date) }}</div>
        <div class="text-[10px]">({{ getStateName(event.stateId) }})</div>
      </div>
    </div>
  </CustomModal>
</template>

<script setup>
import { computed } from 'vue'
import CustomModal from '@/components/modals/customModal.vue'
import { usePlatform, useState, useEventUtils } from '../composables'

const props = defineProps({
  modelValue: Boolean,
  events: Array,
})

const emit = defineEmits(['update:modelValue', 'open-content-modal'])

const { Platform, getPlatformIcon } = usePlatform()
const { State, getStateName } = useState()
const { formatEventTime, getEventClass } = useEventUtils(Platform, State)

const isOpen = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const closeModal = () => {
  emit('update:modelValue', false)
}
</script>