<template>
  <div class="relative w-32 xl:w-full z-10">
    <div class="flex items-center justify-between rounded-full cursor-pointer hover:bg-gray-200 p-1 transition duration-300" @click.stop="toggleMenu">
      <div class="flex items-center">
        <img class="h-6 w-6 rounded-full" :src="require('@/assets/navbar/avatar.png')" alt="Avatar"/> <span class="ml-2 text-xs font-semibold text-gray-900 truncate max-w-[80px]">
          {{ name }}
        </span>
      </div>
      <button class="ml-1 focus:outline-none flex items-center" id="user-menu" aria-expanded="false" aria-haspopup="true">
        <span class="material-symbols-outlined text-gray-500 text-xs">
          {{ menuOpen ? 'expand_less' : 'expand_more' }}
        </span>
      </button>
    </div>

    <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <div v-show="menuOpen" class="absolute right-0 mt-2 w-32 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        <button v-if="$route.path.startsWith('/assets')" class="menu-button" @click="users">
          <span class="material-symbols-outlined text-sm">group</span> <span class="text-xs">{{ $t('partitions.navbar.components.profile.users') }}</span>
        </button>
        <button class="menu-button" @click="logout">
          <span class="material-symbols-outlined text-sm">logout</span> <span class="text-xs">{{ $t('partitions.navbar.components.profile.logout') }}</span>
        </button>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { auth } from '@/networking/urlManager'

const props = defineProps({
  name: String,
})

const store = useStore()
const router = useRouter()
const route = useRoute()

const menuOpen = ref(false)

const toggleMenu = () => {
  menuOpen.value = !menuOpen.value
}

const logout = () => {
  axios
      .post(auth.logOut)
      .then(() => {
        store.commit('clearUserData')
        router.push('/')
      })
      .catch((err) => {
        console.error('Logout error:', err)
        store.commit('clearUserData')
        router.push('/')
      })
}

const users = () => {
  router.push('/users')
  menuOpen.value = false
}

const handleClickOutside = (event) => {
  if (!event.target.closest('.relative')) {
    menuOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<style scoped>
.menu-button {
  @apply block px-2 py-1 text-xs text-gray-700 hover:bg-gray-100 w-full text-left flex items-center space-x-1 transition duration-150 ease-in-out;
}
</style>