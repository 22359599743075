<template>
  <div>
    <slot name="external"></slot>
    <div @mousedown="close" class="w-full bg-black z-[998] fixed h-screen top-0 left-0 transition-all ease-out" :class="[
        'bg-opacity-0 invisible ',
        isOpen ? '!bg-opacity-20 !visible' : '',
        externalClass,
      ]">

      <div @mousedown.stop class="bg-white shadow-md flex flex-col" :class="[
          'sidebar z-[998]',
          isOpen ? 'sidebar-open' : '',
          modalClass,
        ]">

        <div v-if="isHeader" class="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 class="text-lg font-bold text-primary">{{ headerTitle }}</h2>
          <div class="flex items-center">
            <button v-if="isRefresh" @click="$emit('refresh',true)" class="mr-2">
              <i class="fa-solid fa-arrows-rotate text-primary text-xl"></i>
            </button>
            <button @click="close()" class="flex items-center">
              <span class="material-symbols-outlined !text-[22px]">close</span>
            </button>
          </div>
        </div>


        <div :class="['flex-grow flex flex-col overflow-hidden', contentClass]">
          <slot name="content"></slot>
        </div>

        <div class="p-4 border-t border-gray-200">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomModal',
  props: ['externalClass', 'modalClass', 'isHeader', 'headerTitle', 'isClosable', 'closeController', 'isRefresh', 'hiddenOverflowY', 'contentClass'],
  data() {
    return {
      isOpen: false,
      load: false,
    }
  },
  methods: {
    async show() {
      this.isOpen = true
      this.$emit('opened', true)
    },
    async close() {
      if (this.isClosable && this.closeController) {
        this.$emit('closeErorr', true)
      } else {
        this.isOpen = false
        this.$emit('closed', false)
      }
    },
  },
}
</script>

<style scoped>
.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  z-index: 500;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
}

.sidebar-open {
  transform: translateX(0);
}
</style>