<template>
  <div class="bg-white p-4 rounded-lg shadow-sm w-full">
    <h2 class="text-2xl font-bold text-secondary mb-5">
      {{ $t('assets.components.create.components.source.websiteConfiguration.title') }} </h2>
    <sourceTable v-model="sources"/>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import sourceTable from './components/sourceTable.vue'

// Networking
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'

export default {
  name: 'WebsiteConfiguration',
  computed: {
    ...mapState({
      introductionList: (state) => state.assetData.introductionList,
      sources: (state) => state.assetData.sources,
      authList: (state) => state.assetData.authList,
      isDataReceived: (state) => state.assetData.isDataReceived,
    }),
  },
  data() {
    return {
      isValid: false,
    }
  },
  components: {
    sourceTable,
  },
  methods: {
    ...mapActions([
      'setAssetLoading',
      'setInformationList',
      'setAssetId',
      'setAssetInputLoading',
      'setIsDataReceived',
      'setAssetCustomerLanguageId',
    ]),

    async sendRequest() {
      this.setAssetLoading(true)

      const websiteUrl = new URL(this.introductionList.website)

      const data = {
        name: this.introductionList.name,
        website: websiteUrl.href,
        languageId: this.introductionList.languageId,
        username: this.authList.username,
        password: this.authList.password,
        authUrl: this.authList.authUrl,
        sourceList: this.sources,
        searchEngineLocations: this.introductionList.searchEngineLocations,
      }

      await axios
          .post(assets.info, data)
          .then((res) => {
            const data = res.data.data

            this.setAssetInputLoading(true)
            this.isValid = true
            this.setAssetId(data.assetId)
            this.setAssetCustomerLanguageId(data.customerLanguageId)
            this.setInformationList(data)
            this.setIsDataReceived(true)
            this.setAssetLoading(false)
          })
          .catch((err) => {
            this.$responseHandlerService.handleError(err, this.$t('assets.create.source.websiteConfiguration.errors.generalError'))
            this.isValid = false
            this.setAssetLoading(false)
          })
    },

    async finalize() {
      if (!this.authList.isAuth) {
        this.$responseHandlerService.handleWarning(this.$t('assets.create.source.websiteConfiguration.errors.authorizationRequired'))
        return
      }

      if (
          !this.introductionList.name ||
          !this.introductionList.website ||
          !this.introductionList.languageId ||
          !this.authList.username ||
          !this.authList.password ||
          !this.authList.authUrl
      ) {
        this.$responseHandlerService.handleError(this.$t('assets.create.source.websiteConfiguration.errors.fillAllFields'))
        return
      }

      if (this.isDataReceived) {
        return true
      }

      await this.sendRequest()
      return this.isValid

    },
  },
  mounted() {
    this.setAssetLoading(false)
  },
}
</script>
