<template>
  <div
    class="skeleton bg-skeleton-gradient bg-200% animate-skeleton-loading rounded-xl"
    :class="skeletonClass"
  ></div>
</template>

<script>
export default {
  props: {
    skeletonClass: {
      type: String,
      default: "",
    },
  },
};
</script>
