<template>
  <div class="relative z-40">
    <button @click="toggleMenu" class="p-2 rounded-md hover:bg-gray-100 focus:outline-none flex items-center">
      <span :class="option.iconClass">{{ option.icon }}</span> <span v-if="option.showText" class="ml-1 text-sm">{{ option.text }}</span>
    </button>
    <transition name="fade">
      <div v-show="isOpen" class="absolute right-0 mt-1 w-48 bg-white rounded-md shadow-lg z-10 text-sm">
        <div v-for="item in option.items" :key="item.id" @click="$emit('select', item.id)" class="px-3 py-2 hover:bg-gray-100 cursor-pointer flex justify-between items-center">
          {{ item.name }} <span v-if="selected === item.id" class="material-symbols-outlined text-secondary !text-sm">check</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, inject } from 'vue'

const props = defineProps({
  option: Object,
  selected: [String, Number],
})

defineEmits(['select'])

const openMenu = inject('openMenu')
const isOpen = computed(() => openMenu.value === `sort-${props.option.id}`)

const toggleMenu = () => {
  openMenu.value = isOpen.value ? null : `sort-${props.option.id}`
}
</script>