<template>
  <div class="p-2 rounded border border-secondary w-full min-h-28">
    <div class="flex items-center space-x-2">
      <label class="font-bold text-secondary"> {{ label }} </label>
      <img :src="aiStarIcon" class="cursor-pointer w-5 hover:opacity-70 transition-opacity" v-if="showIcon" @click="$emit('iconClicked' , true)" :alt="$t('entity.components.contentReview.components.customContent.altTextStarIcon')"/>
    </div>
    <loading v-if="load" iconClass="w-12" class="w-full"/>
    <div v-else ref="contentDiv" class="p-scroll thin-scrollbar max-h-64 overflow-auto">
      {{
        Array.isArray(message)
            ? message[0]
            : (typeof message === 'string' && message.trim().startsWith('['))
                ? JSON.parse(message)[0]
                : message
      }}
    </div>
  </div>
</template>

<script>
import loading from '@/components/loadings/loading.vue'

export default {
  name: 'CustomComment',
  components: {
    loading,
  },
  data() {
    return {
      aiStarIcon: require('@/assets/icons/aiStar.png'),
    }
  },
  props: {
    load: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    message: {
      type: [String, Object],
      default: '',
    },
    showIcon: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    message: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.$refs.contentDiv) {
            this.$refs.contentDiv.style.height = 'auto'
            this.$refs.contentDiv.style.height = `${this.$refs.contentDiv.scrollHeight}px`
          }
        })
      },
    },
  },
}
</script>


<style scoped>
.p-scroll {
  @apply w-full py-3 overflow-auto;
}
</style>

