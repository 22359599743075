import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'

export const usePlatform = () => {
  const Platform = {
    INSTAGRAM: { id: 1, name: 'Instagram', icon: 'photo_camera', class: 'text-instagram-primary' },
    FACEBOOK: { id: 2, name: 'Facebook', icon: 'thumb_up', class: 'text-facebook-primary' },
    TWITTER: { id: 3, name: 'Twitter', icon: 'chat', class: 'text-twitter-primary' },
    BLOG: { id: 4, name: 'Blog', icon: 'article', class: 'text-blog text-green-800' },
    LINKEDIN: { id: 5, name: 'LinkedIn', icon: 'business_center', class: 'text-linkedin-primary' },
    YOUTUBE: { id: 6, name: 'YouTube', icon: 'play_circle', class: 'text-youtube-primary' },
  }

  const getPlatformIcon = (platformId) => {
    const platform = Object.values(Platform).find(p => p.id === platformId)
    return platform ? platform.icon : 'event'
  }

  const getPlatformName = (platformId) => {
    const platform = Object.values(Platform).find(p => p.id === platformId)
    return platform ? platform.name : 'Unknown'
  }

  return { Platform, getPlatformIcon, getPlatformName }
}

export const useState = () => {
  const { t } = useI18n()

  const State = {
    CREATED: { id: 1, name: t('scheduleAssistant.components.calendar.stateList.pending'), class: 'border-l-4 border-l-yellow-500' },
    REJECTED: { id: 2, name: t('scheduleAssistant.components.calendar.stateList.rejected'), class: 'border-l-4 border-l-red-500' },
    APPROVED: { id: 3, name: t('scheduleAssistant.components.calendar.stateList.approved'), class: 'border-l-4 border-l-green-500' },
    SHARED: { id: 4, name: t('scheduleAssistant.components.calendar.stateList.published'), class: 'border-l-4 border-l-blue-500' },
    ERROR: { id: 5, name: t('scheduleAssistant.components.calendar.stateList.error'), class: 'border-l-4 border-l-purple-500' },
  }

  const getStateClass = (stateId) => {
    const state = Object.values(State).find(s => s.id === stateId)
    return state ? state.class : ''
  }

  const getStateName = (stateId) => {
    const state = Object.values(State).find(s => s.id === stateId)
    return state ? state.name : 'Unknown'
  }

  return { State, getStateClass, getStateName }
}

export const useEventUtils = (Platform, State) => {
  const formatEventTime = (date) => {
    return dayjs(date).format('YYYY-MM-DD HH:mm')
  }

  const getEventClass = (event) => {
    if (!event || !Platform || !State) return ''

    const platform = Object.values(Platform).find(p => p.id === event.platform) || { class: 'bg-gray-100 text-gray-800 border border-gray-300' }
    const state = Object.values(State).find(s => s.id === event.stateId) || { class: '' }
    return `text-xs font-semibold ${platform.class} ${state.class}`
  }

  return { formatEventTime, getEventClass }
}