<template>
  <div class="layout-spacing">
    <div class="w-full flex flex-col h-full min-h-screen lg:min-h-0 p-4">
      <div class="w-full flex items-center justify-between mb-4">
        <customButton type="button" buttonText="Yeni Varlık" @click="newAssetClick" icon="add" icon-position="left" size="sm"/>
        <custom-input v-if="Assets.length > 0 || hasSearched" :icon="searchQuery || hasSearched ? 'close' : 'search'" :show-icon="true" :icon-clickable="true" v-model="searchQuery" @keyup.enter="handleSearch" @icon-clicked="clearSearch" :placeholder="t('assets.search')"/>
      </div>

      <div v-if="load" class="w-full text-center flex justify-center items-center flex-grow h-[73vh]">
        <loading :showLoadingText="true" :loading="load" :loadingText="t('assets.loading')" loading-text-class="font-semibold"/>
      </div>

      <div v-else class="flex-grow h-[73vh]">
        <div v-if="Assets.length === 0" class="flex justify-center items-center h-full">
          <span class="text-gray-500">{{ t('assets.not_found') }}</span>
        </div>
        <div v-if="Assets.length > 0" class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 gap-5">
          <card v-for="asset in Assets" :key="asset.id" :id="asset.id" :name="asset.name" :website="asset.website" :isProcess="asset.isProcess" :languageId="asset.languageId" :serviceType="asset.serviceType" :analysisState="asset.analysisState" :automaticPublishing="asset.automaticPublishing === 1" :isFacebook="asset.facebookAccessExpireDate"/>
        </div>
      </div>

      <div class="w-full flex items-center justify-end px-8" v-if="pageCount > 1 && !hasSearched">
        <pagination :totalCount="totalCount" :currentPage="currentPage" :itemsPerPage="15" @page-changed="fetchPage"/>
      </div>
    </div>
  </div>
  <ServiceTypeModal v-model="showServiceTypeModal"/>
</template>

<script setup>
import { ref, onMounted, provide } from 'vue'
import { useStore } from 'vuex'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import card from './components/card/index.vue'
import pagination from '@/components/pagination/index.vue'
import loading from '@/components/loadings/loading.vue'
import ServiceTypeModal from '@/views/assets/components/create/components/serviceTypeModal/index.vue'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import customButton from '../../components/buttons/customButton.vue'
import CustomInput from '@/components/inputs/customInput.vue'

const store = useStore()
const { t } = useI18n()
const Assets = ref([])
const load = ref(false)
const searchQuery = ref('')
const currentPage = ref(1)
const totalCount = ref(1)
const pageCount = ref(1)
const showServiceTypeModal = ref(false)
const hasSearched = ref(false)

const globalMenuState = {
  openMenuId: ref(null),
}

provide('globalMenuState', globalMenuState)

const getAll = async () => {
  load.value = true
  setTimeout(() => {
    axios
        .get(assets.getAll, {
          params: {
            search: searchQuery.value,
            page: currentPage.value - 1,
            sort: 'id DESC',
          },
        })
        .then((result) => {
          Assets.value = result.data.data.detail
          totalCount.value = result.data.data.totalCount
          pageCount.value = result.data.data.pageCount
          store.dispatch('setAssets', result.data.data.detail)
        })
        .catch((err) => {
          console.log(err)
          responseHandlerService.handleError(err, t('assets.error.general'))
        })
        .finally(() => {
          load.value = false
        })
  }, 1000)
}

const handleSearch = () => {
  if (searchQuery.value.trim()) {
    hasSearched.value = true
    currentPage.value = 1
    getAll()
  }
}

const clearSearch = () => {
  searchQuery.value = ''
  if (hasSearched.value) {
    hasSearched.value = false
    currentPage.value = 1
    getAll()
  }
}

const fetchPage = (page) => {
  currentPage.value = page
  getAll()
}

const newAssetClick = () => {
  store.dispatch('resetAssetData')
  showServiceTypeModal.value = true
}

onMounted(async () => {
  await getAll()
})
</script>