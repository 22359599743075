<template>
  <nav class="bg-gradient-to-r from-primary to-accent z-50 shadow-sm sticky top-16 border-y">
    <div class="header-layout">
      <div class="flex justify-between items-center h-12">
        <div class="flex overflow-x-auto hide-scrollbar">
          <div class="flex space-x-4 sm:space-x-8 items-center">
            <button v-for="tab in tabs" :key="tab.name" @click="changeTab(tab.name)" :class="[
                currentTab === tab.name
                  ? 'text-white'
                  : 'text-white/80 hover:text-white',
                'inline-flex items-center text-xs font-medium whitespace-nowrap'
              ]">
              {{ tab.label }}
            </button>
          </div>
        </div>
        <div :class="[rightContentClass , additionalRightContentClass]">
          <slot name="right-content"></slot>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  initialTab: {
    type: String,
    default: 'general',
  },
  rightContentClass: {
    type: String,
    default: 'flex items-center ml-4 sm:ml-6',
  },
  additionalRightContentClass: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['change-tab'])

const route = useRoute()
const router = useRouter()

const currentTab = ref(props.initialTab)

const changeTab = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
  emit('change-tab', tabName)
}

onMounted(() => {
  const tabFromQuery = route.query.tab
  if (tabFromQuery && props.tabs.some(tab => tab.name === tabFromQuery)) {
    currentTab.value = tabFromQuery
  }
})

watch(() => route.query.tab, (newTab) => {
  if (newTab && props.tabs.some(tab => tab.name === newTab)) {
    currentTab.value = newTab
  }
})
</script>

<style scoped>
.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
</style>