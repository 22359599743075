<template>
  <div class="h-full flex flex-col bg-white overflow-y-auto thin-scrollbar">
    <div v-if="loading" class="flex-grow flex items-center justify-center">
      <span class="material-symbols-outlined animate-spin">
            progress_activity
          </span>
    </div>

    <div v-else-if="!content" class="flex-grow flex items-center justify-center">
      <p class="text-gray-500 text-sm">İçerik bulunamadı.</p>
    </div>

    <template v-else>
      <!-- Header -->
      <div class="px-4 py-3 flex items-center justify-between bg-gray-50 border-b border-gray-200 sticky top-0 z-10">
        <div class="flex items-center space-x-2">
          <div class="flex items-center justify-center gap-1">
            <div v-for="(r,index) in content.platforms" :key="index">
              <img v-if="r.platformId" :src="getPlatformIcon(r.platformId)" :alt="r.platformId" class="w-6 h-6 rounded-full"> <span v-else class="material-symbols-outlined !text-[20px]">share</span>
            </div>
          </div>
          <div>
            <h2 class="font-semibold text-sm text-gray-800">
              {{ content.platformName }} </h2>
            <p class="text-xs text-gray-500">
              {{ formatDate(content.publishedDate) }} </p>
          </div>
        </div>

        <span :class="[
            'px-2 py-0.5 text-xs font-medium rounded-full',
            content.isPublished
              ? 'bg-green-100 text-green-800'
              : 'bg-yellow-100 text-yellow-800',
          ]">
          {{ content.isPublished ? 'Yayında' : 'Taslak' }}
        </span>


      </div>

      <!-- Main Content -->
      <div class="flex-grow overflow-y-auto p-3">
        <div class="flex h-full">
          <!-- Left side: Comments -->
          <div class="w-6/12 pr-4">
            <CommentPage :postId="commentId"/>
          </div>

          <!-- Right side: Content and Link Preview -->
          <div class="w-1/2 pl-4 ">
            <div class="flex-1 pr-4 border-r border-gray-200 ">
              <p class="text-gray-800 text-sm whitespace-pre-line mb-4 h-36 overflow-y-auto thin-scrollbar" v-html="highlightedMessage"></p>
            </div>
            <div v-if="content.link" class="mt-4">
              <div class="overflow-hidden h-64">
                <ImageComponent v-if="isImageLink" :imageUrls="[content.link]" :contentLoading="false" class="w-full"/>
                <div v-else-if="shouldShowLinkPreview" class="p-3 bg-gray-50">
                  <ImageComponent v-if="linkPreview.image" :imageUrls="[linkPreview.image]" :contentLoading="false" class="w-full"/>
                  <a :href="content.link" target="_blank" class="text-blue-600 hover:underline font-medium text-sm block truncate"> {{ linkPreview.title || extractDomain(content.link) }} </a>
                  <p v-if="linkPreview.description" class="text-gray-600 text-xs mt-1 line-clamp-2">
                    {{ linkPreview.description }} </p>
                </div>
                <div v-else class="p-3 bg-gray-50">
                  <a :href="content.link" target="_blank" class="text-blue-600 hover:underline font-medium text-sm block truncate"> {{ extractDomain(content.link) }} </a>
                </div>
              </div>
              <div class="p-2 bg-gray-100 rounded-b-lg text-xs text-gray-600">
                <a :href="content.link" target="_blank" class="hover:underline text-blue-500">{{ extractDomain(content.link) }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Footer -->
      <div class="px-4 py-3 bg-gray-50 flex items-center justify-between border-t border-gray-200 mt-auto">
        <div class="flex items-center space-x-2">
          <span class="flex items-center text-gray-600 text-xs">
            <span class="material-symbols-outlined text-red-500 mr-1 text-sm">favorite</span>
            {{ content.postLikes }} beğeni
          </span>
        </div>
        <customButton v-if="!content.isPublished" :is-loading="isContentPublishing" :is-disabled="isContentPublishing" @click="publishPost" button-text="Yayınla" size="sm" color="primary" variation="solid"/>
      </div>
    </template>
  </div>
</template>

<script setup>
import customButton from '@/components/buttons/customButton.vue'
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import axios from '@/plugins/axiosInstance'
import { socialMedia } from '@/networking/urlManager'
import dayjs from 'dayjs'
import 'dayjs/locale/tr'
import InstagramIcon from '@/assets/icons/instagram.svg'
import FacebookIcon from '@/assets/icons/facebook.svg'
import TwitterIcon from '@/assets/icons/twitterx.svg'
import ImageComponent from '@/components/general/imageComponent.vue'
import CommentPage from './components/comment/index.vue'


const props = defineProps({
  postId: {
    type: [String, Number],
    required: false,
  },
  commentId: {
    type: [Number, String],
    required: false,
  },
})

const store = useStore()
const emit = defineEmits(['update:isPublished'])

const content = ref(null)
const loading = ref(true)
const linkPreview = ref({
  title: '',
  description: '',
  image: '',
})
const isContentPublishing = ref(false)

onMounted(fetchContent)

async function publishPost() {
  isContentPublishing.value = true
  await axios.post(socialMedia.publishPost, { rowId: props.postId, assetId: store.state.assetId, platforms: content.value.platforms }).then(() => {
    content.value.isPublished = true
  }).catch((error) => {
    console.error('Yayınlama hatası:', error)
  }).finally(() => {
    isContentPublishing.value = false
  })
}

async function fetchContent() {
  try {
    const { data } = await axios.get(socialMedia.getPost, { params: { postId: props.postId } })
    content.value = data.data[0]
    emit('update:isPublished', Boolean(content.value.isPublished))
    if (content.value.link && !isImageLink.value) {
      await fetchLinkPreview(content.value.link)
    }
  } catch (error) {
    console.error('İçerik yükleme hatası:', error)
  } finally {
    loading.value = false
  }
}

async function fetchLinkPreview(url) {
  if (isImageLink.value) return

  try {
    const response = await axios.get(url)
    const parser = new DOMParser()
    const doc = parser.parseFromString(response.data, 'text/html')

    linkPreview.value = {
      title:
          doc.querySelector('meta[property="og:title"]')?.content || doc.title,
      description:
          doc.querySelector('meta[property="og:description"]')?.content ||
          doc.querySelector('meta[name="description"]')?.content ||
          '',
      image: doc.querySelector('meta[property="og:image"]')?.content || '',
    }
  } catch (error) {
    linkPreview.value = {
      title: extractDomain(url),
      description: '',
      image: '',
    }
  }
}

const isImageLink = computed(() => {
  if (!content.value?.link) return false
  const extension = content.value.link.split('.').pop().toLowerCase()
  return ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(extension)
})

const highlightedMessage = computed(() => {
  if (!content.value?.message) return ''
  const turkishPattern = 'ğüşıöçĞÜŞİÖÇ'
  const hashtagRegex = new RegExp(`#[a-zA-Z0-9_${turkishPattern}]+`, 'g')
  return content.value.message.replace(
      hashtagRegex,
      (match) => `<span class="text-blue-500 font-medium ml-1">${match}</span>`,
  )
})

const shouldShowLinkPreview = computed(
    () =>
        content.value?.link &&
        !isImageLink.value &&
        (linkPreview.value.title ||
            linkPreview.value.description ||
            linkPreview.value.image),
)

function formatDate(dateString) {
  return dateString
      ? dayjs(dateString).locale('tr').format('D MMMM YYYY HH:mm')
      : 'Tarih belirsiz'
}

function getPlatformIcon(platformId) {
  const icons = { 1: InstagramIcon, 2: FacebookIcon, 3: TwitterIcon }
  return icons[platformId] || ''
}

function extractDomain(url) {
  try {
    return new URL(url).hostname.replace('www.', '')
  } catch {
    return url
  }
}
</script>


