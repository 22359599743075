<template>
  <sideModal ref="contentHistory" modalClass="w-full lg:w-10/12  xl:w-6/12 " :isHeader="true" headerTitle="Geçmiş İçerikler" @opened="getAll">
    <template v-slot:external>
      <customDialog v-model="showConfirmDialog" title="Hafıza Silme" message="Bu hafızayı silmek istediğinize emin misiniz?" :showCancelButton="true" cancelButtonText="Hayır" confirmButtonText="Evet" @update:show="showConfirmDialog = $event"/>
    </template>

    <template v-slot:content>
      <div class="p-4 w-full h-full">
        <div class="overflow-auto h-[550px]  xl:h-[650px] 2xl:h-[750px] mt-4 thin-scrollbar">
          <div v-if="load" class="w-full h-full ">
            <loading loading-text="Yükleniyor" :showLoadingText="true" loadingTextClass="text-sm font-semibold"/>
          </div>
          <div v-else class="flex flex-col h-full space-y-4 ">
            <content-history-list :histories="histories" @withdrawHistory="(id) =>$emit('withdrawHistory',id )"/>
          </div>
        </div>
      </div>
    </template>
  </sideModal>
</template>
<script>
import loading from '@/components/loadings/loading.vue'
import SideModal from '@/components/modals/sideModal.vue'
import SearchSortFilterBox from '@/components/searchSortFilter/index.vue'
import axios from '@/plugins/axiosInstance'
import { entity } from '@/networking/urlManager'
import MemoryList from '@/views/settings/components/configuration/components/memoryHistory/components/memoryList.vue'
import customDialog from '@/components/modals/customDialog.vue'
import ContentHistoryList from './components/contentHistoryList.vue'

export default {
  name: 'Content History',
  components: {
    ContentHistoryList,
    customDialog,
    MemoryList,
    SideModal,
    SearchSortFilterBox,
    loading,
  },
  props: {
    entityId: {
      type: [String, Number],
      default: '',
    },
    topId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      load: false,
      showConfirmDialog: false,
      histories: [],
    }
  },
  methods: {
    async toggleSidebar() {
      await this.$refs.contentHistory.show()
    },
    getAll() {
      this.load = true
      setTimeout(() => {
        axios.get(entity.contentHistory, {
          params: {
            entityId: this.entityId,
            topId: this.topId,
            assetId: this.$store.state.assetId,
          },
        }).then((result) => {
          this.histories = result.data.data
        }).catch((err) => {
          console.log(err)
          this.$responseHandlerService.handleError('Geçmiş içerikler getirilirken bir hata oluştu.')
        }).finally(() => {
          this.load = false
        })
      }, 1000)
    },
  },
}
</script>