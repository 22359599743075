import { createI18n } from 'vue-i18n'
import store from './store'  // Store'u import ediyoruz
import en from './locales/en.json'
import tr from './locales/tr.json'

// Desteklenen diller
const supportedLocales = ['en', 'tr']

// Dil kodunu normalize etme fonksiyonu
const normalizeLocale = (locale) => {
  const [language] = locale.split('-')
  return supportedLocales.includes(language) ? language : null
}

// Kullanıcının dilini tespit etme fonksiyonu
const detectUserLocale = () => {
  // Önce store'daki dili kontrol et
  const storedLocale = store.state.language
  if (storedLocale && supportedLocales.includes(storedLocale)) {
    return storedLocale
  }

  // navigator.languages'i kontrol et (çoklu dil desteği için)
  for (const locale of navigator.languages || []) {
    const normalizedLocale = normalizeLocale(locale)
    if (normalizedLocale) return normalizedLocale
  }

  // Eğer navigator.languages'den bir sonuç bulunamazsa, navigator.language'i kontrol et
  const browserLocale = normalizeLocale(navigator.language || 'en')
  if (browserLocale) return browserLocale

  // Hiçbir eşleşme bulunamazsa varsayılan dili döndür
  return 'tr'
}

// Kullanıcının diline göre varsayılan dili ayarla ve store'u güncelle
const locale = detectUserLocale()
store.commit('setLanguage', locale)

const messages = {
  en,
  tr,
}

const i18n = createI18n({
  legacy: false, // Vue 3 Composition API için
  locale, // Tespit edilen dil
  fallbackLocale: 'en', // Bulunmayan bir çeviri için yedek dil
  messages,
})

export default i18n