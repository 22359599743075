<template>
  <div class="bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 overflow-hidden h-full flex flex-col relative" :class="{
         'cursor-pointer': isClickable,
         'cursor-not-allowed opacity-70': !isClickable,
       }" @click="handleCardClick" @mousedown="handleMouseDown" @contextmenu.prevent="handleRightClick">
    <div v-if="!isNewAssetCard" class="p-4 flex-grow">
      <div class="flex items-center justify-between mb-3">
        <img v-if="logo" :src="logo" :alt="name" class="h-6 w-6 object-contain"/>
      </div>
      <div class="space-y-2">
        <h3 class="text-sm font-semibold text-gray-800 truncate flex items-center" :title="trimmedName">
          <svg-icon name="document" class="w-4 h-4 mr-1 text-gray-500"/>
          {{ trimmedName }}
        </h3>
        <p class="text-xs text-gray-600 truncate flex items-center" :title="website">
          <svg-icon name="globe" class="w-4 h-4 mr-1 text-gray-500"/>
          {{ website }}
        </p>
        <div class="flex items-center justify-between mt-2">
          <p class="text-xs truncate flex items-center">
            <svg-icon name="website" class="w-4 h-4 mr-1 text-gray-500/40"/>
            <svg-icon name="facebook" v-if="isFacebook" class="w-4 h-4 mr-1 text-gray-500/40"/>
          </p>
          <div class="text-xs font-semibold py-1 px-2 rounded-full flex items-center" :class="statusClass" @click="handleStatusClick">
            <svg-icon :name="statusIcon" class="w-4 h-4 mr-1"/>
            {{ statusText }}
          </div>
        </div>
      </div>
    </div>

    <!-- Custom Right-Click Menu -->
    <Teleport to="body">
      <div v-if="showCustomMenu" class="fixed bg-white shadow-md rounded-md z-50 custom-menu" :style="customMenuPosition" @click.stop>
        <ul class="py-2">
          <li v-if="!analysisState" class="px-3 py-1 hover:bg-gray-100 cursor-pointer text-sm">
            <a @click.prevent="handleMenuItemClick('open')" href="#"> Aç </a>
          </li>
          <li v-if="!analysisState" class="px-3 py-1 hover:bg-gray-100 cursor-pointer text-sm">
            <a :href="newTabUrl" target="_blank" rel="noopener noreferrer"> Yeni Sekmede Aç </a>
          </li>
          <li v-if="analysisState" class="px-3 py-1 hover:bg-gray-100 cursor-pointer text-sm">
            <a @click.prevent="handleMenuItemClick('edit')" href="#"> Düzenle </a>
          </li>
        </ul>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, inject, provide } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import SvgIcon from './SvgIcon.vue'

const props = defineProps({
  id: { type: [String, Number], required: true },
  name: { type: String, required: true },
  website: { type: String, required: true },
  logo: { type: String, default: null },
  isProcess: { type: Number, default: 0 },
  analysisState: { type: Number, default: 0 },
  serviceType: { type: [String, Number], default: 1 },
  isNewAssetCard: { type: Boolean, default: false },
  languageId: { type: [String, Number], required: true },
  automaticPublishing: { type: Boolean, default: false },
  isFacebook: { type: String, default: '' },
})

const emit = defineEmits(['new-asset-click'])

const { t } = useI18n()
const router = useRouter()
const store = useStore()

// Inject the global menu state
const globalMenuState = inject('globalMenuState', { openMenuId: ref(null) })

// Provide a unique id for this card
const cardId = Symbol('cardId')
provide('cardId', cardId)

const showCustomMenu = computed(() => globalMenuState.openMenuId.value === cardId)
const customMenuPosition = ref({ top: '0px', left: '0px' })

const trimmedName = computed(() => props.name.trim())

const statusText = computed(() => {
  const texts = [
    'assets.components.card.tooltips.setupInProgress',
    'assets.components.card.tooltips.analyzing',
    'assets.components.card.tooltips.analyzed',
    props.analysisState === 1
        ? 'assets.components.card.tooltips.longEntityError'
        : 'assets.components.card.tooltips.languageError',
  ]
  return t(
      props.isProcess >= 1 && props.isProcess <= 4
          ? texts[props.isProcess - 1]
          : texts[2],
  )
})

const statusClass = computed(() => {
  const classes = [
    'text-ocean-500',
    'text-blue-100 bg-blue-800',
    'text-lobster-700',
    props.analysisState === 1 ? 'text-yellow-100 bg-yellow-800' : 'text-red-100 bg-red-800',
  ]
  return props.isProcess >= 1 && props.isProcess <= 4
      ? classes[props.isProcess - 1]
      : 'bg-gray-100 text-gray-800'
})

const statusIcon = computed(() => {
  const icons = ['cog', 'chart-bar', 'check-circle', props.analysisState === 1 ? 'exclamation-triangle' : 'exclamation-circle']
  return props.isProcess >= 1 && props.isProcess <= 4
      ? icons[props.isProcess - 1]
      : 'information-circle'
})

const isErrorState = computed(() => props.isProcess === 4)
const analysisState = computed(() => isErrorState.value && props.analysisState === 1)
const isClickable = computed(
    () => props.isNewAssetCard || (!isErrorState.value && props.isProcess === 3) || analysisState.value,
)

const newTabUrl = computed(() => {
  const route = { name: 'dashboard' }
  const routeData = router.resolve(route)
  return routeData.href
})

const handleCardClick = (event) => {
  if (!isClickable.value) {
    event.preventDefault()
    return
  }

  if (props.isNewAssetCard) {
    emit('new-asset-click')
    return
  }

  if (analysisState.value) {
    openCustomMenu(event)
  } else {
    prepareAndNavigate()
  }
}

const handleMouseDown = (event) => {
  if (event.button === 1 && isClickable.value && !analysisState.value) {
    event.preventDefault()
    const routeData = router.resolve({ name: 'dashboard' })
    window.open(routeData.href, '_blank', 'noopener,noreferrer')
  }
}

const handleRightClick = (event) => {
  if (isClickable.value) {
    openCustomMenu(event)
  }
}

const handleStatusClick = (event) => {
  if (analysisState.value) {
    openCustomMenu(event)
  }
}

const openCustomMenu = (event) => {
  event.preventDefault()
  event.stopPropagation()
  globalMenuState.openMenuId.value = cardId
  customMenuPosition.value = {
    top: `${event.clientY}px`,
    left: `${event.clientX}px`,
  }
}

const closeCustomMenu = (event) => {
  if (showCustomMenu.value && !event.target.closest('.custom-menu')) {
    globalMenuState.openMenuId.value = null
  }
}

const handleMenuItemClick = async (action) => {
  if (action === 'open' && !analysisState.value) {
    await prepareAndNavigate()
  } else if (action === 'edit' && analysisState.value) {
    await store.dispatch('resetAssetData')
    await store.dispatch('setAssetServiceType', props.serviceType)
    await router.push({ name: 'editAsset', params: { id: props.id } })
  }
  globalMenuState.openMenuId.value = null
}

const prepareAndNavigate = async (newTab = false) => {
  await store.dispatch('resetAssetData')
  await store.dispatch('setAssetId', props.id)
  await store.dispatch('setAssetLanguage', props.languageId)
  await store.dispatch('setAssetServiceType', props.serviceType)
  await store.dispatch('setAssetName', trimmedName.value)
  await store.dispatch('setAutomaticPublishing', props.automaticPublishing)

  const route = { name: 'dashboard' }
  if (!newTab) await router.push(route)
}

onMounted(() => {
  document.addEventListener('click', closeCustomMenu)
  window.addEventListener('scroll', closeCustomMenu)
})

onUnmounted(() => {
  document.removeEventListener('click', closeCustomMenu)
  window.removeEventListener('scroll', closeCustomMenu)
})
</script>