<template>
  <div>
    <div ref="triggerRef" @click="toggleDatePicker" class="cursor-pointer">
      <slot name="trigger">
        <button class="px-3 md:py-1 border rounded flex items-center">
          <div class="flex items-center">
            <span class="text-sm">{{ displayDate }}</span> <span class="material-symbols-outlined ">expand_more</span>
          </div>
          <!--          <div class="md:hidden flex items-center">-->
          <!--            <span class="material-symbols-outlined !text-[20px]">event</span>-->
          <!--          </div>-->
        </button>
      </slot>
    </div>

    <!-- Tarih seçici vücut etiketi içerisine geçici olarak yerleştirildi -->
    <Teleport to="body">
      <Transition enter-active-class="transition ease-out duration-200" enter-from-class="opacity-0 translate-y-1" enter-to-class="opacity-100 translate-y-0" leave-active-class="transition ease-in duration-150" leave-from-class="opacity-100 translate-y-0" leave-to-class="opacity-0 translate-y-1">
        <div v-if="isDatePickerVisible" ref="floatingRef" class="z-50" :style="{
          position: strategy,
          top: `${y ?? 0}px`,
          left: `${x ?? 0}px`,
          width: 'max-content'
        }">
          <div class="bg-white rounded-lg shadow-lg border border-gray-200 p-4">
            <div class="flex justify-between mb-2">
              <!-- Bugüne gitmek için buton -->
              <span @click="goToToday" class="text-sm text-primary cursor-pointer hover:underline">
                {{ t('globalComponents.dateRangePicker.goToToday') }}
              </span>
              <!-- Tarih aralığını temizlemek için buton -->
              <span @click="clearDateRange" class="text-sm text-primary cursor-pointer hover:underline">
                Temizle
              </span>
            </div>

            <!-- İki takvimi yan yana göster -->
            <div class="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
              <div v-for="(calendarDate, index) in [leftDate, rightDate]" :key="index" class="w-full sm:w-64">
                <div class="flex justify-between items-center mb-4">
                  <!-- Önceki aya gitmek için buton -->
                  <button @click="changeMonth(index, -1)" class="text-gray-600 hover:text-gray-800 focus:outline-none" :disabled="isFirstMonth(calendarDate)">
                    <span class="material-symbols-outlined" :class="{ 'opacity-50': isFirstMonth(calendarDate) }">chevron_left</span>
                  </button>

                  <!-- Ay ve yılı gösteren butonlar -->
                  <div class="flex space-x-2">
                    <button @click="toggleMonthSelector(index)" class="text-sm font-semibold text-gray-800 hover:text-primary focus:outline-none">
                      {{ formatMonth(calendarDate) }}
                    </button>
                    <button @click="toggleYearSelector(index)" class="text-sm font-semibold text-gray-800 hover:text-primary focus:outline-none">
                      {{ calendarDate.year() }}
                    </button>
                  </div>

                  <!-- Sonraki aya gitmek için buton -->
                  <button @click="changeMonth(index, 1)" class="text-gray-600 hover:text-gray-800 focus:outline-none" :disabled="isCurrentMonth(calendarDate)">
                    <span class="material-symbols-outlined" :class="{ 'opacity-50': isCurrentMonth(calendarDate) }">chevron_right</span>
                  </button>
                </div>

                <!-- Ay seçici bölümü -->
                <div v-if="showMonthSelector[index]" class="mb-4 grid grid-cols-3 gap-2">
                  <button v-for="(month, monthIndex) in localizedMonthNames" :key="monthIndex" @click.stop="selectMonth(index, monthIndex)" class="px-2 py-1 text-sm rounded-md hover:bg-primary hover:text-white transition-colors duration-150" :class="{ 'bg-primary text-white': monthIndex === calendarDate.month() }">
                    {{ month.slice(0, 3) }}
                  </button>
                </div>

                <!-- Yıl seçici bölümü -->
                <div v-if="showYearSelector[index]" class="mb-4 grid grid-cols-3 gap-2 max-h-40 overflow-y-auto">
                  <button v-for="year in yearRange" :key="year" @click.stop="selectYear(index, year)" class="px-2 py-1 text-sm rounded-md hover:bg-primary hover:text-white transition-colors duration-150" :class="{ 'bg-primary text-white': year === calendarDate.year() }">
                    {{ year }}
                  </button>
                </div>

                <!-- Takvim günlerini göster -->
                <div v-if="!showMonthSelector[index] && !showYearSelector[index]">
                  <div class="grid grid-cols-7 gap-1 mb-1">
                    <div v-for="day in daysOfWeek" :key="day" class="text-center text-xs font-medium text-gray-500">
                      {{ day }}
                    </div>
                  </div>

                  <div class="grid grid-cols-7 gap-1">
                    <!-- Takvimdeki günler arasında dolaşma -->
                    <div v-for="{ date, isCurrentMonth, isToday, isInRange, isRangeStart, isRangeEnd, isFuture } in getCalendarDays(calendarDate)" :key="date.toISOString()" @click="selectDate(date)" @mouseenter="handleMouseEnter(date)" class="h-8 flex items-center justify-center text-sm rounded-full cursor-pointer transition-colors duration-200" :class="{
                         'text-gray-300': !isCurrentMonth || isFuture,
                         'bg-primary text-white font-bold transform scale-110': isRangeStart || isRangeEnd && !isFuture,
                         'bg-primary bg-opacity-10 text-primary': isInRange && !isRangeStart && !isRangeEnd,
                         'hover:bg-gray-100': isCurrentMonth && !isInRange && !isRangeStart && !isRangeEnd && !isFuture,
                         'text-gray-800': isCurrentMonth && !isInRange && !isRangeStart && !isRangeEnd && !isFuture,
                         'ring-2 ring-primary': isToday && !isInRange && !isRangeStart && !isRangeEnd,
                         'cursor-not-allowed': isFuture
                       }">
                      {{ date.date() }}
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { useFloating, offset, flip, shift, autoUpdate } from '@floating-ui/vue'

// Day.js isBetween eklentisi ile birlikte kullanılıyor
dayjs.extend(isBetween)

// Bileşen özellikleri (props)
const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({ start: null, end: null }),
  },
  minYear: {
    type: Number,
    default: () => dayjs().year() - 101,
  },
  maxYear: {
    type: Number,
    default: () => dayjs().year(),
  },
  currentYearOnly: {
    type: Boolean,
    default: false,
  },
})

// Tarih aralığı değiştiğinde bir olay yayar
const emit = defineEmits(['update:modelValue'])

// Uluslararasılaştırma
const { t, tm } = useI18n()

// Sol ve sağ takvim tarihlerinin ayarlandığı referanslar
const leftDate = ref(dayjs().subtract(1, 'month').startOf('month'))
const rightDate = ref(dayjs().startOf('month'))

// Görünürlük durumları
const isDatePickerVisible = ref(false)
const showMonthSelector = ref([false, false])
const showYearSelector = ref([false, false])

// Seçilen ve üzerine gelinen tarihler
const selectedStartDate = ref(null)
const selectedEndDate = ref(null)
const hoverDate = ref(null)

// Tetikleyici ve yüzen eleman için referanslar
const triggerRef = ref(null)
const floatingRef = ref(null)

// Floating UI yapılandırması
const { x, y, strategy, update } = useFloating(triggerRef, floatingRef, {
  placement: 'bottom-start',
  middleware: [
    offset(10),
    flip(),
    shift({ padding: 5 }),
  ],
  whileElementsMounted: autoUpdate,
})

// Haftanın günleri ve ay adları için hesaplanmış özellikler
const daysOfWeek = computed(() => t('general.daysShort').split('_'))
const localizedMonthNames = computed(() => tm('general.monthNames'))

// Özelliklere (props) dayalı yıl aralığı
const yearRange = computed(() => {
  if (props.currentYearOnly) {
    const currentYear = dayjs().year()
    return [currentYear - 1, currentYear]
  }
  return Array.from(
      { length: props.maxYear - props.minYear + 1 },
      (_, i) => props.minYear + i,
  ).reverse()
})

// Tarihi DD MMM YYYY formatında gösterir
const formatDate = (date) => {
  if (!date) return ''
  const day = dayjs(date).date()
  const month = dayjs(date).month()
  const year = dayjs(date).year()
  const monthName = tm('general.monthNames')[month].slice(0, 3)
  return `${day} ${monthName} ${year}`
}

// Seçili tarihi görüntülemek için hesaplanmış özellik
const displayDate = computed(() => {
  const start = selectedStartDate.value || props.modelValue.start
  const end = selectedEndDate.value || props.modelValue.end

  if (start && end) return `${formatDate(start)} - ${formatDate(end)}`

  return formatDate(dayjs()) // Güncel tarih gösterimi
  // return t('globalComponents.dateRangePicker.selectRange')
})

// Ayı MMM formatında gösterir
const formatMonth = (date) => {
  return tm('general.monthNames')[date.month()].slice(0, 3).toUpperCase()
}

// Ayın izin verilen aralığındaki ilk ay olup olmadığını kontrol eder
const isFirstMonth = (date) => {
  return date.isSame(dayjs().subtract(100, 'year').startOf('month'), 'month')
}

// Ayın şu anki ay olup olmadığını kontrol eder
const isCurrentMonth = (date) => {
  return date.isSame(dayjs(), 'month')
}

// Takvim için verilen aydaki tüm günleri alır
const getCalendarDays = (month) => {
  const startOfMonth = month.startOf('month')
  const endOfMonth = month.endOf('month')
  const startDate = startOfMonth.startOf('week')
  const endDate = endOfMonth.endOf('week')

  const days = []
  let day = startDate

  const today = dayjs()

  while (day.isBefore(endDate) || day.isSame(endDate, 'day')) {
    const isCurrentMonth = day.month() === month.month()
    const isToday = day.isSame(today, 'day')
    const isInRange = isDateInRange(day)
    const isRangeStart = day.isSame(selectedStartDate.value, 'day')
    const isRangeEnd = day.isSame(selectedEndDate.value, 'day') || (selectedStartDate.value && !selectedEndDate.value && day.isSame(hoverDate.value, 'day'))
    const isFuture = day.isAfter(today, 'day')

    days.push({ date: day, isCurrentMonth, isToday, isInRange, isRangeStart, isRangeEnd, isFuture })
    day = day.add(1, 'day')
  }

  return days
}

// Bir tarihin seçili aralıkta olup olmadığını kontrol eder
const isDateInRange = (date) => {
  if (selectedStartDate.value && selectedEndDate.value) {
    return date.isBetween(selectedStartDate.value, selectedEndDate.value, 'day', '[]')
  }
  if (selectedStartDate.value && hoverDate.value) {
    return date.isBetween(selectedStartDate.value, hoverDate.value, 'day', '[]')
  }
  return false
}

// Takvimde gösterilen ayı değiştirir
const changeMonth = (calendarIndex, delta) => {
  if (calendarIndex === 0) {
    const newDate = leftDate.value.add(delta, 'month')
    if (newDate.isBefore(dayjs().subtract(100, 'year'))) {
      return
    }
    leftDate.value = newDate
    if (leftDate.value.isAfter(rightDate.value) || leftDate.value.isSame(rightDate.value, 'month')) {
      rightDate.value = leftDate.value.add(1, 'month')
    }
  } else {
    const newDate = rightDate.value.add(delta, 'month')
    if (newDate.isAfter(dayjs())) {
      return
    }
    rightDate.value = newDate
    if (rightDate.value.isBefore(leftDate.value) || rightDate.value.isSame(leftDate.value, 'month')) {
      leftDate.value = rightDate.value.subtract(1, 'month')
    }
  }
}

// Tarih aralığı için bir tarih seçer
const selectDate = (date) => {
  if (date.isAfter(dayjs(), 'day')) {
    return
  }

  if (!selectedStartDate.value || (selectedStartDate.value && selectedEndDate.value)) {
    selectedStartDate.value = date
    selectedEndDate.value = null
    hoverDate.value = null
  } else {
    if (date.isSame(selectedStartDate.value, 'day')) return
    else if (date.isBefore(selectedStartDate.value)) {
      selectedEndDate.value = selectedStartDate.value
      selectedStartDate.value = date
    } else {
      selectedEndDate.value = date
    }
    emit('update:modelValue', {
      start: selectedStartDate.value.toDate(),
      end: selectedEndDate.value.toDate(),
    })
    isDatePickerVisible.value = false
  }
}

// Bir tarih üzerine fareyle gelindiğinde işlemi yapar
const handleMouseEnter = (date) => {
  if (selectedStartDate.value && !selectedEndDate.value) {
    hoverDate.value = date
  }
}

// Takvim görünürlüğünü değiştirir
const toggleDatePicker = () => {
  isDatePickerVisible.value = !isDatePickerVisible.value
  if (isDatePickerVisible.value) {
    nextTick(() => update())
  }
}

// Ay seçici görünürlüğünü değiştirir
const toggleMonthSelector = (index) => {
  showMonthSelector.value[index] = !showMonthSelector.value[index]
  showYearSelector.value[index] = false
  nextTick(() => update())
}

// Yıl seçici görünürlüğünü değiştirir
const toggleYearSelector = (index) => {
  showYearSelector.value[index] = !showYearSelector.value[index]
  showMonthSelector.value[index] = false
  nextTick(() => update())
}

// Bir ay seçer
const selectMonth = (calendarIndex, monthIndex) => {
  if (calendarIndex === 0) {
    const newDate = leftDate.value.month(monthIndex)
    if (newDate.isAfter(rightDate.value)) {
      leftDate.value = rightDate.value.subtract(1, 'month')
    } else {
      leftDate.value = newDate
    }
  } else {
    const newDate = rightDate.value.month(monthIndex)
    if (newDate.isAfter(dayjs())) {
      rightDate.value = dayjs().startOf('month')
    } else if (newDate.isBefore(leftDate.value)) {
      rightDate.value = leftDate.value.add(1, 'month')
    } else {
      rightDate.value = newDate
    }
  }
  showMonthSelector.value[calendarIndex] = false
  nextTick(() => update())
}

// Bir yıl seçer
const selectYear = (calendarIndex, year) => {
  if (calendarIndex === 0) {
    const newDate = leftDate.value.year(year)
    if (newDate.isAfter(rightDate.value)) {
      leftDate.value = rightDate.value.subtract(1, 'month')
    } else {
      leftDate.value = newDate
    }
  } else {
    const newDate = rightDate.value.year(year)
    if (newDate.isAfter(dayjs())) {
      rightDate.value = dayjs().startOf('month')
    } else if (newDate.isBefore(leftDate.value)) {
      rightDate.value = leftDate.value.add(1, 'month')
    } else {
      rightDate.value = newDate
    }
  }
  showYearSelector.value[calendarIndex] = false
  nextTick(() => update())
}

// Seçilen tarih aralığını temizler
const clearDateRange = () => {
  selectedStartDate.value = null
  selectedEndDate.value = null
  hoverDate.value = null
  isDatePickerVisible.value = false
  emit('update:modelValue', { start: null, end: null })
}

// Bugünün tarihine ayarlar
const goToToday = () => {
  const today = dayjs()
  rightDate.value = today.startOf('month')
  if (rightDate.value.isSame(leftDate.value, 'month')) {
    leftDate.value = rightDate.value.subtract(1, 'month')
  }
}

// Bileşen dışına tıklama işlemini dinler
const handleClickOutside = (event) => {
  const pickerElement = floatingRef.value
  const triggerElement = triggerRef.value

  if (isDatePickerVisible.value && pickerElement && triggerElement) {
    if (!pickerElement.contains(event.target) && !triggerElement.contains(event.target)) isDatePickerVisible.value = false
  }
}

// Takvim görünürlüğünü izler
watch(isDatePickerVisible, async (visible) => {
  if (visible) {
    await nextTick()
    update()
    if (props.modelValue.start && props.modelValue.end) {
      selectedStartDate.value = dayjs(props.modelValue.start)
      selectedEndDate.value = dayjs(props.modelValue.end)
      rightDate.value = selectedEndDate.value.startOf('month')
      leftDate.value = rightDate.value.subtract(1, 'month')
    }
  }
})

// Monte ve demonte olay dinleyicileri
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

// modelValue prop değişikliklerini izler
watch(() => props.modelValue, (newValue) => {
  if (newValue.start && newValue.end) {
    selectedStartDate.value = dayjs(newValue.start)
    selectedEndDate.value = dayjs(newValue.end)
  }
})
</script>