<template>
  <div class="flex flex-col" :class="props.containerClass">
    <!-- Label ve Description Alanı -->
    <div v-if="label || description" class="mb-2">
      <div class="flex items-center space-x-2 mb-1">
        <div :class="['flex items-center', labelIconPosition === 'left' ? 'flex-row' : 'flex-row-reverse']">
          <icon-component v-if="labelIconPosition === 'left' && props.labelIcon" :icon="props.labelIcon" :class="[props.labelIconClass, 'mr-1', iconClickableClass]" @click="emitIconClick"/>
          <label v-if="props.label" :class="[props.labelClass, props.additionalLabelClass]" class="text-sm"> {{ props.label }}<span v-if="props.required" class="text-primary">*</span> </label>
          <icon-component v-if="labelIconPosition === 'right' && props.labelIcon" :icon="props.labelIcon" :class="[props.labelIconClass, 'ml-1', iconClickableClass]" @click="emitIconClick"/>
        </div>
        <img v-if="props.showAiIcon" :src="aiStarIcon" class="cursor-pointer w-4 hover:opacity-70 transition-opacity" @click="emitIconClick" alt="star icon"/>
      </div>
      <p v-if="props.description" :class="[props.descriptionClass, props.additionalDescriptionClass]" class="text-xs mb-1">
        {{ props.description }} </p>
    </div>

    <!-- Textarea Alanı -->
    <div v-if="props.type === 'textarea'" class="w-full">
      <input-skeleton v-if="props.loading" :class="props.autoGrow ? 'h-56' : textAreaHeight"/>
      <div v-else class="relative flex items-center">
        <textarea ref="textarea" :class="[baseInputClass, sizeClasses, props.autoGrow ? 'h-auto max-h-96 min-h-56' : textAreaHeight, props.showIcon && props.icon ? iconInputClass : '']" :placeholder="props.placeholder" :value="modelValue" @input="updateValue" :disabled="props.disabled" :required="props.required"></textarea>
        <button @click="openModal" class="absolute top-2 right-2 rounded-md bg-gray-200 p-1 flex items-center">
          <icon-component icon="frame_inspect" icon-class="!text-[18px]"/>
        </button>
      </div>
    </div>

    <!-- Diğer Input Alanı -->
    <div v-else-if="props.type !== 'select' && props.type !== 'password' && props.type !== 'phoneNumber'" class="relative">
      <input-skeleton v-if="props.loading" :class="skeletonClass"/>
      <div v-else class="relative flex items-center">
        <div v-if="props.showIcon && props.icon" :class="['absolute inset-y-0 left-0 flex items-center border-r border-gray-300', iconPaddingClass, iconClickableClass]">
          <icon-component :icon="props.icon" :icon-class="iconClass" @click="emitIconClick"/>
        </div>
        <input :class="[baseInputClass, sizeClasses, props.showIcon && props.icon ? iconInputClass : '']" :type="props.type" :placeholder="props.placeholder" :value="modelValue" @input="updateValue" :disabled="props.disabled" :required="props.required"/>
      </div>
    </div>

    <!-- Password Input Alanı -->
    <div v-else-if="props.type === 'password'" class="relative">
      <div class="relative flex items-center">
        <div v-if="props.showIcon && props.icon" :class="['absolute inset-y-0 left-0 flex items-center border-r border-gray-300', iconPaddingClass, iconClickableClass]">
          <icon-component :icon="props.icon" :icon-class="iconClass" @click="emitIconClick"/>
        </div>
        <input :class="[baseInputClass, sizeClasses, 'pr-10', props.showIcon && props.icon ? iconInputClass : '']" :type="showPassword ? 'text' : 'password'" :placeholder="props.placeholder" :value="modelValue" @input="updateValue" :disabled="props.disabled" :required="props.required"/>
        <button type="button" class="absolute right-2 top-1/2 transform -translate-y-1/2 text-sm text-gray-800" @click="showPassword = !showPassword">
          <icon-component :icon="showPassword ? 'visibility' : 'visibility_off'" :icon-class="iconClass"/>
        </button>
      </div>
    </div>

    <!-- Select Input Alanı -->
    <div v-else-if="props.type === 'select'">
      <input-skeleton v-if="props.loading" :class="skeletonClass"/>
      <div v-else class="relative">
        <div v-if="props.showIcon && props.icon" :class="['absolute inset-y-0 left-0 flex items-center border-r border-gray-300', iconPaddingClass, iconClickableClass]">
          <icon-component :icon="props.icon" :icon-class="iconClass" @click="emitIconClick"/>
        </div>
        <p v-if="!modelValue" class="absolute left-3 top-1/2 transform -translate-y-1/2 text-sm text-gray-500" :class="props.showIcon && props.icon ? 'left-10' : ''">
          {{ props.placeholder }} </p>
        <select :class="[baseInputClass, sizeClasses, props.showIcon && props.icon ? iconInputClass : '']" :disabled="props.disabled" :value="modelValue" @input="updateValue" :required="props.required">
          <option v-if="props.loading" value="">Yükleniyor...</option>
          <option v-else-if="props.selectList.length === 0" value="">Veri yok</option>
          <option v-else v-for="item in props.selectList" :key="item.id" :value="item.id">{{ item.name }}</option>
        </select>
      </div>
    </div>

    <!-- Telefon Numarası Input Alanı -->
    <div v-else-if="props.type === 'phoneNumber'" class="w-full">
      <input-skeleton v-if="props.loading" :class="skeletonClass"/>
      <div v-else class="relative flex items-center">
        <div v-if="props.showIcon && props.icon" :class="['absolute inset-y-0 left-0 flex items-center border-r border-gray-300', iconPaddingClass, iconClickableClass]">
          <icon-component :icon="props.icon" :icon-class="iconClass" @click="emitIconClick"/>
        </div>
        <input :value="modelValue" @input="updatePhoneNumber" type="text" :class="[baseInputClass, sizeClasses, props.showIcon && props.icon ? iconInputClass : '']" class="w-full" :placeholder="props.placeholder" :required="props.required"/>
      </div>
    </div>

    <!-- Modal Alanı -->
    <transition name="fade">
      <div v-if="modalVisible" class="fixed inset-0 z-[99999999999] flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white p-6 rounded-lg w-4/5 lg:w-3/5">
          <h2 class="text-lg font-semibold mb-4">Metni Düzenle</h2>
          <textarea ref="modalTextarea" :class="[baseInputClass, 'w-full h-64 autofocus', props.autoGrow ? 'h-auto max-h-screen min-h-56' : 'h-64']" :value="modelValue" @input="updateValue" @blur="closeModal" :placeholder="props.placeholder" :disabled="props.disabled" :required="props.required"></textarea>
          <div class="flex justify-end mt-4">
            <custom-button @click="closeModal" button-text="Kapat" size="sm"/>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed, nextTick, onMounted, watch } from 'vue'
import InputSkeleton from '@/components/loadings/inputSkeleton.vue'
import IconComponent from '@/components/general/iconComponent.vue'
import CustomButton from '@/components/buttons/customButton.vue'

const props = defineProps({
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  modelValue: { type: [String, Number, Array, Object], default: '' },
  selectList: { type: Array, default: () => [] },
  type: { type: String, default: 'text' },
  additionalInputClass: { type: String, default: '' },
  labelClass: { type: String, default: 'font-semibold text-secondary' },
  descriptionClass: { type: String, default: 'text-gray-600' },
  additionalDescriptionClass: { type: String, default: '' },
  additionalLabelClass: { type: String, default: '' },
  containerClass: { type: String, default: '' },
  placeholder: { type: String, default: '' },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  autoGrow: { type: Boolean, default: false },
  textAreaHeight: { type: String, default: 'h-24' },
  showAiIcon: { type: Boolean, default: false },
  icon: { type: String, default: 'link' },
  iconClass: { type: String, default: 'text-secondary !text-base' },
  showIcon: { type: Boolean, default: false },
  size: { type: String, default: 'sm', validator: (value) => ['sm', 'md', 'lg'].includes(value) },
  labelIcon: { type: String, default: '' },
  labelIconClass: { type: String, default: 'text-primary !text-[20px]' },
  labelIconPosition: { type: String, default: 'left', validator: (value) => ['left', 'right'].includes(value) },
  iconClickable: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'iconClicked'])

const showPassword = ref(false)
const phoneNumberError = ref(null)
const textarea = ref(null)
const aiStarIcon = require('@/assets/icons/aiStar.png')
const modalVisible = ref(false)

const baseInputClass = computed(() => [
  'border border-gray-300 rounded-md focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500 w-full thin-scrollbar',
  props.additionalInputClass,
])

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'text-xs py-1.5 px-2 placeholder:text-xs'
    case 'md':
      return 'text-sm py-2 px-3 placeholder:text-sm'
    case 'lg':
      return 'text-base py-3 px-4 placeholder:text-base'
    default:
      return 'text-sm py-2 px-3 placeholder:text-sm'
  }
})

const iconPaddingClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'pl-2 pr-2'
    case 'lg':
      return 'pl-3 pr-3'
    default:
      return 'pl-2.5 pr-2.5'
  }
})

const iconInputClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'pl-10'
    case 'lg':
      return 'pl-12'
    default:
      return 'pl-11'
  }
})

const skeletonClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'h-7 w-full'
    case 'lg':
      return 'h-12 w-full'
    default:
      return 'h-9 w-full'
  }
})

const iconClass = computed(() => {
  return props.icon ? `${props.iconClass} text-base` : ''
})

const iconClickableClass = computed(() => {
  return props.iconClickable ? 'cursor-pointer hover:text-blue-600 transition-colors' : ''
})

const updateValue = (event) => {
  let value = event.target.value
  emit('update:modelValue', value)
  if (props.autoGrow && props.type === 'textarea') nextTick(adjustHeight)
}

const updatePhoneNumber = (event) => {
  let value = event.target.value.replace(/\D/g, '')
  if (value.length > 16) value = value.slice(0, 16)

  emit('update:modelValue', value)

  if (value.length < 13) phoneNumberError.value = 'Telefon numarası 13 haneli olmalıdır.'
  else phoneNumberError.value = null
}

const adjustHeight = () => {
  if (textarea.value && props.autoGrow) {
    textarea.value.style.height = 'auto'
    textarea.value.style.height = `${textarea.value.scrollHeight}px`
  }
}

const openModal = () => {
  modalVisible.value = true
}

const closeModal = () => {
  modalVisible.value = false
}

const emitIconClick = () => {
  if (props.iconClickable) emit('iconClicked')
}

watch(() => props.modelValue, (newValue) => {
  if (props.type === 'textarea' && props.autoGrow) nextTick(adjustHeight)
})

onMounted(() => {
  if (props.type === 'textarea' && props.autoGrow) adjustHeight()
})
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}
</style>