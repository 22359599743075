<template>
  <VueFinalModal :click-to-close="true" :esc-to-close="true" class="flex justify-center items-center" content-class="flex flex-col bg-white rounded-lg shadow-xl w-full max-w-4xl mx-4 max-h-[90vh]" @closed="handleClose">
    <!-- Modal Header -->
    <div class="flex items-center justify-between px-6 py-4 border-b shrink-0">
      <h2 class="text-xl font-semibold text-gray-800">
        {{ t('settings.components.integrations.components.manageModal.title', { name: integration?.name }) }} </h2>
      <button @click="handleClose" class="text-gray-500 hover:text-gray-700 transition-colors flex items-center">
        <span class="material-symbols-outlined">close</span>
      </button>
    </div>

    <!-- Modal Content -->
    <div class="flex-1 min-h-0">
      <div class="h-[450px] px-6 py-4">
        <component v-if="currentPlatformComponent" :is="currentPlatformComponent" :integration-id="integration?.id" ref="platformComponentRef"/>
      </div>
    </div>

    <!-- Modal Footer -->
    <div class="flex justify-end px-6 py-4 border-t bg-gray-50 shrink-0">
      <div class="space-x-2">
        <CustomButton :button-text="t('settings.components.integrations.components.manageModal.cancel')" @click="handleClose" color="secondary" size="sm"/>
        <CustomButton :button-text="t('settings.components.integrations.components.manageModal.save')" @click="handleSave" :is-loading="saving" :is-disabled="!hasChanges" size="sm"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, markRaw } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import CustomButton from '@/components/buttons/customButton.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'

// Platform specific components
import GoogleAnalyticsConfig from './components/googleAnalyticsConfig.vue'
import FacebookConfig from './components/facebookConfig.vue'
import InstagramConfig from './components/instagramConfig.vue'
import TwitterConfig from './components/xConfig.vue'

const props = defineProps({
  integration: {
    type: Object,
    required: false,
  },
  show: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue'])
const { t } = useI18n()
const store = useStore()

const saving = ref(false)
const platformComponentRef = ref(null)

const platformComponents = {
  1: markRaw(InstagramConfig),
  2: markRaw(FacebookConfig),
  3: markRaw(TwitterConfig),
  6: markRaw(GoogleAnalyticsConfig),
}

// Computed
const currentPlatformComponent = computed(() => {
  return platformComponents[props.integration?.id] || null
})

const hasChanges = computed(() => {
  return platformComponentRef.value?.hasChanges || false
})

// Methods
const handleSave = async () => {
  if (!hasChanges.value || !platformComponentRef.value) return

  saving.value = true
  try {
    const componentData = platformComponentRef.value.getData()

    await axios.put(settings.saveManageIntegration, {
      assetId: store.state.assetId,
      platformId: props.integration?.id,
      data: componentData,
    })

    responseHandlerService.handleSuccess(t('settings.components.integrations.components.manageModal.saveSuccess'))
    handleClose()
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.components.integrations.components.manageModal.saveError'))
  } finally {
    saving.value = false
  }
}

const handleClose = () => {
  emit('update:modelValue', false)
}
</script>