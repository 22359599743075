<template>
  <VueFinalModal
      class="flex justify-center items-center"
      content-class="bg-white rounded-lg shadow-lg max-w-2xl w-full mx-4"
      :click-to-close="true"
      @closed="emit('update:modelValue', false)"
      contentTransition="vfm-slide-down"
  >
    <div class="flex flex-col h-[500px]">
      <div class="flex items-center justify-between p-4 border-b">
        <h2 class="text-lg font-semibold text-gray-800">
          {{ title }}
        </h2>
        <button @click="emit('update:modelValue', false)" class="text-gray-500 hover:text-gray-700 transition-colors">
          <span class="material-symbols-outlined text-sm">close</span>
        </button>
      </div>
      <div class="flex-1 overflow-hidden">
        <CategoryDetail :detail="categorizedAuditDetail" />
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { computed, watch } from 'vue'
import { VueFinalModal } from 'vue-final-modal'
import CategoryDetail from './components/categoryDetail.vue'

const props = defineProps({
  showModalId: String,
  auditDetail: Object,
})

const emit = defineEmits(['update:modelValue'])

const titles = {
  performance: 'Performans',
  accessibility: 'Erişilebilirlik',
  'best-practices': 'En İyi Uygulamalar',
  seo: 'SEO',
}

const title = computed(() => titles[props.showModalId] || '')

const categorizedAuditDetail = computed(() => {
  if (props.auditDetail && props.auditDetail.audits) {
    return props.auditDetail.audits[props.showModalId]
  }
  return null
})

watch(() => props.showModalId, (newVal) => {
  if (props.auditDetail && props.auditDetail.audits) {
    categorizedAuditDetail.value = props.auditDetail.audits[newVal]
  }
}, { immediate: true })
</script>