<template>
  <div class="relative">
    <custom-input :icon="icon" :show-icon="true" v-model="searchQuery" @keyup.enter="$emit('search', searchQuery)" type="text" :placeholder="placeholder" class="w-48 text-sm" @iconClicked="$emit('search', searchQuery)" :icon-clickable="true"/>
    <span v-if="showText" class="ml-2 text-sm">{{ text }}</span>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import CustomInput from '@/components/inputs/customInput.vue'

defineProps({
  icon: String,
  placeholder: String,
  showText: Boolean,
  text: String,
})

defineEmits(['search'])

const searchQuery = ref('')
</script>