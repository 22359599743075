<template>
  <VueFinalModal v-model="showModal" class="flex justify-center items-center" content-class="relative bg-white rounded-lg w-96 max-w-full mx-4 p-4 border" :click-to-close="false" :esc-to-close="true">
    <div class="space-y-4">
      <!-- Modal Header -->
      <div class="flex justify-between items-center pb-2 border-b">
        <h3 class="text-lg font-semibold text-gray-900">
          {{ t('auth.forgotPassword.title') }} </h3>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-500">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Modal Body -->
      <p class="text-sm text-gray-600">
        {{ t('auth.forgotPassword.description') }} </p>

      <form @submit.prevent="handleForgotPassword" class="space-y-4">
        <customInput icon="mail" :show-icon="true" type="email" v-model="localEmail" :placeholder="t('auth.emailPlaceholder')" additional-input-class="bg-slate-100 border-none" size="md"/>

        <!-- Modal Footer -->
        <div class="flex justify-end space-x-3 pt-4">
          <customButton type="button" :buttonText="t('common.cancel')" @click="closeModal" color="secondary" variant="outline" size="sm"/>
          <customButton type="submit" :buttonText="t('auth.forgotPassword.sendResetLink')" :isLoading="isResetLoading" :loadingText="t('auth.forgotPassword.sendingResetLink')" color="primary" size="sm"/>
        </div>
      </form>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch, computed } from 'vue'
import customButton from '@/components/buttons/customButton.vue'
import customInput from '@/components/inputs/customInput.vue'
import { useI18n } from 'vue-i18n'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import axios from '@/plugins/axiosInstance'
import { auth } from '@/networking/urlManager'
import { VueFinalModal } from 'vue-final-modal'

const { t } = useI18n()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  initialEmail: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const localEmail = ref('')
const isResetLoading = ref(false)

watch(() => props.initialEmail, (newValue) => {
  if (newValue) {
    localEmail.value = newValue
  }
}, { immediate: true })

// Watch for modal visibility changes
watch(() => showModal.value, (newValue) => {
  if (newValue && props.initialEmail) {
    localEmail.value = props.initialEmail
  }
})

const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

const handleForgotPassword = async () => {
  if (!localEmail.value) {
    responseHandlerService.handleError(t('auth.emailRequired'))
    return
  }

  if (!validateEmail(localEmail.value)) {
    responseHandlerService.handleError(t('auth.invalidEmail'))
    return
  }

  isResetLoading.value = true

  try {
    const protocol = window.location.protocol
    const hostname = window.location.hostname
    const port = window.location.port

    const baseURL = port
        ? `${protocol}//${hostname}:${port}`
        : `${protocol}//${hostname}`

    await axios.post(auth.forgotPassword, {
      email: localEmail.value,
      url: baseURL,
    })

    responseHandlerService.handleSuccess(t('auth.forgotPassword.resetLinkSent'))
    closeModal()
  } catch (error) {
    responseHandlerService.handleError(error, t('auth.error.resetLinkFailed'))
  } finally {
    isResetLoading.value = false
  }
}

const closeModal = () => {
  showModal.value = false
  localEmail.value = ''
  isResetLoading.value = false
}
</script>