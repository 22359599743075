<template>
  <div class="w-full px-10 text-white h-full items-center justify-center pl-10 text-left relative bg-cover bg-center bg-fixed hidden md:inline-flex before:absolute before:inset-0 before:backdrop-blur-lg">
    <div class="relative z-10 flex flex-col w-11/12">
      <h1 class="text-3xl font-bold text-secondary">
        {{ currentInfo.title }} </h1>
      <p class="font-Poppins text-base font-extralight text-gray-900 mt-5">
        {{ currentInfo.description }} </p>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

const route = useRoute()
const { t } = useI18n()

// Map route names to their respective translation paths
const INFO_TRANSLATIONS = {
  resetPassword: {
    title: 'auth.resetPassword.title',
    description: 'auth.resetPassword.description',
  },
  signIn: {
    title: 'auth.components.information.signIn.title',
    description: 'auth.components.information.signIn.description',
  },
  forgotPassword: {
    title: 'auth.forgotPassword.title',
    description: 'auth.forgotPassword.description',
  },
}

const currentInfo = computed(() => {
  const translations = INFO_TRANSLATIONS[route.name] || INFO_TRANSLATIONS.signIn

  return {
    title: t(translations.title),
    description: t(translations.description),
  }
})
</script>