<template>
  <div class="flex h-screen">
    <info/>
    <AuthForm/>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import Info from '../components/information.vue'
import AuthForm from './components/authForm.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

const store = useStore()
const router = useRouter()
const { t } = useI18n()
const route = useRoute()

const token = computed(() => store.state.userData.token)

onMounted(() => {
  if (token.value) {
    router.push('/assets')
    return
  }

  if (route.query.error) responseHandlerService.handleError({ message: route.query.error }, t('auth.error.general'))
})
</script>