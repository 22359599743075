<template>
  <div class="bg-white p-4 rounded-md shadow-md">
    <h3 class="text-lg font-semibold ">En Çok Trafik Getiren Kaynaklar</h3>

    <customTable :data="data" :columns="columns" :isLoading="loading" :minHeight="minHeight" :maxHeight="maxHeight" :minWidth="minWidth">
      <template #source="{ item }">
        <div>{{ item.source }}</div>
      </template>

      <template #activeUsers="{ item }">
        <div>{{ item.activeUsers }}</div>
      </template>

      <template #sessions="{ item }">
        <div>{{ item.sessions }}</div>
      </template>
    </customTable>
  </div>
</template>

<script setup>
import CustomTable from '@/components/table/customTable.vue'

defineProps({
  data: Array,
  loading: Boolean,
})
const minHeight = '410px'
const maxHeight = '410px'
const minWidth = '600px'

// Tablo sütun bilgilerini tanımlıyoruz
const columns = [
  { key: 'source', label: 'Kaynak', sortable: false },
  { key: 'activeUsers', label: 'Aktif Kullanıcılar', sortable: false },
  { key: 'sessions', label: 'Oturumlar', sortable: false },
]

const handleSearch = (query) => {
  // Arama işlemi
}

const handleSort = ({ key, order }) => {
  // Sıralama işlemi
}

const handleFilter = (filters) => {
  // Filtreleme işlemi
}

const handlePageChange = (page) => {
  // Sayfa değişim işlemi
}

const handleRowClick = (item) => {
  // Satır tıklama işlemi
}

const handleReorder = ({ fromIndex, toIndex, updatedData }) => {
  // Satır yeniden sıralama işlemi
}
</script>
