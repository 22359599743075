<template>
  <div class="flex items-center pt-2">
    <div class="flex rounded-md shadow-sm">
      <button :disabled="currentPage === 1" @click="handlePageChange(currentPage - 1)" class="paginate-button rounded-l-md">
        ‹
      </button>
      <button v-for="(page, index) in visiblePages" :key="index" :class="['paginate-button', { selected: page === currentPage }]" @click="
          page === '...' ? openDropdown($event, index) : handlePageChange(page)
        " :ref="page === '...' ? `ellipsis-${index}` : null">
        {{ page }}
      </button>
      <button :disabled="currentPage === computedPageCount" @click="handlePageChange(currentPage + 1)" class="paginate-button rounded-r-md">
        ›
      </button>
    </div>
    <Teleport to="body">
      <div v-if="showDropdown" ref="dropdown" class="bg-white border border-gray-300 rounded-md shadow-lg p-2 z-50" :style="{
          position: 'absolute',
          top: `${dropdownPosition.top}px`,
          left: `${dropdownPosition.left}px`,
        }">
        <input v-model.number="jumpToPage" @keyup.enter="handleJumpToPage" class="w-16 px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary" type="number" min="1" :max="computedPageCount"/>
        <button @click="handleJumpToPage" class="ml-2 px-2 py-1 text-sm bg-primary text-white rounded-md hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-primary">
          {{ t('general.go') }}
        </button>
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue'
import { computePosition, flip, shift, offset } from '@floating-ui/dom'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  totalCount: Number,
  itemsPerPage: {
    type: Number,
    default: 10,
  },
  currentPage: {
    type: Number,
    default: 1,
  },
  pageCount: {
    type: Number,
    default: null,
  },
})

const { t } = useI18n()

const emit = defineEmits(['page-changed'])

const showDropdown = ref(false)
const dropdownPosition = ref({ top: 0, left: 0 })
const jumpToPage = ref('')
const dropdown = ref(null)
const activeEllipsis = ref(null)

const computedPageCount = computed(() => {
  return props.pageCount !== null
      ? props.pageCount
      : Math.ceil(props.totalCount / props.itemsPerPage)
})

const visiblePages = computed(() => {
  const range = []
  const maxPages = 7

  if (computedPageCount.value <= maxPages) {
    for (let i = 1; i <= computedPageCount.value; i++) {
      range.push(i)
    }
  } else {
    range.push(1)
    if (props.currentPage > 3) range.push('...')

    let start = Math.max(2, props.currentPage - 1)
    let end = Math.min(computedPageCount.value - 1, props.currentPage + 1)

    if (props.currentPage < 3) end = 4
    if (props.currentPage > computedPageCount.value - 2)
      start = computedPageCount.value - 3

    for (let i = start; i <= end; i++) {
      range.push(i)
    }

    if (props.currentPage < computedPageCount.value - 2) range.push('...')
    range.push(computedPageCount.value)
  }

  return range
})

const handlePageChange = (page) => {
  if (
      page !== props.currentPage &&
      page !== '...' &&
      page >= 1 &&
      page <= computedPageCount.value
  ) {
    emit('page-changed', page)
    showDropdown.value = false // Sayfa değiştirildiğinde menüyü kapat
  }
}

const openDropdown = (event, index) => {
  activeEllipsis.value = event.target
  showDropdown.value = true
  jumpToPage.value = ''
  updateDropdownPosition()
}

const updateDropdownPosition = () => {
  if (activeEllipsis.value && dropdown.value) {
    computePosition(activeEllipsis.value, dropdown.value, {
      placement: 'bottom',
      middleware: [offset(5), flip(), shift({ padding: 5 })],
    }).then(({ x, y }) => {
      dropdownPosition.value = { top: y, left: x }
    })
  }
}

const handleJumpToPage = () => {
  const page = parseInt(jumpToPage.value)
  if (page >= 1 && page <= computedPageCount.value) {
    handlePageChange(page)
    showDropdown.value = false
  }
}

const closeDropdown = (event) => {
  if (
      dropdown.value &&
      !dropdown.value.contains(event.target) &&
      !event.target.classList.contains('paginate-button')
  ) {
    showDropdown.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', closeDropdown)
  window.addEventListener('resize', updateDropdownPosition)
  window.addEventListener('scroll', updateDropdownPosition)
})

onUnmounted(() => {
  document.removeEventListener('click', closeDropdown)
  window.removeEventListener('resize', updateDropdownPosition)
  window.removeEventListener('scroll', updateDropdownPosition)
})
</script>

<style scoped>
.paginate-button {
  @apply h-8 min-w-[2rem] px-2 text-sm flex items-center justify-center bg-white border border-gray-300 !text-secondary cursor-pointer transition-all duration-200 ease-in-out;
}

.paginate-button:not(:first-child) {
  @apply border-l-0;
}

.paginate-button.selected {
  @apply !bg-primary !text-white border-primary shadow-md z-10 scale-105;
}

.paginate-button:disabled {
  @apply cursor-not-allowed bg-gray-200 border-gray-200 !text-gray-400;
}

.paginate-button:hover:not(:disabled) {
  @apply bg-gray-100;
}
</style>