export class ResponseHandlerService {
  constructor() {
    this.errorHandler = null
    this.successHandler = null
    this.warningHandler = null
    this.unauthorizedSnackbarDisplayed = false
  }

  // Handlers
  setErrorHandler(handler) {
    this.errorHandler = handler
  }

  setSuccessHandler(handler) {
    this.successHandler = handler
  }

  setWarningHandler(handler) {
    this.warningHandler = handler
  }

  // Helper method to extract message with fallback
  _extractMessage(data, fallbackMessage = null) {
    if (!data) return fallbackMessage

    if (data?.response?.data?.message) {
      return data.response.data.message
    }

    if (typeof data === 'string') return data

    return fallbackMessage
  }

  // Check if error is unauthorized
  _isUnauthorizedError(error) {
    return error?.response?.status === 401
  }

  // Reset unauthorized flag
  resetUnauthorizedFlag() {
    this.unauthorizedSnackbarDisplayed = false
  }

  // Main handlers with fallback messages
  handleError(error, fallbackMessage = 'Bir hata oluştu.') {
    if (!this.errorHandler) {
      console.error('No error handler provided!')
      return
    }

    // Handle unauthorized errors
    if (this._isUnauthorizedError(error)) {
      if (!this.unauthorizedSnackbarDisplayed) {
        this.unauthorizedSnackbarDisplayed = true
        const customMessage = this._extractMessage(error, 'Oturum süreniz dolmuştur. Lütfen yeniden giriş yapın.')

        this.errorHandler(customMessage)
      }
      return
    }

    const message = this._extractMessage(error, fallbackMessage)
    this.errorHandler(message)
  }

  handleSuccess(message, fallbackMessage = 'İşlem başarıyla tamamlandı.') {
    if (!this.successHandler) {
      console.log('No success handler provided!')
      return
    }

    const extractedMessage = this._extractMessage(message, fallbackMessage)
    this.successHandler(extractedMessage)
  }

  handleWarning(message, fallbackMessage = 'Uyarı mesajı.') {
    if (!this.warningHandler) {
      console.warn('No warning handler provided!')
      return
    }

    const extractedMessage = this._extractMessage(message, fallbackMessage)
    this.warningHandler(extractedMessage)
  }
}

// Create singleton instance
export const responseHandlerService = new ResponseHandlerService()