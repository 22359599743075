<template>
  <div class="relative w-full" v-click-outside="closeDropdown">
    <label v-if="label" :for="id" class="block text-sm font-medium text-gray-700 mb-1">{{ label }}</label>
    <div class="flex w-full">
      <div :style="dynamicStyle" class="relative flex-grow">
        <div :id="id" @click="toggleDropdown" :class="[
            'w-full bg-white text-left cursor-pointer border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 flex items-center justify-between',
            sizeClasses,
            { 'rounded-r-none': showButton }
          ]">
          <div class="flex-grow overflow-hidden">
            <span v-if="loading" class="text-gray-400">{{ t('globalComponents.customDropdown.loading') }}</span> <span v-else class="block truncate">{{ selectedOptionLabel }}</span>
          </div>
          <span v-if="loading" class="material-symbols-outlined text-gray-400 animate-spin ml-2">progress_activity</span> <span v-else class="material-symbols-outlined text-gray-400 ml-2">
            {{ isOpen ? 'arrow_drop_up' : 'arrow_drop_down' }}
          </span>
        </div>
      </div>
      <button v-if="showButton" @click.stop="onButtonClick" :class="[
          'bg-indigo-600 text-white px-3 rounded-r-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center',
          sizeClasses
        ]">
        <span class="material-symbols-outlined">{{ buttonIcon }}</span>
      </button>
    </div>
    <Teleport to="body">
      <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
        <div v-if="isOpen && !loading" class="fixed z-[9999] mt-1 bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" :style="dropdownStyle" @click.stop>
          <div v-if="showSearch" class="px-2 py-1">
            <CustomInput v-model="searchQuery" type="text" :placeholder="t('globalComponents.customDropdown.search')" @click.stop @input="onSearchInput"/>
          </div>
          <ul class="max-h-60 overflow-auto thin-scrollbar">
            <li v-for="option in filteredOptions" :key="option.value" @click.stop="selectOption(option.value)" class="cursor-pointer select-none relative py-2 pl-3 pr-9 group" :class="{ 'bg-indigo-100': isSelected(option.value), 'hover:bg-gray-100': !isSelected(option.value)}">
              <div class="flex items-center">
                <input v-if="multiSelect" type="checkbox" :checked="isSelected(option.value)" class="mr-2" @click.stop>
                <span class="block truncate" :class="{'font-semibold text-primary': isSelected(option.value), 'font-normal group-hover:text-gray-900': !isSelected(option.value)}">
                  {{ option.label }}
                </span>
              </div>
              <span v-if="isSelected(option.value)" class="absolute inset-y-0 right-0 flex items-center pr-4 text-primary">
                <span class="material-symbols-outlined text-primary">check</span>
              </span>
            </li>
          </ul>
        </div>
      </transition>
    </Teleport>
  </div>
</template>

<script setup>
import { ref, computed, nextTick, onMounted, onUnmounted } from 'vue'
import { useI18n } from 'vue-i18n'
import CustomInput from '@/components/inputs/customInput.vue'

const props = defineProps({
  label: String,
  options: Array,
  modelValue: [String, Array, Number],
  showButton: {
    type: Boolean,
    default: false,
  },
  buttonIcon: {
    type: String,
    default: 'add',
  },
  showSearch: {
    type: Boolean,
    default: false,
  },
  multiSelect: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  minWidth: {
    type: String,
    default: '',
  },
  maxWidth: {
    type: String,
    default: '',
  },
  width: {
    type: String,
    default: '',
  },
})

const { t } = useI18n()
const emit = defineEmits(['update:modelValue', 'buttonClick'])

const isOpen = ref(false)
const searchQuery = ref('')
const id = `dropdown-${Math.random().toString(36).substr(2, 9)}`
const dropdownStyle = ref({})

const selectedOptionLabel = computed(() => {
  if (props.multiSelect) {
    const selectedOptions = props.options.filter(option => props.modelValue.includes(option.value))
    return selectedOptions.length > 0
        ? selectedOptions.map(o => o.label).join(', ')
        : t('dropdown.selectOptions')
  } else {
    const selectedOption = props.options.find(option => option.value === props.modelValue)
    return selectedOption ? selectedOption.label : t('globalComponents.customDropdown.selectAnOption')
  }
})

const dynamicStyle = computed(() => {
  return {
    minWidth: props.minWidth ? `min-w-${props.minWidth}` : '',
    maxWidth: props.maxWidth ? `max-w-${props.maxWidth}` : '',
    width: props.width ? `w-${props.width}` : '',
  }
})

const filteredOptions = computed(() => {
  return props.options.filter(option =>
      option.label.toLowerCase().includes(searchQuery.value.toLowerCase()),
  )
})

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'py-1 px-2 text-sm h-8'
    case 'lg':
      return 'py-3 px-4 text-lg h-12'
    default:
      return 'py-2 px-3 text-base h-10'
  }
})

const toggleDropdown = () => {
  if (!props.loading) {
    isOpen.value = !isOpen.value
    if (isOpen.value) {
      nextTick(() => {
        updateDropdownPosition()
      })
    }
  }
}

const selectOption = (value) => {
  if (props.multiSelect) {
    const newValue = props.modelValue.includes(value)
        ? props.modelValue.filter(v => v !== value)
        : [...props.modelValue, value]
    emit('update:modelValue', newValue)
  } else {
    emit('update:modelValue', value)
    isOpen.value = false
  }
}

const isSelected = (value) => {
  return props.multiSelect
      ? props.modelValue.includes(value)
      : props.modelValue === value
}

const closeDropdown = (event) => {
  if (!event.target.closest('.custom-dropdown')) {
    isOpen.value = false
  }
}

const onButtonClick = () => {
  emit('buttonClick')
}

const updateDropdownPosition = () => {
  const button = document.getElementById(id)
  if (button) {
    const rect = button.getBoundingClientRect()
    dropdownStyle.value = {
      position: 'fixed',
      top: `${rect.bottom + window.scrollY}px`,
      left: `${rect.left + window.scrollX}px`,
      width: `${rect.width}px`,
      maxHeight: '300px',
      overflowY: 'auto',
    }
  }
}

const onSearchInput = (event) => {
  event.stopPropagation()
  searchQuery.value = event.target.value
}

onMounted(() => {
  window.addEventListener('scroll', updateDropdownPosition)
  window.addEventListener('resize', updateDropdownPosition)
})

onUnmounted(() => {
  window.removeEventListener('scroll', updateDropdownPosition)
  window.removeEventListener('resize', updateDropdownPosition)
})

// Click-outside directive
const clickOutside = {
  mounted(el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event)
      }
    }
    document.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideEvent)
  },
}

// Register the directive
const vClickOutside = clickOutside
</script>