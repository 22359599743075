<template>
  <div class="w-full pr-4 h-[450px] bg-gray-100/80 overflow-y-auto thin-scrollbar">
    <div class=" p-4 rounded-lg ">
      <h2 class="text-xl font-bold mb-4">Yorumlar</h2>

      <div v-if="isLoading" class="flex h-full items-center justify-center">
        <div class="flex items-center space-x-2">
          <span class="material-symbols-outlined animate-spin">
            progress_activity
          </span>
        </div>
      </div>
      <div v-if="!isLoading && comments.length === 0" class="text-gray-500 text-sm text-center flex h-full items-center justify-center">
        Hiç yorum yapılmadı
      </div>

      <div v-else-if="!isLoading" class="space-y-4">
        <div v-for="comment in comments" :key="comment.commentId" class="bg-white p-3 rounded shadow">
          <div v-for="message in comment.messages" :key="message.commenterId">
            <div v-if="message.writerType === 1" class="flex items-center space-x-2 mb-2">
              <img src="../../../../../../../../assets/navbar/avatar.png" alt="" class="w-5 h-5 rounded-full"/> <span class="font-semibold text-sm">Kullanıcı</span>
            </div>
            <p class="text-gray-700 mb-2 text-xs" v-if="message.writerType === 1">
              {{ message.message }} </p>

            <!-- Yanıt olup olmadığını kontrol et (writerType == 2) -->
            <div v-if="message.writerType === 2" class="pl-4  border-l-2 border-gray-200">
              <div class="flex items-center space-x-2 mb-2 mt-4">
                <img src="@/assets/logo/logoB.svg" alt="" class="w-5 h-5 rounded-full"/> <span class="font-semibold text-sm">AI</span>
              </div>
              <p class="text-gray-700 mb-2 text-xs">{{ message.message }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import axios from '@/plugins/axiosInstance'
import { socialMedia } from '@/networking/urlManager'
import { useStore } from 'vuex'

const props = defineProps({
  postId: {
    type: [Number, String],
    required: true,
  },
})

const store = useStore()
const comments = ref([])
const isLoading = ref(true) // Yüklenme durumunu tutan değişken

const getAll = async () => {
  try {
    const response = await axios.get(socialMedia.commentsGetAll, {
      params: {
        postId: props.postId,
        assetId: store.state.assetId,
        platform: 1, //testte 1 canlıda 2 atılacak
      },
    })
    comments.value = response.data.data
  } catch (error) {
    console.error('Error fetching comments:', error)
  } finally {
    isLoading.value = false
  }
}

onMounted(() => {
  getAll()
})
</script>
