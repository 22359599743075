<template>
  <div class="relative">
    <button @click="toggleMenu" class="flex items-center justify-center w-8 h-8 rounded-full hover:bg-gray-200 focus:outline-none transition duration-150 ease-in-out">
      <span class="material-symbols-outlined text-gray-600 !text-[18px]" :class="isOpen ? '!text-primary' : ''">
        language</span>
    </button>
    <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95">
      <div v-if="isOpen" class="origin-top-right absolute right-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="language-menu">
          <a v-for="lang in languages" :key="lang.code" href="#"
             @click.prevent="changeLanguage(lang.code)"
             class="flex items-center px-3 py-1 text-xs text-gray-700 hover:bg-gray-100 hover:text-gray-900"
             :class="{ 'bg-gray-100': currentLanguage === lang.code }"
             role="menuitem">
            <span class="material-symbols-outlined mr-2 text-gray-400"
                  v-if="currentLanguage === lang.code">check</span>
            <span class="material-symbols-outlined mr-2 text-transparent"
                  v-else>check</span>
            {{ lang.name }}
          </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'LanguageMenu',
  data() {
    return {
      isOpen: false,
      languages: [
        { code: 'en', name: 'English' },
        { code: 'tr', name: 'Türkçe' },
      ],
    }
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale
    },
  },
  methods: {
    toggleMenu() {
      this.isOpen = !this.isOpen
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang
      this.$store.commit('setLanguage', lang)
      this.isOpen = false
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside)
  },
}
</script>