<template>
  <div class="w-full flex-col space-y-4">
    <div class="flex flex-col sm:flex-row space-x-0 space-y-4 sm:space-x-4 sm:space-y-0 items-center">
      <button @click="addSource" class="w-full sm:w-auto bg-secondary rounded-lg hover:bg-secondary/90 px-10 py-1 text-white">
        {{ $t('assets.components.create.components.source.components.sourceTable.buttons.newSource') }}
      </button>
      <button @click="openModal" class="w-full sm:w-auto border border-secondary text-secondary hover:bg-secondary/70 hover:text-white py-1 px-8 rounded-lg animate-pulse flex items-center space-x-1 justify-center" :class="{
          'animate-none ': isAuth,
        }">
        <span>{{ $t('assets.components.create.components.source.components.sourceTable.buttons.authorization') }}</span>
        <span class="material-symbols-outlined text-[20px]" v-if="!isAuth">
          lock_open
        </span> <span class="material-symbols-outlined text-[20px]" v-else> lock </span>
      </button>
    </div>

    <div class="overflow-x-auto custom-scrollbar">
      <table class="w-full min-w-[1000px]">
        <thead>
        <tr>
          <th class="table-th">
            {{ $t('assets.components.create.components.source.components.sourceTable.fields.category') }}
          </th>
          <th class="table-th">
            {{ $t('assets.components.create.components.source.components.sourceTable.fields.getContentUrl') }}
          </th>
          <th class="table-th">
            {{ $t('assets.components.create.components.source.components.sourceTable.fields.addContentUrl') }}
          </th>
          <th class="table-th">
            {{ $t('assets.components.create.components.source.components.sourceTable.fields.editContentUrl') }}
          </th>
          <th class="table-th">#</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, index) in sources" :key="index">
          <td class="table-td">
            <sourceCategory v-model="item.sourceCategoryId"/>
          </td>
          <td class="table-td">
            <input class="source-input" v-model="item.getUrl" type="url"/>
          </td>
          <td class="table-td">
            <input class="source-input" v-model="item.addUrl" type="url"/>
          </td>
          <td class="table-td">
            <input class="source-input" v-model="item.editUrl" type="url"/>
          </td>
          <td class="table-td min-w-12 max-w-12">
            <button v-if="index > 0" class="bg-secondary rounded-lg h-8 w-8 flex items-center justify-center text-white" @click="removeSource(index)">
              <span class="material-symbols-outlined"> close </span>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <customModal v-model="showModal" @update:show="showModal = $event">
    <div class="flex flex-col space-y-5 items-center">
      <div class="">
        <h2 class="text-2xl font-bold text-secondary">
          {{ $t('assets.components.create.components.source.components.sourceTable.modal.title') }}</h2>
        <p class="text-center text-gray-500">
          {{ $t('assets.components.create.components.source.components.sourceTable.modal.description') }} </p>
      </div>
      <div class="flex flex-col w-full mt-2">
        <customInput v-model="authUrl" :label="$t('assets.components.create.components.source.components.sourceTable.modal.authUrl')" :placeholder="$t('assets.components.create.components.source.components.sourceTable.modal.authUrl')"/>
        <customInput v-model="username" :label="$t('assets.components.create.components.source.components.sourceTable.modal.username')" :placeholder="$t('assets.components.create.components.source.components.sourceTable.modal.username')"/>
        <customInput v-model="password" :label="$t('assets.components.create.components.source.components.sourceTable.modal.password')" :placeholder="$t('assets.components.create.components.source.components.sourceTable.modal.password')" type="password"/>
        <customButton :isDisabled="loading" :isLoading="loading" :buttonText="loading ? $t('assets.components.create.components.source.components.sourceTable.buttons.loading') : $t('assets.components.create.components.source.components.sourceTable.buttons.save')" @click="sendRequest" :fullWidth="true"/>
      </div>
    </div>
  </customModal>

  <customDialog v-model="showConfirmDialog" :title="$t('assets.components.create.components.source.components.sourceTable.dialog.deleteTitle')" :message="$t('assets.components.create.components.source.components.sourceTable.dialog.deleteMessage')" :showCancelButton="true" :cancelButtonText="$t('assets.components.create.components.source.components.sourceTable.dialog.cancel')" :confirmButtonText="$t('assets.components.create.components.source.components.sourceTable.dialog.confirm')" @confirm="confirmDelete" @update:show="showConfirmDialog = $event"/>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import sourceCategory from './sourceCategory.vue'
import customModal from '@/components/modals/customModal.vue'
import customDialog from '@/components/modals/customDialog.vue'
import customInput from '@/components/inputs/customInput.vue'
import customButton from '@/components/buttons/customButton.vue'
import { assets } from '@/networking/urlManager'
import axios from '@/plugins/axiosInstance'


export default {
  name: 'sourceTable',
  props: {
    modelValue: [Array, String, Number, Object],
  },
  emits: ['authList', 'update:modelValue'],
  components: {
    sourceCategory,
    customModal,
    customInput,
    customButton,
    customDialog,
  },
  computed: {
    ...mapState({
      sources: (state) => state.assetData.sources,
      buttonLoading: (state) => state.assetData.loading,
    }),
  },
  data() {
    return {
      username: '',
      password: '',
      authUrl: '',
      isAuth: false,
      loading: false,
      load: true,
      showModal: false,
      showConfirmDialog: false,
      confirmDeleteIndex: null,
    }
  },
  methods: {
    ...mapActions(['setAuthList', 'setSources']),
    addSource() {
      const newSource = {
        categoryId: '',
        getUrl: '',
        addUrl: '',
        editUrl: '',
      }
      this.setSources([...this.sources, newSource])
    },
    removeSource(index) {
      this.confirmDeleteIndex = index
      this.showConfirmDialog = true
    },
    confirmDelete() {
      const newSources = [...this.sources]
      newSources.splice(this.confirmDeleteIndex, 1)
      this.setSources(newSources)
      this.confirmDeleteIndex = null
    },
    openModal() {
      this.showModal = true
    },
    sendRequest() {
      if (!this.authUrl || !this.username || !this.password) {
        this.$responseHandlerService.handleError(this.$t('assets.components.create.components.source.components.sourceTable.errors.fillAllFields'))
        return
      }
      this.loading = true
      axios.post(assets.customerTokenController, {
        authUrl: this.authUrl,
        username: this.username,
        password: this.password,
      })
          .then(() => {
            this.loading = false
            this.isAuth = true
            this.setAuthList({
              authUrl: this.authUrl,
              username: this.username,
              password: this.password,
              isAuth: true,
            })
            this.showModal = false
            this.$store.commit('setSnackbarPosition', { top: true, left: true })
            this.$responseHandlerService.handleSuccess(this.$t('assets.components.create.components.source.components.sourceTable.success.authorizationSuccess'))
          })
          .catch((error) => {
            this.loading = false
            this.$store.commit('setSnackbarPosition', { top: true, left: true })
            this.$responseHandlerService.handleError(error.response.data.message)
            console.error(error)
          })
    },
  },
  watch: {
    modelValue: {
      handler: function (newValue) {
        this.setSources(newValue)
      },
      immediate: true,
    },
  },
  mounted() {
    this.username = this.$store.state.assetData.authList.username
    this.password = this.$store.state.assetData.authList.password
    this.authUrl = this.$store.state.assetData.authList.authUrl
    this.isAuth = this.$store.state.assetData.authList.isAuth
  },
}
</script>

<style scoped>
.source-input {
  @apply w-full rounded-lg p-2 h-10 border;
}

.table-th {
  @apply border p-2 text-center text-xs font-medium text-black uppercase tracking-wider w-3/12 rounded-bl-xl whitespace-nowrap cursor-pointer;
}

.table-td {
  @apply border p-2 text-center text-xs font-medium text-black uppercase tracking-wider w-3/12 rounded-bl-xl whitespace-nowrap cursor-pointer;
}

@keyframes pulse {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.animate-none {
  animation: none;
}
</style>
