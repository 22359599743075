<template>
  <div>
    <!--    <headerCard :label="t('users.headerCard.headerLabel')" :description="t('users.headerCard.headerDescription')" :is-button-show="true" :buttonLabel="t('users.addUser')" @buttonClick="() => openModal('add')"/>-->
    <div class="layout-spacing">
      <div class="bg-white py-3 px-6 rounded-2xl col-span-1 w-full">
        <div class="flex w-full justify-end items-center mb-4 pr-1">
          <div class="relative">
            <input class="border-2 border-secondary px-4 py-2 rounded w-[250px] focus:outline-none" type="text" v-model="searchQuery" @keyup.enter="fetchUserData" :placeholder="t('users.searchPlaceholder')"/>
            <span @click="fetchUserData" class="material-symbols-outlined absolute right-3 top-1/2 transform -translate-y-1/2 text-secondary cursor-pointer">
              search
            </span>
          </div>
        </div>
        <div v-if="userList.length" class="border border-secondary/30 rounded-lg overflow-y-auto thin-scrollbar relative" style="height: calc(100vh - 390px);">
          <table class="min-w-full divide-y divide-gray-200 bg-white">
            <thead class="bg-gray-50 sticky top-0 z-10 shadow-md shadow-secondary/10 text-sm">
            <tr>
              <th scope="col" class="px-6 py-4 text-left thead-text w-5/12">
                {{ t('users.nameColumn') }}
              </th>
              <th scope="col" class="px-6 py-4 text-left thead-text w-5/12">
                {{ t('users.emailColumn') }}
              </th>
              <th scope="col" class="px-6 py-4 text-center thead-text w-2/12"></th>
            </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200 text-[15px]">
            <template v-if="load">
              <tr>
                <td colspan="3" class="text-center w-full">
                  <div class="flex items-center justify-center h-[calc(100vh-450px)]">
                    <loading/>
                  </div>
                </td>
              </tr>
            </template>
            <template v-else-if="userList.length">
              <tr v-for="(user, index) in userList" :key="index" class="hover:bg-gray-100 transition-all duration-200 ease-in-out cursor-pointer">
                <td class="px-6 py-2 border-b text-left w-5/12">{{ user.name }}</td>
                <td class="px-6 py-2 border-b text-left w-5/12">{{ user.email }}</td>
                <td class="px-6 py-2 border-b text-center w-2/12">
                  <button @click="editUser(user)">
                      <span class="material-symbols-outlined cursor-pointer !text-[23px] text-secondary">
                        edit
                      </span>
                  </button>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td colspan="3" class="text-center w-full">
                <div class="flex items-center justify-center h-[calc(100vh-450px)]">
                  <span class="no-data font-semibold">{{ t('users.noData') }}</span>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="w-full flex items-center justify-end px-2 h-24">
          <pagination :totalCount="totalCount" :currentPage="currentPage" :itemsPerPage="10" @page-changed="fetchUserData"/>
        </div>
      </div>
    </div>
  </div>
  <userFormModal v-model:show="showModal" :isEditing="isEditing" :userData="selectedUser" @update:show="(val) => showModal = val" @update="fetchUserData"/>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { users } from '@/networking/urlManager'

// Global Components
import pagination from '@/components/pagination/index.vue'
import loading from '@/components/loadings/loading.vue'
import userFormModal from './components/userFormModal.vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const searchQuery = ref('')
const userList = ref([])
const load = ref(false)
const showModal = ref(false)
const isEditing = ref(false)
const selectedUser = ref({})
const currentPage = ref(1)
const totalCount = ref(0)

const fetchUserData = async (page = currentPage.value) => {
  load.value = true
  try {
    const response = await axios.get(users.getAll, {
      params: {
        search: searchQuery.value,
        page: page - 1,
        sort: 'id DESC',
      },
    })
    userList.value = response.data.data.detail
    totalCount.value = response.data.data.totalCount
  } catch (error) {
    console.error(error)
  } finally {
    load.value = false
  }
}

const editUser = (user) => {
  openModal('edit', user)
}

const openModal = (mode, user = null) => {
  isEditing.value = mode === 'edit'
  selectedUser.value = user || {}
  showModal.value = true
}

onMounted(() => {
  fetchUserData(1)
})

watch(currentPage, () => {
  fetchUserData()
})

</script>