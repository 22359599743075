<template>
  <div class="custom-table-container p-4">
    <div class="flex flex-col sm:flex-row justify-between sm:items-center mb-4">
      <slot name="table-actions"></slot>
      <div v-if="!$slots['table-actions']"></div>
      <div class="flex items-center w-full sm:w-1/2 sm:justify-end mt-2 sm:mt-0">
        <slot name="extra-actions"></slot>
        <SearchSortFilterBox :search-icon="searchIcon" :search-placeholder="searchPlaceholderComputed" :show-searchbar="showSearch" :show-search-text="showSearchText" :search-text="searchTextComputed" :show-sort="showSort" :show-filter="showFilter" :sort-options="sortOptions" :filter-options="filterOptions" @search="handleSearch" @sort="handleSort" @filter="handleFilter"/>
      </div>
    </div>

    <div class="table-wrapper thin-scrollbar relative" :style="tableWrapperStyle" ref="tableWrapper">
      <table class="w-full" :style="{ minWidth: props.minWidth }">
        <thead>
        <tr>
          <th v-for="column in visibleColumns" :key="column.key" :class="getColumnClass(column, 'th')">
            <div :class="getHeaderAlignmentClass(column)" class="text-xs">
              {{ column.label }} <span v-if="column.sortable" class="material-symbols-outlined !text-[16px] cursor-pointer" @click="handleColumnSort(column.key)">
                  {{ getSortIndicator(column.key) }}
                </span>
            </div>
          </th>
        </tr>
        </thead>

        <tbody>
        <template v-if="isLoading">
          <tr v-for="i in 10" :key="'skeleton-' + i">
            <td v-for="column in visibleColumns" :key="column.key" :class="getColumnClass(column, 'td')">
              <div class="skeleton-loader animate-pulse"></div>
            </td>
          </tr>
        </template>
        <template v-else-if="internalData.length === 0">
          <tr class="h-full">
            <td :colspan="visibleColumns.length" class="h-full">
              <div class="absolute inset-0 flex items-center justify-center">
                <p class="text-center py-4 text-gray-500">
                  {{ noDataTextComputed }} </p>
              </div>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr v-for="(item, index) in internalData" :key="item.id || index" :ref="el => { if (el) rowRefs[index] = el }" :class="getRowClasses(index)" :style="getRowStyle(index)" @mousedown="startDrag($event, index)" @click="handleRowClick(item)">
            <td v-for="column in visibleColumns" :key="column.key" :class="getColumnClass(column, 'td')">
              <slot :name="column.key" :item="item" :index="index">
                <div :class="getCellAlignmentClass(column)" class="h-full flex items-center">
                    <span v-if="column.key === 'dragHandle' && props.draggable" class="drag-handle material-symbols-outlined cursor-move mr-2">
                      drag_indicator
                    </span> <span class="truncate">{{ item[column.key] }}</span>
                </div>
              </slot>
            </td>
          </tr>
        </template>
        </tbody>
      </table>
    </div>

    <div class="mt-4 flex justify-end">
      <pagination v-if="pageCount > 1" :totalCount="totalCount" :pageCount="pageCount" :currentPage="currentPage" :itemsPerPage="itemsPerPage" @page-changed="handlePageChange"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useI18n } from 'vue-i18n'
import pagination from '@/components/pagination/index.vue'
import SearchSortFilterBox from '@/components/searchSortFilter/index.vue'

const { t } = useI18n()

const props = defineProps({
  data: { type: Array, required: true },
  columns: { type: Array, required: true },
  isLoading: { type: Boolean, default: false },
  itemsPerPage: { type: Number, default: 15 },
  totalCount: { type: Number },
  pageCount: { type: Number },
  currentPage: { type: Number },
  sortKey: { type: String, default: '' },
  sortOrder: { type: String, default: 'DESC' },
  searchIcon: { type: String, default: 'search' },
  showSearch: { type: Boolean, default: false },
  showSearchText: { type: Boolean, default: false },
  showSort: { type: Boolean, default: false },
  showFilter: { type: Boolean, default: false },
  sortOptions: { type: Array, default: () => [] },
  filterOptions: { type: Array, default: () => [] },
  minWidth: { type: String, default: '100%' },
  minHeight: { type: String, default: '300px' },
  maxHeight: { type: String, default: '60vh' },
  draggable: { type: Boolean, default: false },
  noDataText: { type: String, default: '' },
  searchPlaceholder: { type: String, default: '' },
  searchText: { type: String, default: '' },
})

const emit = defineEmits(['row-click', 'search', 'sort', 'filter', 'page-change', 'reorder'])

const internalData = ref([...props.data])
const isDragging = ref(false)
const draggedIndex = ref(null)
const dragOverIndex = ref(null)
const rowRefs = ref({})
const mouseY = ref(0)
const dragOffset = ref(0)
const tableWrapper = ref(null)

const noDataTextComputed = computed(() => props.noDataText || t('globalComponents.table.noDataFound'))
const searchPlaceholderComputed = computed(() => props.searchPlaceholder || t('globalComponents.table.searchPlaceholder'))
const searchTextComputed = computed(() => props.searchText || t('globalComponents.table.search'))

watch(() => props.data, (newData) => {
  internalData.value = [...newData]
})

const visibleColumns = computed(() => props.columns.filter(column => !column.hidden))

const tableWrapperStyle = computed(() => ({
  minHeight: props.minHeight,
  maxHeight: props.maxHeight,
  overflowX: 'auto',
  overflowY: 'auto',
}))

const getColumnClass = (column, elementType) => {
  const baseClass = `p-2 text-sm`
  const widthClass = column.width || ''
  if (elementType === 'th') {
    return `${baseClass} ${widthClass} font-medium text-gray-500 uppercase tracking-tight`
  }
  return `${baseClass} ${widthClass} h-[36px]`
}

const getHeaderAlignmentClass = (column) => {
  const baseClass = 'flex items-center gap-1 h-full'
  switch (column.align) {
    case 'center':
      return `${baseClass} justify-center`
    case 'right':
      return `${baseClass} justify-end`
    default:
      return `${baseClass} justify-start`
  }
}

const getCellAlignmentClass = (column) => {
  const baseClass = 'flex items-center h-full'
  switch (column.align) {
    case 'center':
      return `${baseClass} justify-center`
    case 'right':
      return `${baseClass} justify-end`
    default:
      return `${baseClass} justify-start`
  }
}

const handleSearch = (query) => emit('search', query)

const handleSort = ({ key, order }) => emit('sort', { key, order })

const handleFilter = (filters) => emit('filter', filters)

const handleColumnSort = (key) => {
  let newOrder
  if (props.sortKey !== key) {
    newOrder = 'ASC'
  } else {
    switch (props.sortOrder) {
      case '':
        newOrder = 'ASC'
        break
      case 'ASC':
        newOrder = 'DESC'
        break
      case 'DESC':
        newOrder = ''
        break
      default:
        newOrder = 'ASC'
    }
  }

  if (newOrder === '') emit('sort', { key: '', order: '' })
  else emit('sort', { key, order: newOrder })
}

const getSortIndicator = (key) => {
  if (props.sortKey !== key) {
    return 'unfold_more'
  }
  switch (props.sortOrder) {
    case 'ASC':
      return 'expand_less'
    case 'DESC':
      return 'expand_more'
    default:
      return 'unfold_more'
  }
}

const handlePageChange = (page) => emit('page-change', page)

const handleRowClick = (item) => emit('row-click', item)

const startDrag = (event, index) => {
  if (!props.draggable) return

  event.preventDefault()
  isDragging.value = true
  draggedIndex.value = index
  dragOverIndex.value = index
  mouseY.value = event.clientY

  const draggedEl = rowRefs.value[index]
  dragOffset.value = event.clientY - draggedEl.getBoundingClientRect().top

  document.addEventListener('mousemove', onMouseMove)
  document.addEventListener('mouseup', stopDrag)
}

const onMouseMove = (event) => {
  if (!isDragging.value) return

  const rows = Object.values(rowRefs.value)
  const draggedRect = rows[draggedIndex.value].getBoundingClientRect()
  const tableTop = rows[0].getBoundingClientRect().top
  const mouseIndex = Math.floor((event.clientY - tableTop) / draggedRect.height)

  let newIndex = Math.max(0, Math.min(mouseIndex, rows.length - 1))

  if (newIndex !== dragOverIndex.value) {
    dragOverIndex.value = newIndex
  }
}

const stopDrag = () => {
  if (!isDragging.value) return

  const fromIndex = draggedIndex.value
  const toIndex = dragOverIndex.value

  if (fromIndex !== toIndex) {
    const updatedData = [...internalData.value]
    const [movedItem] = updatedData.splice(fromIndex, 1)
    updatedData.splice(toIndex, 0, movedItem)
    internalData.value = updatedData
    emit('reorder', { fromIndex, toIndex, updatedData })
  }

  isDragging.value = false
  draggedIndex.value = null
  dragOverIndex.value = null

  document.removeEventListener('mousemove', onMouseMove)
  document.removeEventListener('mouseup', stopDrag)
}

const getRowClasses = (index) => {
  return [
    { 'cursor-move': props.draggable },
    { 'opacity-75 z-10 shadow-lg': isDragging.value && draggedIndex.value === index },
    { 'hover:bg-gray-50': !isDragging.value || draggedIndex.value !== index },
  ]
}

const getRowStyle = (index) => {
  if (!isDragging.value || draggedIndex.value === null || dragOverIndex.value === null) return {}

  const draggedRect = rowRefs.value[draggedIndex.value].getBoundingClientRect()

  if (index === draggedIndex.value) {
    return {
      transform: `translateY(${(dragOverIndex.value - draggedIndex.value) * draggedRect.height}px)`,
      transition: 'transform 0.2s ease-out',
      zIndex: 10,
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    }
  } else if (
      (dragOverIndex.value > draggedIndex.value && index > draggedIndex.value && index <= dragOverIndex.value) ||
      (dragOverIndex.value < draggedIndex.value && index < draggedIndex.value && index >= dragOverIndex.value)
  ) {
    const direction = dragOverIndex.value > draggedIndex.value ? -1 : 1
    return {
      transform: `translateY(${direction * draggedRect.height}px)`,
      transition: 'transform 0.2s ease-out',
    }
  }

  return {
    transform: 'translateY(0)',
    transition: 'transform 0.2s ease-out',
  }
}

onMounted(() => {
  document.addEventListener('mousemove', onMouseMove)
})

onBeforeUnmount(() => {
  document.removeEventListener('mousemove', onMouseMove)
  document.removeEventListener('mouseup', stopDrag)
})
</script>

<style scoped>
.custom-table-container {
  @apply overflow-hidden rounded-md bg-white;
}

.table-wrapper {
  @apply overflow-x-auto overflow-y-auto;
}

table {
  @apply border-collapse w-full;
}

thead {
  @apply bg-gray-50;
}

th {
  @apply sticky top-0 z-10 bg-gray-50 shadow-sm;
}

tr {
  @apply border-b border-gray-200;
}

tr:last-child {
  @apply border-b-0;
}

td, th {
  @apply align-middle;
}

.drag-handle {
  cursor: move;
}

.skeleton-loader {
  @apply h-4 bg-gray-200 rounded;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.dragging {
  cursor: grabbing !important;
}
</style>