<template>
  <div class="h-[80vh] w-full relative" :class="!loading ? 'component-layout' :'' ">
    <div v-if="loading" class="loading-overlay h-[80vh]">
      <loading/>
    </div>
    <sourceTable v-else :use-store="false" v-model="List" :asset-service-type-prop="Number(store.state.assetServiceType)"/>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import sourceTable from './components/sourceTable.vue'
import axios from '@/plugins/axiosInstance'
import { settings } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import Loading from '@/components/loadings/loading.vue'

const { t } = useI18n()
const store = useStore()

const emit = defineEmits(['saving'])

const List = ref([])
const loading = ref(false)

const fetch = async () => {
  loading.value = true
  try {
    const response = await axios.get(settings.sourceConfiguration, {
      params: { assetId: store.state.assetId },
    })
    List.value = response.data.data
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.errorFetchingData'))
    console.error(error)
  } finally {
    setTimeout(() => loading.value = false, 500)
  }
}

const save = async () => {
  emit('saving', true)
  try {
    await axios.put(settings.sourceConfiguration, {
      source: List.value,
      assetId: store.state.assetId,
    })
    responseHandlerService.handleSuccess(t('settings.successfullyUpdated'))
  } catch (error) {
    responseHandlerService.handleError(error, t('settings.errorUpdatingSettings'))
  } finally {
    emit('saving', false)
  }
}

onMounted(fetch)

defineExpose({ save })
</script>

<style scoped>
.loading-overlay {
  @apply absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10;
}
</style>