<template>
  <div class="w-full h-screen bg-white flex items-center justify-center">
    <div class="flex flex-col justify-center items-center space-y-10 w-full">
      <!-- Logo -->
      <img :src="require('@/assets/logo/logoA.svg')" alt="logo" class="h-20"/>

      <!-- Sign In Form -->
      <form @submit.prevent="signIn" class="space-y-4 w-3/4 lg:w-2/4">
        <div class="flex flex-col w-full space-y-4">
          <!-- Email Input -->
          <customInput icon="mail" :show-icon="true" type="email" v-model="email" :placeholder="t('auth.emailPlaceholder')" additional-input-class="bg-slate-100 border-none" size="md"/>
          <!-- Password Input -->
          <customInput icon="passkey" :show-icon="true" type="password" v-model="password" :placeholder="t('auth.passwordPlaceholder')" additional-input-class="bg-slate-100 border-none" size="md"/>
        </div>

        <!-- Remember Me & Forgot Password -->
        <div class="flex justify-between items-center font-semibold">
          <div class="relative flex items-center">
            <input type="checkbox" id="rememberMe" :checked="rememberMe" @change="handleRememberMeChange" class="hidden"/>
            <div class="w-4 h-4 bg-primary/25 rounded flex justify-center items-center" :class="{ 'bg-primary': rememberMe }">
              <span v-if="rememberMe" class="material-symbols-outlined text-black font-semibold text-[18px]">
                done
              </span>
            </div>
            <label for="rememberMe" class="text-xs text-gray-600 ml-2"> {{ t('auth.signIn.rememberMe') }} </label>
          </div>
          <a @click.prevent="handleForgotPasswordClick" class="text-xs text-primary hover:underline cursor-pointer"> {{ t('auth.signIn.forgotPassword') }} </a>
        </div>

        <!-- Sign In Button -->
        <customButton type="submit" :buttonText="t('auth.signIn.signInButton')" :isLoading="isLoading" :loadingText="t('auth.signIn.signingIn')" :fullWidth="true" color="secondary" size="md"/>
      </form>
    </div>
  </div>

  <!-- Forgot Password Modal -->
  <forgot-password-modal v-model="showForgotPasswordModal" :initial-email="email"/>
</template>

<script setup>
import ForgotPasswordModal from './forgotPasswordModal.vue'
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { auth } from '@/networking/urlManager'
import customButton from '@/components/buttons/customButton.vue'
import customInput from '@/components/inputs/customInput.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

const store = useStore()
const router = useRouter()
const { t } = useI18n()

// Form state
const email = ref('')
const password = ref('')
const isLoading = ref(false)
const showForgotPasswordModal = ref(false)

// Computed properties from store
const userEmail = computed(() => store.state.userEmail)
const rememberMe = computed(() => store.state.rememberMe)

// Store mutations and actions
const setUserData = store.commit.bind(store, 'setUserData')
const setRememberMe = store.dispatch.bind(store, 'setRememberMe')
const setUserEmail = store.dispatch.bind(store, 'setUserEmail')

// Email validation
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

// Form submission handler
const signIn = async () => {
  if (!email.value || !password.value) {
    responseHandlerService.handleError(t('auth.fillAllFields'))
    return
  }

  if (!validateEmail(email.value)) {
    responseHandlerService.handleError(t('auth.invalidEmail'))
    return
  }

  if (rememberMe.value) {
    setUserEmail(email.value)
  } else {
    setUserEmail('')
  }

  isLoading.value = true

  try {
    const res = await axios.post(auth.signIn, {
      email: email.value,
      password: password.value,
    })
    setUserData(res.data.data)
    await router.push('/assets')
  } catch (err) {
    responseHandlerService.handleError(err, t('auth.error.general'))
  } finally {
    isLoading.value = false
  }
}

// Remember me handler
const handleRememberMeChange = (event) => {
  setRememberMe(event.target.checked)
  if (event.target.checked) {
    setUserEmail(email.value)
  } else {
    setUserEmail('')
  }
}

// Forgot password handler
const handleForgotPasswordClick = () => {
  showForgotPasswordModal.value = true
}

// Lifecycle hooks
onMounted(() => {
  if (rememberMe.value) {
    email.value = userEmail.value
  }
})
</script>