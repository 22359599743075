<template>
  <VueFinalModal v-model="showModal" class="flex justify-center items-center" content-class="relative bg-white rounded-lg w-[500px] max-w-full mx-4 p-4 border" :click-to-close="false" :esc-to-close="true">
    <div class="space-y-4">
      <!-- Modal Header -->
      <div class="flex justify-between items-center pb-2 border-b">
        <h3 class="text-lg font-semibold text-gray-900">
          {{ t('settings.components.configuration.components.keywordsTable.addNewKeywords') }} </h3>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-500">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="space-y-3">
        <div v-for="(keyword, index) in keywords" :key="index" class="flex items-center">
          <div class="flex-grow mr-2">
            <CustomInput v-model="keyword.name" :placeholder="t('settings.components.configuration.components.keywordsTable.enterKeyword')" size="sm"/>
          </div>
          <div class="w-28 mr-2">
            <CustomSelect v-model="keyword.priority" :options="priorityOptions" :placeholder="t('settings.components.configuration.components.keywordsTable.selectPriority')" size="sm"/>
          </div>
          <div class="w-8 flex justify-center">
            <button v-if="index !== 0" @click="removeKeyword(index)" class="text-red-500 hover:text-red-700 p-1">
              <span class="material-symbols-outlined text-sm">delete</span>
            </button>
          </div>
        </div>
      </div>

      <!-- Add More Button -->
      <div v-if="keywords.length < 5" @click="addKeyword" class="flex items-center text-sm text-secondary hover:text-secondary/70 cursor-pointer">
        <span class="material-symbols-outlined mr-1 text-sm">add</span> <span>{{ t('settings.components.configuration.components.keywordsTable.addAnotherKeyword') }}</span>
      </div>

      <!-- Modal Footer -->
      <div class="flex justify-end space-x-3 pt-4">
        <CustomButton type="button" :button-text="t('common.cancel')" @click="closeModal" color="secondary" variant="outline" size="sm"/>
        <CustomButton type="submit" :button-text="t('common.save')" @click="handleSave" :disabled="!isValid" color="primary" size="sm"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import CustomButton from '@/components/buttons/customButton.vue'
import CustomInput from '@/components/inputs/customInput.vue'
import CustomSelect from '@/components/inputs/customSelect.vue'

const { t } = useI18n()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['update:modelValue', 'save'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const keywords = ref([{ name: '', priority: null }])

const priorityOptions = computed(() =>
    Array.from({ length: 10 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() })),
)

const isValid = computed(() =>
    keywords.value.some(k => k.name.trim() !== '' && k.priority !== null),
)

const addKeyword = () => {
  if (keywords.value.length < 5) {
    keywords.value.push({ name: '', priority: null })
  }
}

const removeKeyword = (index) => {
  keywords.value.splice(index, 1)
}

const handleSave = () => {
  const validKeywords = keywords.value.filter(k => k.name.trim() !== '' && k.priority !== null)
  emit('save', validKeywords)
  closeModal()
}

const closeModal = () => {
  showModal.value = false
  keywords.value = [{ name: '', priority: null }]
}

// Reset form when modal opens
watch(() => showModal.value, (newValue) => {
  if (newValue) {
    keywords.value = [{ name: '', priority: null }]
  }
})
</script>