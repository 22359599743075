<template>
  <div class="flex flex-col mb-5" :class="containerClass">
    <label v-if="label" :class="[labelClass, additionalLabelClass]"> {{ label }} </label>
    <p v-if="description" :class="[descriptionClass, additionalDescriptionClass]">
      {{ description }} </p>
    <div class="border-2 border-dashed border-secondary rounded-xl px-3 py-2 relative" @dragover.prevent @drop="onDrop">
      <input type="file" class="absolute inset-0 w-full h-full opacity-0 cursor-pointer" @change="onFileChange" ref="customFileInput"/>
      <div class="py-10">
        <div v-if="!fileUrl" class="text-center">
          <p>{{ $t('globalComponets.customFileInput.dragDropText') }}</p>
          <button class="mt-2 px-3 py-2 bg-secondary text-white rounded-xl focus:outline-none" type="button" @click="onClick">
            {{ $t('globalComponets.customFileInput.selectFileButton') }}
          </button>
        </div>
        <div v-else class="flex items-center p-2 border-2 border-secondary rounded-xl">
          <img :src="fileUrl" alt="Selected file" class="w-16 h-16 object-contain rounded-xl"/>
          <p class="mx-2">{{ fileName }}</p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from 'vue'

export default {
  name: 'CustomFileInput',
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    labelClass: {
      type: String,
      default: 'font-bold mb-1 text-secondary',
    },
    descriptionClass: {
      type: String,
      default: 'text-secondary text-sm mb-2',
    },
    additionalDescriptionClass: {
      type: String,
      default: '',
    },
    additionalLabelClass: {
      type: String,
      default: '',
    },
    containerClass: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const fileInput = ref(null)
    const fileUrl = ref(null)
    const fileName = ref(null)

    const onClick = () => {
      fileInput.value.click()
    }

    const onFileChange = (event) => {
      if (event.target.files.length > 0) {
        const file = event.target.files[0]
        emit('file-changed', file)

        const reader = new FileReader()
        reader.onload = (e) => {
          fileUrl.value = e.target.result
        }
        reader.readAsDataURL(file)
        fileName.value = file.name
      }
    }

    const onDrop = (event) => {
      event.preventDefault()
      if (event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0]
        emit('file-changed', file)

        const reader = new FileReader()
        reader.onload = (e) => {
          fileUrl.value = e.target.result
        }
        reader.readAsDataURL(file)
        fileName.value = file.name
      }
    }

    const onDelete = () => {
      fileUrl.value = null
      fileName.value = null
      fileInput.value.value = null
      emit('file-changed', null)
    }

    return {
      fileInput,
      onClick,
      onFileChange,
      onDrop,
      fileUrl,
      fileName,
      onDelete,
    }
  },
}
</script>
