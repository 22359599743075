import axios from 'axios'
import store from '../store'
import router from '../router'
import { responseHandlerService } from './responseHandlerService'

const instance = axios.create({ timeout: 300000 })

instance.CancelToken = axios.CancelToken
instance.isCancel = axios.isCancel

instance.interceptors.request.use(
    (config) => {
      const token = store.state.userData.token

      if (token) {
        config.headers.Authorization = `Bearer ${token}`
      }

      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      config.headers.Timezone = timeZone ? timeZone : 'Europe/Istanbul'

      return config
    },
    (error) => {
      return Promise.reject(error)
    },
)

instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            store.dispatch('clearUserData')
            router.push('/')
            if (!responseHandlerService.unauthorizedSnackbarDisplayed) {
              responseHandlerService.handleError(new Error('Yetkisiz erişim!'))
              responseHandlerService.unauthorizedSnackbarDisplayed = true
            }
            break
          case 500:
            responseHandlerService.handleError(new Error('Sunucu hatası!'))
            break
          default:
            break
        }
      }
      return Promise.reject(error)
    },
)

export default instance

