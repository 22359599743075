const env = process.env.VUE_APP_MODE
export const baseUrl = env === 'newProd' ? 'https://api.lobsterlead.com'
    : env === 'development'
        ? 'http://localhost:3000'
        : env === 'production'
            ? 'https://api.lobsterlead.com'
            : env === 'test' || !env
                ? 'https://api.lobster.softwareoptimize.com'
                : 'Invalid VUE_APP_MODE'


export const auth = {
  signIn: `${baseUrl}/users/signin`,
  logOut: `${baseUrl}/users/signout`,
  forgotPassword: baseUrl + '/users/forgot-password',
  resetPassword: baseUrl + '/users/reset-password',
  validateResetToken: `${baseUrl}/users/validate-reset-token`,
}
export const users = {
  getAll: `${baseUrl}/users`,
  add: `${baseUrl}/users`,
  edit: `${baseUrl}/users`,
}
export const assets = {
  getAll: `${baseUrl}/assets`,
  get: `${baseUrl}/assets/one`,
  categories: `${baseUrl}/assets/source-types`,
  languages: `${baseUrl}/assets/languages`,
  info: `${baseUrl}/assets/info`,
  add: `${baseUrl}/assets`,
  edit: `${baseUrl}/assets`,
  customerTokenController: `${baseUrl}/assets/token-controller`,
  searchEngineLocation: `${baseUrl}/assets/search-engine-location`,
  startCrawler: `${baseUrl}/assets/add-crawler`,
  checkSocialAuth: `${baseUrl}/assets/platform-auth-status`,
}

export const dashboard = {
  getSeoScores: `${baseUrl}/dashboard/seo-scores`,
  getKeywordAnalysis: `${baseUrl}/dashboard/keyword-analysis`,
  getTrendKeywords: `${baseUrl}/dashboard/trend-keyword`,
  getMonthlyKeywordPositions: `${baseUrl}/dashboard/monthly-keyword-positions`,
  getMonthlyKeywordVolumes: `${baseUrl}/dashboard/monthly-keyword-volume`,
  getKeywordEntity: `${baseUrl}/dashboard/with-keyword`,
  saveContentWithArticle: `${baseUrl}/dashboard/save-content-article`,
  competitorAnalysis: `${baseUrl}/dashboard/competitor-analysis`,
  getAnalyticsData: `${baseUrl}/dashboard/get-analytics-data`,
}

export const entity = {
  getAll: `${baseUrl}/entities`,
  add: `${baseUrl}/entities`,
  createContent: `${baseUrl}/entities/content-and-image`,
  createVideo: `${baseUrl}/dashboard/create-video`,
  createTrendContent: `${baseUrl}/dashboard/create-content-keywords`,
  getDetail: `${baseUrl}/entities/detail`,
  assetContentEdit: `${baseUrl}/entities`,
  saveEditedContent: `${baseUrl}/entities/save-edited-content`,
  revise: `${baseUrl}/entities/revise`,
  contentHistory: `${baseUrl}/entities/content-history`,
}

export const socialMedia = {
  socialAuth: `${baseUrl}/social-media/authorize`,
  socialOauth: `${baseUrl}/social-media/auth/callback`,
  checkSocialAuth: `${baseUrl}/social-media/check-auth`,
  disconnect: `${baseUrl}/social-media/auth/disconnect`,
  getPosts: `${baseUrl}/social-media/posts`,
  getPost: `${baseUrl}/social-media/post`,
  postGenerate: `${baseUrl}/social-media/post/generate`,
  savePost: `${baseUrl}/social-media/post/save`,
  publishPost: `${baseUrl}/social-media/post/publish`,
  commentsGetAll: `${baseUrl}/social-media/comments`,
  getProperty: `${baseUrl}/social-media/get-property`,
}

export const settings = {
  generalSettings: `${baseUrl}/settings/general`,
  scheduleSettings: `${baseUrl}/settings/scheduling`,
  assetConfiguration: `${baseUrl}/settings/asset-configuration`,
  sourceConfiguration: `${baseUrl}/settings/source-configuration`,
  categories: `${baseUrl}/assets/source-types`,
  getPolicy: `${baseUrl}/settings/get-policy`,
  uploadDocumentFiles: `${baseUrl}/settings/upload-document-files`,
  getDocumentFiles: `${baseUrl}/settings/get-document-files`,
  deleteDocumentFiles: `${baseUrl}/settings/delete-document-files`,
  autoOptimize: `${baseUrl}/entities/auto-optimize-contents`,
  aiIframe: `${baseUrl}/settings/assistant`,
  getAnalyticProperties: `${baseUrl}/settings/manage-integrations`,
  saveManageIntegration: `${baseUrl}/settings/save-manage-integrations`,
  exportKeywords: `${baseUrl}/settings/export-keywords`,
}

export const scheduleAssistant = {
  getContents: `${baseUrl}/scheduling`,
  getContent: `${baseUrl}/scheduling/content-detail`,
  refreshImage: `${baseUrl}/scheduling/refresh/image`,
  refreshContent: `${baseUrl}/scheduling/refresh-content`,
  confirmContent: `${baseUrl}/scheduling`,
  rejectContent: `${baseUrl}/scheduling`,
  toggleCreateContent: `${baseUrl}/scheduling/toggle-create-content`,
}
export const memories = {
  getAll: `${baseUrl}/memories`,
  delete: `${baseUrl}/memories`,
}
