<template>
  <div class="flex flex-col h-full">
    <div class="flex border-b mb-2">
      <button v-for="tabItem in tabs" :key="tabItem.value" @click="tab = tabItem.value" class="w-full py-3 px-4 text-sm focus:outline-none transition-colors duration-200" :class="tabItem.classes[tab === tabItem.value ? 'active' : 'inactive']">
        {{ tabItem.label }}
      </button>
    </div>

    <div class="overflow-y-auto flex-grow thin-scrollbar">
      <div v-if="currentItems.length > 0" v-for="item in currentItems" :key="item.id" class="border-b border-gray-200 py-3 flex items-start justify-between px-4 hover:bg-gray-50">
        <div class="flex-1 pr-4">
          <h3 class="font-semibold text-sm mb-1">{{ item.title }}</h3>
          <p class="text-xs text-gray-600">{{ item.description }}</p>
        </div>
        <p :class="[
          'text-lg font-semibold',
          tab === 'strengths' ? 'text-green-500' : 'text-red-500'
        ]">
          {{ scoreToPercentage(item.score) }} </p>
      </div>
      <div v-else class="w-full py-4">
        <p class="text-center text-sm text-gray-500">
          {{ noItemsMessage }} </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  detail: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()

const tab = ref('strengths')

const tabs = [
  {
    value: 'strengths',
    label: t('dashboard.components.pageInsightInfo.components.infoModal.components.categoryDetail.strengths'),
    classes: {
      active: 'border-b-2 border-green-500 text-green-600 font-medium',
      inactive: 'text-gray-500 hover:text-green-500',
    },
  },
  {
    value: 'deficiencies',
    label: t('dashboard.components.pageInsightInfo.components.infoModal.components.categoryDetail.deficiencies'),
    classes: {
      active: 'border-b-2 border-red-500 text-red-600 font-medium',
      inactive: 'text-gray-500 hover:text-red-500',
    },
  },
]

const currentItems = computed(() => props.detail[tab.value] || [])

const noItemsMessage = computed(() =>
    tab.value === 'strengths'
        ? t('dashboard.components.pageInsightInfo.components.infoModal.components.categoryDetail.noStrengths')
        : t('dashboard.components.pageInsightInfo.components.infoModal.components.categoryDetail.noDeficiencies'),
)

const scoreToPercentage = (score) => `${Math.round(score)}%`
</script>
