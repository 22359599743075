<template>
  <div class="flex flex-col items-center h-96" :class="[imageClass, 'w-full']">
    <div class="h-full w-full bg-gray-200 rounded-md relative">
      <div v-if="imageUrls && imageUrls.length > 1 && !contentLoading && validImage" class="absolute top-1/2 left-2 transform -translate-y-1/2 bg-secondary/65 text-white border-none cursor-pointer z-10 flex items-center justify-center w-9 h-9 rounded-full" @click="prevImage">
        <span class="material-symbols-outlined">chevron_left</span>
      </div>
      <div class="h-full w-full flex flex-col items-center justify-center" :class="(imageUrls && imageUrls.length) || !contentLoading || !loading && validImage ? 'cursor-pointer' : 'cursor-default'">
        <span v-if="contentLoading || loading" class="material-symbols-outlined animate-spin absolute">
          progress_activity
        </span>
        <div v-else-if="imageUrls && imageUrls.length && validImage" class="h-full w-full absolute" v-viewer>
          <img v-for="(item, index) in imageUrls" :src="getImageSrc(item)" :key="getImageKey(item, index)" :alt="t('globalComponents.imageComponent.contentImageAltText')" v-motion-fade v-show="index === currentIndex" class="h-full w-full object-cover rounded-md image" @mouseover="isHovered = true" @mouseout="isHovered = false" :class="{ 'hover-effect': isHovered }" @error="handleImageError"/>
        </div>
        <div v-else class="h-full w-full flex items-center justify-center text-gray-500 text-sm">
         <span>{{ t('globalComponents.imageComponent.noImageFound') }}</span>
        </div>
      </div>
      <div v-if="imageUrls && imageUrls.length > 1 && !contentLoading && validImage" class="absolute top-1/2 right-2 transform -translate-y-1/2 bg-secondary/65 text-white border-none cursor-pointer z-10 flex items-center justify-center w-9 h-9 rounded-full" @click="nextImage">
        <span class="material-symbols-outlined">chevron_right</span>
      </div>
      <div v-if="isImageUploadable" class="absolute bottom-2 right-2">
        <label for="imageUpload" class="cursor-pointer bg-white p-1 rounded-md shadow-md hover:bg-gray-100 transition-colors flex items-center">
          <span class="material-symbols-outlined text-gray-600 ml-1 !text-[18px]">add_a_photo</span> </label>
        <input id="imageUpload" type="file" accept="image/*" class="hidden" @change="uploadImage"/>
      </div>
    </div>
    <div class="flex justify-center w-full mt-2">
      <button v-if="!isImageUrlsEmpty && !contentLoading && isImageRefreshable && validImage" class="p-2 text-primary text-sm font-semibold bg-white rounded-md transition-colors" @click="refreshImage">
        {{ t('globalComponents.imageComponent.refreshImageButton') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  imageUrls: {
    type: [Array, String],
    required: false,
    default: () => [],
  },
  imageClass: {
    type: String,
    default: '',
  },
  contentLoading: {
    type: Boolean,
    default: false,
  },
  isImageRefreshable: {
    type: Boolean,
    default: false,
  },
  isImageUploadable: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['refreshImage', 'uploadImage'])

const { t } = useI18n()

const isHovered = ref(false)
const currentIndex = ref(0)
const loading = ref(false)
const validImage = ref(true)

const isImageUrlsEmpty = computed(() => {
  return !props.imageUrls || props.imageUrls.length === 0
})

function refreshImage() {
  emit('refreshImage', props.imageUrls[currentIndex.value], currentIndex.value)
}

function nextImage() {
  if (currentIndex.value < props.imageUrls.length - 1) {
    currentIndex.value++
  } else {
    currentIndex.value = 0
  }
}

function prevImage() {
  if (currentIndex.value > 0) {
    currentIndex.value--
  } else {
    currentIndex.value = props.imageUrls.length - 1
  }
}

function uploadImage(event) {
  const file = event.target.files[0]
  if (file) {
    emit('uploadImage', file)
  }
}

function triggerImageUpload() {
  document.getElementById('imageUpload').click()
}

function preloadImages() {
  const imageUrls = handleImageUrls()

  if (!imageUrls) return

  imageUrls.forEach((imageUrl) => {
    const img = new Image()
    img.src = typeof imageUrl === 'object' ? imageUrl.image || imageUrl.url : imageUrl
  })
}

function validateImageUrls() {
  const imageUrls = handleImageUrls()

  if (!imageUrls) return

  validImage.value = imageUrls.every((imageUrl) => {
    if (typeof imageUrl === 'object') {
      return (imageUrl.image && imageUrl.image.trim() !== '') || (imageUrl.url && imageUrl.url.trim() !== '')
    }
    return imageUrl && imageUrl.trim() !== ''
  })
}

function handleImageUrls() {
  let imageUrls = props.imageUrls

  if (props.imageUrls === null) {
    imageUrls = []
  } else if (typeof props.imageUrls === 'string') {
    try {
      imageUrls = JSON.parse(props.imageUrls)
    } catch (error) {
      console.error('Failed to parse imageUrls:', error)
      imageUrls = []
    }
  }

  return Array.isArray(imageUrls) ? imageUrls : []
}

function handleImageError() {
  validImage.value = false
}

function getImageSrc(item) {
  return typeof item === 'object' ? item.image || item.url : item
}

function getImageKey(item, index) {
  return typeof item === 'object' ? item.id : index
}

watch(
    () => props.imageUrls,
    () => {
      loading.value = true
      validImage.value = true
      preloadImages()
      validateImageUrls()
      nextTick(() => {
        setTimeout(() => {
          loading.value = false
        }, 1000)
      })
    },
    { deep: true },
)

validateImageUrls()
</script>

<style scoped>
.hover-effect {
  filter: blur(1px);
}
</style>