<template>
  <router-link
      :to="path"
      class="flex items-center space-x-2 px-2 py-1 rounded-md text-xs font-medium transition-colors duration-200"
      :class="routeClass"
      @click="$emit('clicked')"
  >
    <span class="material-symbols-outlined !text-[18px]" :class="iconClass">
      {{ icon }}
    </span>
    <span :class="labelClass">{{ label }}</span>
  </router-link>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  path: String,
  paths: Array,
  icon: String,
  iconClass: String,
  labelClass: String,
  label: String,
  activeClass: String,
  customClass: String,
})

const route = useRoute()

const isActive = computed(() =>
    props.paths.some((path) =>
        route.matched.some((m) => m.path.includes(path)),
    ),
)

const routeClass = computed(() => ({
  [props.customClass]: props.customClass,
  [props.activeClass]: isActive.value,
  'bg-gray-700 text-white': !props.activeClass && isActive.value,
  'text-gray-500 ': !isActive.value,
}))
</script>