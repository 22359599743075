<template>
  <div class="flex items-center space-x-2">
    <slot name="label-left"></slot>
    <button type="button" :class="[
        'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
        switchColorClasses,
        disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'
      ]" :disabled="disabled" @click="toggle" :aria-pressed="modelValue">
      <span class="inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out" :class="modelValue ? 'translate-x-5' : 'translate-x-0'"/>
    </button>
    <slot name="label-right"></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  color: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary', 'accent', 'header', 'indigo', 'neutral'].includes(value),
  },
})

const emit = defineEmits(['update:modelValue', 'change'])

const colorVariations = {
  primary: {
    active: 'bg-primary focus:ring-primary/50',
    inactive: 'bg-gray-200',
  },
  secondary: {
    active: 'bg-secondary focus:ring-secondary/50',
    inactive: 'bg-gray-200',
  },
  accent: {
    active: 'bg-accent focus:ring-accent/50',
    inactive: 'bg-gray-200',
  },
  header: {
    active: 'bg-white focus:ring-white/50',
    inactive: 'bg-gray-200',
  },
  indigo: {
    active: 'bg-indigo focus:ring-indigo/50',
    inactive: 'bg-gray-200',
  },
  neutral: {
    active: 'bg-neutral focus:ring-neutral/50',
    inactive: 'bg-gray-200',
  },
}

const switchColorClasses = computed(() => {
  const colorVariation = colorVariations[props.color]
  return props.modelValue ? colorVariation.active : colorVariation.inactive
})

const toggle = () => {
  if (!props.disabled) {
    emit('update:modelValue', !props.modelValue)
    emit('change', !props.modelValue)
  }
}
</script>