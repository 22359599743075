<template>
  <div class="component-layout">
    <div class="calendar-container p-4 bg-white rounded-md shadow-md">
      <CalendarHeader :currentMonthYear="currentMonthYear" :currentDate="currentDate" :markedDates="markedDates" :dateFormatter="dateFormatter" @change-week="changeWeek" @set-today="setToday" @update:modelValue="handleDatePickerChange"/>

      <div class="calendar-body relative">
        <CalendarGrid :weekDays="weekDays" :visibleHours="visibleHours" :getEventsForDayAndHour="getEventsForDayAndHour" :formatHour="formatHour" :getEventClass="getEventClass" :getStateClass="getStateClass" :getPlatformIcon="getPlatformIcon" :getStateName="getStateName" @open-content-modal="openContentModal" @open-more-events-modal="openMoreEventsModal"/>
        <LoadingOverlay v-if="loading"/>
      </div>

      <ContentSideModal ref="contentModal" @confirmed="handleConfirmed" @rejected="handleRejected" @refreshed="handleRefreshed"/>

      <MoreEventsModal v-model="isMoreEventsModalOpen" :events="moreEvents" @open-content-modal="openContentModal"/>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import isoWeek from 'dayjs/plugin/isoWeek'
import axios from '@/plugins/axiosInstance'
import { scheduleAssistant } from '@/networking/urlManager'
import { usePlatform, useState, useEventUtils } from './composables'
import CalendarHeader from './components/calendarHeader.vue'
import CalendarGrid from './components/calendarGrid.vue'
import LoadingOverlay from './components/loadingOverlay.vue'
import ContentSideModal from './components/contentSideModal.vue'
import MoreEventsModal from './components/moreEventsModal.vue'

[utc, timezone, weekOfYear, localizedFormat, isoWeek].forEach(dayjs.extend)

const { t, tm } = useI18n()
const store = useStore()

// Composables
const { Platform, getPlatformIcon, getPlatformName } = usePlatform()
const { State, getStateClass, getStateName } = useState()
const { formatEventTime, getEventClass } = useEventUtils(Platform, State)

// Refs
const contentModal = ref(null)
const currentDate = ref(dayjs())
const loading = ref(false)
const events = ref([])
const moreEvents = ref([])
const isMoreEventsModalOpen = ref(false)
const userTimezone = ref(dayjs.tz.guess())

// Computed properties
const is12HourFormat = computed(() => ['US', 'CA', 'AU', 'NZ', 'PH', 'PK', 'IN', 'EG', 'SA', 'CO', 'VE', 'MY'].includes(userTimezone.value.split('/')[0]))
const today = computed(() => dayjs().tz(userTimezone.value).format('YYYY-MM-DD'))
const weekDays = computed(() => calculateWeekDays())
const currentMonthYear = computed(() => calculateCurrentMonthYear())
const visibleHours = computed(() => Array.from({ length: 12 }, (_, i) => i + 8))
const eventMap = computed(() => createEventMap())
const markedDates = computed(() => events.value.map(event => dayjs(event.date).format('YYYY-MM-DD')))

// Methods
const calculateWeekDays = () => {
  const startOfWeek = currentDate.value.startOf('isoWeek')
  const dayNames = tm('general.dayNames')
  return Array.from({ length: 7 }, (_, i) => {
    const dayObject = startOfWeek.add(i, 'day')
    return {
      date: dayObject.format('YYYY-MM-DD'),
      dayOfMonth: dayObject.date(),
      dayName: dayNames[(i + 1).toString()],
      month: dayObject.month() + 1,
      year: dayObject.year(),
      isToday: dayObject.format('YYYY-MM-DD') === today.value,
    }
  })
}

const calculateCurrentMonthYear = () => {
  const [firstDay, lastDay] = [weekDays.value[0], weekDays.value[6]]
  const monthNames = tm('general.monthNames')
  return firstDay.month === lastDay.month
      ? `${monthNames[firstDay.month - 1]} ${firstDay.year}`
      : `${monthNames[firstDay.month - 1]} - ${monthNames[lastDay.month - 1]} ${lastDay.year}`
}

const createEventMap = () => {
  const map = new Map()
  events.value.forEach(event => {
    const eventDate = dayjs(event.date).tz(userTimezone.value)
    const key = `${eventDate.format('YYYY-MM-DD')}-${eventDate.hour()}`
    if (!map.has(key)) map.set(key, [])
    map.get(key).push(event)
  })
  return map
}

const dateFormatter = (date) => dayjs(date).format('YYYY-MM-DD')

const formatHour = (hour) => dayjs().hour(hour).minute(0).format(is12HourFormat.value ? 'h A' : 'HH:00')

const getEventsForDayAndHour = (date, hour) => {
  const key = `${date}-${hour}`
  return eventMap.value.get(key) || []
}

// API calls
const fetchEvents = async () => {
  loading.value = true
  try {
    const response = await axios.get(scheduleAssistant.getContents, {
      params: {
        assetId: store.state.assetId,
        year: currentDate.value.year(),
        month: currentDate.value.month() + 1,
      },
    })
    events.value = response.data.data
  } catch (error) {
    console.error('Error fetching events:', error)
  } finally {
    setTimeout(() => loading.value = false, 500)
  }
}

// const fetchEvents = async () => {
//   loading.value = true
//   try {
//     // API çağrısı simülasyonu
//     await new Promise(resolve => setTimeout(resolve, 1000))
//
//     // Örnek veriler
//     events.value = [
//       { id: 1, title: 'Instagram Post', date: '2024-09-24T09:00:00', platform: 1 },
//       { id: 2, title: 'Facebook Update', date: '2024-09-24T09:00:00', platform: 2 },
//       { id: 3, title: 'Tweet Campaign', date: '2024-09-24T09:00:00', platform: 3 },
//       { id: 4, title: 'LinkedIn Article', date: '2024-09-24T14:00:00', platform: 5 },
//       { id: 5, title: 'Blog Post', date: '2024-09-24T14:00:00', platform: 4 },
//       { id: 6, title: 'YouTube Video', date: '2024-09-24T16:00:00', platform: 6 },
//       { id: 7, title: 'Instagram Story', date: '2024-09-25T11:00:00', platform: 1 },
//       { id: 8, title: 'Facebook Live', date: '2024-09-25T15:00:00', platform: 2 },
//     ]
//   } catch (error) {
//     console.error('Error fetching events:', error)
//   } finally {
//     loading.value = false
//   }
// }

// Event handlers
const changeWeek = (direction) => {
  currentDate.value = currentDate.value.add(direction, 'week')
  fetchEvents()
}

const setToday = () => {
  currentDate.value = dayjs()
  fetchEvents()
}

const openContentModal = (rowId) => {
  isMoreEventsModalOpen.value = false
  contentModal.value.show(rowId)
}

const handleConfirmed = ({ id, result }) => {
  if (result) updateEventState(id, 3)
}

const handleRejected = ({ id, result }) => {
  if (result) updateEventState(id, 2)
}

const handleRefreshed = ({ content, result }) => {
  if (result) {
    const index = events.value.findIndex(event => event.id === content.id)
    if (index !== -1) {
      events.value[index] = { ...events.value[index], ...content }
    }
  }
}

const updateEventState = (id, newState) => {
  const event = events.value.find(event => event.id === id)
  if (event) {
    event.stateId = newState
  }
}

const handleDatePickerChange = (newDate) => {
  currentDate.value = dayjs(newDate)
  fetchEvents()
}

const openMoreEventsModal = (events) => {
  moreEvents.value = events
  isMoreEventsModalOpen.value = true
}

const handleKeyDown = (event) => {
  if (event.code === 'ArrowLeft') {
    changeWeek(-1)
  } else if (event.code === 'ArrowRight') {
    changeWeek(1)
  }
}

// Lifecycle hooks
onMounted(() => {
  fetchEvents()
  window.addEventListener('keydown', handleKeyDown)
})

onUnmounted(() => {
  window.removeEventListener('keydown', handleKeyDown)
})

watch(currentDate, () => {
  fetchEvents()
})
</script>

<style scoped>
.calendar-container {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}
</style>