<template>
  <div class="component-layout">
    <div class="bg-white p-4 rounded-md shadow-md">
      <CustomTable :data="trends" :columns="columns" :isLoading="isLoading" :filter-items="[]" min-height="300px" max-height="300px" min-width="900px">
        <template v-slot:table-actions>
          <h1 class="text-base font-semibold mb-2">
            {{ t('dashboard.components.trendKeywords.title') }} </h1>
        </template>

        <!-- Custom cell renderers -->
        <template v-slot:number="{ item }">
          {{ trends.indexOf(item) + 1 }}
        </template>

        <template v-slot:name="{ item }">
          <div class="flex items-center space-x-4 w-full text-xs">
            <p>{{ item.name }}</p>
            <Tooltip :title="item.description" :displayCondition="true">
              <template v-slot:content>
                <div class="w-full h-full flex items-center mb-1 cursor-pointer">
                  <span class="material-symbols-outlined !text-[14px] !font-bold !text-primary">
                    ?
                  </span>
                </div>
              </template>
            </Tooltip>
          </div>
        </template>

        <template v-slot:link="{ item }">
          <div class="flex items-center justify-center">
            <a :href="item.url" class="text-blue-500 text-xs cursor-pointer" target="_blank">
              {{ item.url ? t('dashboard.components.trendKeywords.openLink') : t('dashboard.components.trendKeywords.noLink') }} </a>
          </div>
        </template>

        <template v-slot:content="{ item }">
          <div class="flex items-center justify-center">
            <CustomButton :class="{ 'bg-gray-500': !item.url }" :disabled="!item.url" :button-text="t('dashboard.components.trendKeywords.createContent')" @click="handleNavigation(item)" size="sm"/>
          </div>
        </template>
      </CustomTable>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import CustomTable from '@/components/table/customTable.vue'
import Tooltip from '@/components/general/tooltip.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

const store = useStore()
const { t } = useI18n()
const router = useRouter()

const trends = ref([])
const isLoading = ref(true)

const columns = [
  { key: 'number', label: t('dashboard.components.trendKeywords.columns.number'), width: 'w-1/12' },
  { key: 'name', label: t('dashboard.components.trendKeywords.columns.keywords') },
  { key: 'link', label: t('dashboard.components.trendKeywords.columns.link'), align: 'center' },
  { key: 'content', label: t('dashboard.components.trendKeywords.columns.content'), align: 'center' },
]

const fetchTrends = async () => {
  isLoading.value = true
  try {
    const response = await axios.get(dashboard.getTrendKeywords, {
      params: {
        assetId: store.state.assetId,
      },
    })
    trends.value = response.data.data
  } catch (error) {
    console.error('Error fetching keyword trends:', error)
  } finally {
    isLoading.value = false
  }
}

const handleNavigation = (item) => {
  router.push({
    name: 'contentModerator',
    query: {
      tab: 'new-content',
      keyword: item.name,
      url: item.url,
    },
  })
}

// Define emits
const emit = defineEmits(['navigate'])

onMounted(() => {
  fetchTrends()
})
</script>