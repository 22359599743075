<template>
  <div class="flex h-screen">
    <info/>
    <ResetPasswordForm/>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import { computed, onMounted } from 'vue'
import Info from '../components/information.vue'
import ResetPasswordForm from './components/resetPasswordForm.vue'
import { useI18n } from 'vue-i18n'

const store = useStore()
const router = useRouter()
const route = useRoute()
const { t } = useI18n()

const token = computed(() => store.state.userData.token)

onMounted(() => {
  if (token.value) router.push('/assets')
})
</script>