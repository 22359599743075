<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'contents'" @change-tab="handleTabChange">
    <template #right-content></template>
  </tab-navigation>

  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" :key="currentTab" ref="tabRef" v-bind="$attrs"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TabNavigation from '@/components/navigation/tabNavigation.vue'
import contentList from './components/contentList/index.vue'
import newContent from './components/newContent/index.vue'
import videoContent from './components/newVideoContent/index.vue'

const router = useRouter()
const route = useRoute()

const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'contents')
const showModal = ref(false)

const tabs = [
  { name: 'contents', label: 'İçerikler', component: markRaw(contentList) },
  { name: 'new-content', label: 'Yeni İçerik', component: markRaw(newContent) },
  { name: 'new-video-content', label: 'Video İçerik', component: markRaw(videoContent) },
]

const currentTabComponent = computed(() => {
  const tab = tabs.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}


watch(() => route.query.tab, (newTab) => {
  currentTab.value = newTab || 'contents'
})

</script>