<template>
  <VueFinalModal v-model="modalVisible" class="z-[999999999999999]" :click-to-close="clickToClose" :esc-to-close="escToClose" @opened="opened" :class="modalClass" :content-class="computedContentClass" contentTransition="vfm-slide-down">
    <div class="flex justify-between items-center">
      <div class="w-full">
        <h2 v-if="modalTitle" :class="[titleAlignmentClass, titleClass]">{{ modalTitle }}</h2>
      </div>
      <button @click="closeModal" class="!text-[18px] flex items-center">
        <span class="material-symbols-outlined">
          close
        </span>
      </button>
    </div>
    <div>
      <slot></slot>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'
import { toRefs, computed } from 'vue'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  clickToClose: {
    type: Boolean,
    default: true,
  },
  escToClose: {
    type: Boolean,
    default: true,
  },
  modalClass: {
    type: String,
    default: 'flex justify-center items-center ',
  },
  contentClass: {
    type: String,
    default: 'flex flex-col p-6 bg-white border rounded-md space-y-2 shadow-md relative',
  },
  width: {
    type: String,
    default: 'w-[500px] max-w-full',
  },
  modalTitle: {
    type: String,
    default: '',
  },
  titleAlignment: {
    type: String,
    default: 'left',
  },
  titleClass: {
    type: String,
    default: 'text-sm font-semibold text-secondary',
  },
})

const { modelValue } = toRefs(props)
const emit = defineEmits(['update:modelValue', 'opened'])

const modalVisible = computed({
  get() {
    return modelValue.value
  },
  set(value) {
    emit('update:modelValue', value)
  },
})

const titleAlignmentClass = computed(() => {
  return {
    'text-left': props.titleAlignment === 'left',
    'text-center': props.titleAlignment === 'center',
    'text-right': props.titleAlignment === 'right',
  }
})

const computedContentClass = computed(() => {
  return `${props.contentClass} ${props.width}`
})

const opened = () => {
  emit('opened')
}

const closeModal = () => {
  modalVisible.value = false
}
</script>