<template>
  <VueFinalModal v-model="showModal" class="flex justify-center items-center" content-class="relative bg-white rounded-lg w-[400px] max-w-full mx-4 p-4 border" :click-to-close="false" :esc-to-close="true">
    <div class="space-y-4">
      <!-- Modal Header -->
      <div class="flex justify-between items-center pb-2 border-b">
        <h3 class="text-lg font-semibold text-gray-900">
          {{ t('settings.components.configuration.components.keywordsTable.editKeyword') }} </h3>
        <button @click="closeModal" class="text-gray-400 hover:text-gray-500">
          <span class="material-symbols-outlined">close</span>
        </button>
      </div>

      <!-- Modal Body -->
      <div class="space-y-4">
        <div>
          <label class="block text-sm font-medium mb-1"> {{ t('settings.components.configuration.components.keywordsTable.keyword') }} </label>
          <CustomInput v-model="editedKeyword.name" size="sm"/>
        </div>
        <div>
          <label class="block text-sm font-medium mb-1"> {{ t('settings.components.configuration.components.keywordsTable.priority') }} </label>
          <CustomSelect v-model="editedKeyword.priority" :options="priorityOptions" size="sm"/>
        </div>
      </div>

      <!-- Modal Footer -->
      <div class="flex justify-end space-x-3 pt-4">
        <CustomButton type="button" :button-text="t('common.cancel')" @click="closeModal" color="secondary" variant="outline" size="sm"/>
        <CustomButton type="submit" :button-text="t('general.save')" @click="handleSave" :disabled="!isValid" color="primary" size="sm"/>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { VueFinalModal } from 'vue-final-modal'
import CustomButton from '@/components/buttons/customButton.vue'
import CustomInput from '@/components/inputs/customInput.vue'
import CustomSelect from '@/components/inputs/customSelect.vue'

const { t } = useI18n()

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  keyword: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits(['update:modelValue', 'save'])

const showModal = computed({
  get: () => props.modelValue,
  set: (value) => emit('update:modelValue', value),
})

const editedKeyword = ref({
  id: null,
  keywordId: null,
  name: '',
  priority: null,
  state: null,
})

const priorityOptions = computed(() =>
    Array.from({ length: 10 }, (_, i) => ({ value: i + 1, label: (i + 1).toString() })),
)

const isValid = computed(() =>
    editedKeyword.value.name.trim() !== '' &&
    editedKeyword.value.priority !== null &&
    (
        editedKeyword.value.name !== props.keyword.name ||
        editedKeyword.value.priority !== props.keyword.priority
    ),
)

const handleSave = () => {
  if (!isValid.value) return

  emit('save', {
    id: editedKeyword.value.id,
    keywordId: editedKeyword.value.keywordId,
    name: editedKeyword.value.name.trim(),
    priority: editedKeyword.value.priority,
    state: editedKeyword.value.state,
  })
  closeModal()
}

const closeModal = () => {
  showModal.value = false
  editedKeyword.value = {
    id: null,
    keywordId: null,
    name: '',
    priority: null,
    state: null,
  }
}

watch(() => props.keyword, (newKeyword) => {
  if (newKeyword) {
    editedKeyword.value = {
      id: newKeyword.id,
      keywordId: newKeyword.keywordId,
      name: newKeyword.name,
      priority: newKeyword.priority,
      state: newKeyword.state,
    }
  }
}, { immediate: true })

watch(() => showModal.value, (newValue) => {
  if (!newValue) {
    closeModal()
  }
})
</script>