<template>
  <div class="custom-select relative" ref="selectRef">
    <button @click="toggleDropdown" :class="[
        'flex items-center justify-between border bg-white border-gray-300 rounded-md focus:outline-none focus:border-gray-500 focus:ring-1 focus:ring-gray-500 w-full',
        sizeClasses
      ]">
      <span class="truncate">{{ selectedOption ? selectedOption.label : placeholder }}</span> <span :class="['material-symbols-outlined text-gray-400', iconSizeClass]">expand_more</span>
    </button>
    <div v-if="isOpen" class="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-y-auto thin-scrollbar">
      <ul>
        <li v-for="option in options" :key="option.value" @click="selectOption(option)" :class="[
            'hover:bg-gray-100 cursor-pointer truncate',
            sizeClasses
          ]">
          {{ option.label }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  modelValue: [String, Number],
  options: Array,
  placeholder: String,
  size: {
    type: String,
    default: 'sm',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
})

const emit = defineEmits(['update:modelValue'])

const isOpen = ref(false)
const selectRef = ref(null)

const selectedOption = computed(() =>
    props.options.find(option => option.value === props.modelValue),
)

const sizeClasses = computed(() => {
  switch (props.size) {
    case 'sm':
      return 'text-xs py-1.5 px-2'
    case 'md':
      return 'text-sm py-2 px-3'
    case 'lg':
      return 'text-base py-2.5 px-3'
    default:
      return 'text-sm py-2 px-3'
  }
})

const iconSizeClass = computed(() => {
  switch (props.size) {
    case 'sm':
      return '!text-[16px]'
    case 'md':
      return '!text-[18px]'
    case 'lg':
      return '!text-[20px]'
    default:
      return '!text-[18px]'
  }
})

const toggleDropdown = () => {
  isOpen.value = !isOpen.value
}

const selectOption = (option) => {
  emit('update:modelValue', option.value)
  isOpen.value = false
}

const handleClickOutside = (event) => {
  if (selectRef.value && !selectRef.value.contains(event.target)) {
    isOpen.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>