<template>
  <div class="calendar-header flex flex-col sm:flex-row justify-between items-center mb-4 space-y-4 sm:space-y-0">
    <div class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4 w-full sm:w-auto">
      <h2 class="text-xl sm:text-2xl space-x-2 text-center sm:text-left">
        <span class="font-semibold">{{ currentMonthYear.split(' ')[0] }}</span> <span class="font-light">{{ currentMonthYear.split(' ').slice(1).join(' ') }}</span>
      </h2>
      <DatePicker v-model="modelValue" :markedDates="markedDates" :dateFormatter="dateFormatter" @update:modelValue="handleDateChange">
        <template #trigger="{ toggleDatePicker }">
          <button @click="toggleDatePicker" class="text-secondary text-sm px-3 py-1 rounded border shadow-sm flex items-center justify-center w-full sm:w-auto">
            <span class="material-symbols-outlined align-middle mr-1 !text-[16px]">calendar_today</span> <span>Tarih Seç</span>
          </button>
        </template>
      </DatePicker>
    </div>
    <div class="flex items-center space-x-2 w-full sm:w-auto justify-center sm:justify-end">
      <button @click="$emit('change-week', -1)" class="nav-button">
        <span class="material-symbols-outlined">chevron_left</span>
      </button>
      <button @click="$emit('set-today')" class="today-button px-3 py-1 bg-secondary text-white rounded-md text-xs">
        Today
      </button>
      <button @click="$emit('change-week', 1)" class="nav-button">
        <span class="material-symbols-outlined">chevron_right</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import DatePicker from '@/components/inputs/datePicker.vue'

const props = defineProps({
  currentMonthYear: String,
  currentDate: Object,
  markedDates: Array,
  dateFormatter: Function,
})

const emit = defineEmits(['change-week', 'set-today', 'update:modelValue'])

const modelValue = computed({
  get: () => props.currentDate,
  set: (value) => emit('update:modelValue', value),
})

const handleDateChange = (newDate) => {
  emit('update:modelValue', newDate)
}
</script>

<style scoped>
.nav-button {
  @apply text-gray-400 hover:text-secondary p-1 flex items-center;
}
</style>