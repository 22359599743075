<template>
  <nav class="bg-white z-[900] fixed w-full shadow-md">
    <div class="mx-auto px-4 sm:px-6 lg:px-8">
      <div class="flex items-center justify-between h-16">
        <div class="flex items-center flex-1">
          <router-link :to="'/assets'" class="flex-shrink-0 cursor-pointer no-underline">
            <img src="../../../assets/logo/logoB.svg" alt="logo" class="h-8 w-auto sm:h-8"/>
          </router-link>
          <div v-if="!isAssetsRoute" class="hidden xl:block ml-4 flex-1">
            <div class="flex items-baseline space-x-4">
              <navbarLink v-for="link in localizedNavLinks" :key="link.path" v-bind="link"/>
            </div>
          </div>
        </div>
        <div class="items-center space-x-4" :class="!isAssetsRoute ? 'hidden xl:flex ' : 'flex'">
          <notifications v-if="!isAssetsRoute && !route.path.startsWith('/users')"/>
          <language-menu/>
          <profile :name="profileName"/>
        </div>
        <div v-if="!isAssetsRoute" class="xl:hidden flex items-center space-x-4">
          <notifications v-if="!isAssetsRoute && !route.path.startsWith('/users')"/>
          <language-menu/>
          <button @click="toggleMenu" type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" aria-controls="mobile-menu" aria-expanded="false">
            <span class="sr-only">Open main menu</span> <span v-show="!isOpen" class="material-symbols-outlined">menu</span> <span v-show="isOpen" class="material-symbols-outlined">close</span>
          </button>
        </div>
      </div>
    </div>

    <div v-if="!isAssetsRoute" :class="{'block': isOpen, 'hidden': !isOpen}" class="xl:hidden" id="mobile-menu">
      <div class="px-2 pt-2 pb-3 space-y-1">
        <navbarLink v-for="link in localizedNavLinks" :key="link.path" v-bind="link" class="block" @clicked="toggleMenu"/>
      </div>
      <div class="pt-4 pb-3 border-t border-gray-200 flex justify-end px-4">
        <profile :name="profileName" :mobile="true"/>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import NavbarLink from './components/navbarLink.vue'
import notifications from './components/notifications.vue'
import Profile from './components/profile.vue'
import LanguageMenu from './components/language.vue'

const store = useStore()
const route = useRoute()
const { t } = useI18n()

const isOpen = ref(false)

const toggleMenu = () => {
  isOpen.value = !isOpen.value
}

const profileName = computed(() => store.state.userData.fullname)

const isAssetsRoute = computed(() => {
  return ['/assets', '/', '/assets/create', '/users'].includes(route.path) || route.path.startsWith('/assets/edit')
})

const localizedNavLinks = computed(() => [
  {
    path: '/dashboard',
    paths: ['/dashboard'],
    icon: 'dashboard',
    label: t('partitions.navbar.dashboard'),
    customClass: 'hover:text-primary text-xs',
    activeClass: 'text-primary',
  },
  {
    path: '/content-moderator',
    paths: ['/content-moderator', '/new-content'],
    icon: 'group',
    label: t('partitions.navbar.contentModerator'),
    customClass: 'hover:text-primary text-xs ',
    activeClass: 'text-primary',
  },
  {
    path: '/content/scheduling/assistant',
    paths: ['/content/scheduling/assistant'],
    icon: 'calendar_month',
    label: t('partitions.navbar.planningAssistant'),
    customClass: 'hover:text-primary text-xs',
    activeClass: 'text-primary',
  },
  {
    path: '/social-media',
    paths: ['/social-media'],
    icon: 'share',
    label: t('partitions.navbar.socialMedia'),
    customClass: 'hover:text-primary text-xs ',
    activeClass: 'text-primary',
  },
  {
    path: '/settings',
    paths: ['/settings'],
    icon: 'settings',
    label: t('partitions.navbar.settings'),
    customClass: 'hover:text-primary text-xs ',
    activeClass: 'text-primary',
  },
])
</script>