<template>
  <VueFinalModal :modelValue="show" :click-to-close="true" :esc-to-close="true" @opened="opened" @update:modelValue="closeModal" class="flex justify-center items-center" content-class="flex flex-col max-w-xl p-10 bg-white border rounded-lg space-y-2 shadow-xl relative" contentTransition="vfm-slide-down">
    <form @submit.prevent="submitForm" class="h-full flex flex-col space-y-8 w-96">
      <h2 class="text-2xl font-bold text-secondary">
        {{ isEditing ? t('users.editUser') : t('users.addUser') }} </h2>
      <customInput :label="t('users.nameLabel')" containerClass="w-full" v-model="name"/>
      <customInput :label="t('users.emailLabel')" containerClass="w-full" v-model="email" :disabled="isEditing"/>
      <customInput :label="t('users.passwordLabel')" containerClass="w-full" v-model="password" type="password"/>
      <div class="flex-grow">
        <customButton :buttonText="t('users.saveButton')" :isLoading="isLoading" :isDisabled="false" :fullWidth="true"/>
      </div>
    </form>
  </VueFinalModal>
</template>

<script setup>
import { ref, watch } from 'vue'
import customInput from '@/components/inputs/customInput.vue'
import customButton from '@/components/buttons/customButton.vue'
import { VueFinalModal } from 'vue-final-modal'
import axios from '@/plugins/axiosInstance'
import { users } from '@/networking/urlManager'
import { responseHandlerService } from '@/plugins/responseHandlerService'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  isEditing: { type: Boolean, required: true },
  userData: { type: Object, required: false },
  show: { type: Boolean, required: true },
})

const emit = defineEmits(['update', 'opened'])

const { t } = useI18n()
const name = ref(props.isEditing && props.userData ? props.userData.name : '')
const email = ref(props.isEditing && props.userData ? props.userData.email : '')
const password = ref('')
const isLoading = ref(false)


const submitForm = async () => {
  if (!name.value || !email.value || !password.value) {
    responseHandlerService.handleError($t('users.fillAllFields'))
    return
  }

  const url = props.isEditing ? users.edit : users.add
  const method = props.isEditing ? 'put' : 'post'
  let data = {
    name: name.value,
    email: email.value,
    password: password.value,
  }

  if (props.isEditing) {
    data = {
      userId: props.userData.id,
      name: name.value,
      password: password.value,
    }
  }

  isLoading.value = true
  try {
    await axios[method](url, data)
    isLoading.value = false
    responseHandlerService.handleSuccess(props.isEditing ? t('users.updateSuccess') : t('users.addSuccess'))
    resetForm()
    emit('update', true)
    closeModal()
  } catch (err) {
    isLoading.value = false
    responseHandlerService.handleError(err, props.isEditing ? t('users.updateError') : t('users.addError'))
  }
}


const opened = () => {
  emit('opened')
}

const closeModal = () => {
  emit('update:show', false)
}

const resetForm = () => {
  name.value = ''
  email.value = ''
  password.value = ''
}

watch(
    () => props.userData,
    (val) => {
      if (val) {
        name.value = val.name
        email.value = val.email
      }
    },
    { deep: true },
)

watch(
    () => props.show,
    (val) => {
      if (!val) resetForm()
    },
    { immediate: true },
)

</script>