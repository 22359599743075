<template>
  <div class="space-y-6">
    <!-- URL ekleme alanı (değişmedi) -->
    <div class="bg-white p-4 rounded-lg shadow-sm border border-gray-200 hover:shadow-md transition duration-300">
      <div class="flex flex-col space-y-3">
        <div class="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-2">
          <div class="relative w-full">
            <CustomInput v-model="newUrl" :placeholder="t('settings.components.configuration.components.documentModal.urlPlaceholder')" type="text" container-class="w-full" icon="link" :show-icon="true"/>
          </div>
          <CustomButton :button-text="t('settings.components.configuration.components.documentModal.addUrl')" @click="addUrl" size="sm" variant="primary" :is-disabled="!isValidUrl(newUrl.trim())"/>
        </div>
        <p class="text-xs text-primary font-medium italic">
          {{ t('settings.components.configuration.components.documentModal.urlNote') }} </p>
      </div>
    </div>

    <!-- URL listesi -->
    <div v-if="modelValue && modelValue.length > 0">
      <div class="flex items-center mb-3">
        <div class="w-8 h-px bg-gray-300 mr-3"></div>
        <h3 class="text-sm font-medium text-gray-500">{{ t('settings.components.configuration.components.documentModal.addedUrls') }}</h3>
        <div class="flex-grow h-px bg-gray-300 ml-3"></div>
      </div>
      <div class="overflow-y-auto max-h-[30vh] thin-scrollbar">
        <TransitionGroup name="list" tag="ul" class="space-y-3">
          <li v-for="(item, index) in modelValue" :key="item.id" class="bg-white p-3 rounded-md shadow-sm transition duration-300 ease-in-out hover:shadow-md border border-gray-200">
            <div class="flex justify-between items-center mb-2">
              <a :href="item.url" target="_blank" rel="noopener noreferrer" class="text-secondary text-sm underline" :title="item.url">
                {{ truncateUrl(item.url) }} </a>
              <CustomButton @click="removeUrl(index)" size="sm" variant="danger" icon="delete" :isTextShowable="false" :show-icon="true"/>
            </div>
            <CustomInput :model-value="item.description" @update:model-value="updateDescription(index, $event)" :placeholder="t('settings.components.configuration.components.documentModal.descriptionPlaceholder')" type="text" size="sm"/>
          </li>
        </TransitionGroup>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { v4 as uuidv4 } from 'uuid'
import CustomButton from '@/components/buttons/customButton.vue'
import CustomInput from '@/components/inputs/customInput.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const { t } = useI18n()
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const newUrl = ref('')

const isValidUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}

const truncateUrl = (url, maxLength = 70) => {
  if (url.length <= maxLength) return url
  const half = Math.floor(maxLength / 2)
  return url.slice(0, half - 2) + '...' + url.slice(-half + 1)
}

const addUrl = () => {
  const trimmedUrl = newUrl.value.trim()
  if (isValidUrl(trimmedUrl)) {
    if (!props.modelValue.some(item => item.url === trimmedUrl)) {
      emit('update:modelValue', [{ id: uuidv4(), url: trimmedUrl, description: '' }, ...props.modelValue])
      newUrl.value = ''
    } else responseHandlerService.handleError(t('settings.components.configuration.components.documentModal.urlAlreadyExists'))
  } else responseHandlerService.handleError(t('settings.components.configuration.components.documentModal.invalidUrl'))
}

const removeUrl = (index) => {
  const updatedUrls = [...props.modelValue]
  updatedUrls.splice(index, 1)
  emit('update:modelValue', updatedUrls)
}

const updateDescription = (index, newDescription) => {
  const updatedUrls = [...props.modelValue]
  updatedUrls[index] = { ...updatedUrls[index], description: newDescription }
  emit('update:modelValue', updatedUrls)
}
</script>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>