<!-- ToastNotification.vue -->
<template>
  <div :class="positionClass">
    <TransitionGroup :name="transitionName">
      <div v-for="toast in visibleToasts" :key="toast.id" class="mb-4 w-96 rounded-lg shadow-lg overflow-hidden" :class="[toast.isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800']">
        <div class="p-4">
          <div class="flex items-start">
            <span :class="['material-symbols-outlined mr-3', getIconColor(toast.type)]">{{ getIcon(toast.type) }}</span>
            <div class="flex-grow">
              <p class="text-sm font-semibold">{{ getTitle(toast) }}</p>
              <p class="text-sm opacity-75">{{ toast.message }}</p>
            </div>
            <button @click="removeToast(toast.id)" class="ml-3 opacity-50 hover:opacity-75">
              <span class="material-symbols-outlined">close</span>
            </button>
          </div>
        </div>
        <div v-if="toast.showProgress" class="h-1 bg-gray-200 overflow-hidden">
          <div class="h-full transition-all duration-300 ease-linear progress-bar" :class="getProgressColor(toast.type)" :style="{ animationDuration: `${toast.timeout}ms` }"/>
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup>
import { ref, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  timeout: {
    type: Number,
    default: 5000,
  },
  showProgress: {
    type: Boolean,
    default: true,
  },
  isDarkMode: {
    type: Boolean,
    default: false,
  },
  maxToasts: {
    type: Number,
    default: 5,
  },
  newestOnTop: {
    type: Boolean,
    default: true,
  },
  filterBeforeCreate: {
    type: Function,
    default: (toast) => toast,
  },
})

const store = useStore()
const { t } = useI18n()
const toasts = ref([])

const snackbarPosition = computed(() => store.state.snackbarPosition)

const positionClass = computed(() => {
  const classes = ['fixed z-[9999999999999] flex flex-col']
  if (snackbarPosition.value.top) classes.push('top-4')
  if (snackbarPosition.value.bottom) classes.push('bottom-4')
  if (snackbarPosition.value.left) classes.push('left-4 items-start')
  if (snackbarPosition.value.right) classes.push('right-4 items-end')
  return classes
})

const transitionName = computed(() => {
  if (snackbarPosition.value.right) return 'toast-right'
  if (snackbarPosition.value.left) return 'toast-left'
  if (snackbarPosition.value.top) return 'toast-top'
  return 'toast-bottom'
})

const visibleToasts = computed(() => {
  let visibleToasts = [...toasts.value]
  if (props.newestOnTop) {
    visibleToasts.reverse()
  }
  return visibleToasts.slice(0, props.maxToasts)
})

const addToast = (type, message, options = {}) => {
  const id = Date.now()
  const config = {
    ...{
      timeout: props.timeout,
      showProgress: props.showProgress,
      isDarkMode: props.isDarkMode,
    },
    ...options,
  }
  const newToast = { id, type, message, ...config }

  const filteredToast = props.filterBeforeCreate(newToast, toasts.value)
  if (filteredToast) {
    if (props.newestOnTop) {
      toasts.value.unshift(filteredToast)
    } else {
      toasts.value.push(filteredToast)
    }
    setTimeout(() => removeToast(id), config.timeout)
  }

  // Remove excess toasts
  if (toasts.value.length > props.maxToasts) {
    const excessToasts = toasts.value.slice(props.maxToasts)
    excessToasts.forEach(toast => removeToast(toast.id))
  }
}

const removeToast = (id) => {
  toasts.value = toasts.value.filter(toast => toast.id !== id)
}

const getIcon = (type) => {
  switch (type) {
    case 'success':
      return 'check_circle'
    case 'info':
      return 'info'
    case 'warning':
      return 'warning'
    case 'error':
      return 'error'
    default:
      return 'notifications'
  }
}

const getIconColor = (type) => {
  switch (type) {
    case 'success':
      return 'text-green-500'
    case 'info':
      return 'text-blue-500'
    case 'warning':
      return 'text-yellow-500'
    case 'error':
      return 'text-red-500'
    default:
      return 'text-gray-500'
  }
}

const getProgressColor = (type) => {
  switch (type) {
    case 'success':
      return 'bg-green-500'
    case 'info':
      return 'bg-blue-500'
    case 'warning':
      return 'bg-yellow-500'
    case 'error':
      return 'bg-red-500'
    default:
      return 'bg-gray-500'
  }
}

const getTitle = (toast) => {
  if (toast.title) return toast.title
  switch (toast.type) {
    case 'success':
      return t('globalComponents.toast.success')
    case 'info':
      return t('globalComponents.toast.info')
    case 'warning':
      return t('globalComponents.toast.warning')
    case 'error':
      return t('globalComponents.toast.error')
    default:
      return t('globalComponents.toast.default')
  }
}

defineExpose({ addToast, removeToast })

watchEffect(() => {
  if (toasts.value.length > props.maxToasts) {
    toasts.value = toasts.value.slice(0, props.maxToasts)
  }
})
</script>

<style scoped>
.toast-right-enter-active,
.toast-right-leave-active,
.toast-left-enter-active,
.toast-left-leave-active,
.toast-top-enter-active,
.toast-top-leave-active,
.toast-bottom-enter-active,
.toast-bottom-leave-active {
  transition: all 0.3s ease;
}

.toast-right-enter-from,
.toast-right-leave-to {
  opacity: 0;
  transform: translateX(100%);
}

.toast-left-enter-from,
.toast-left-leave-to {
  opacity: 0;
  transform: translateX(-100%);
}

.toast-top-enter-from,
.toast-top-leave-to {
  opacity: 0;
  transform: translateY(-100%);
}

.toast-bottom-enter-from,
.toast-bottom-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

@keyframes progress {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.progress-bar {
  animation: progress linear;
  animation-fill-mode: forwards;
}
</style>