<template>
  <div class="relative w-full" ref="componentRef">
    <label v-if="label" :class="['block font-semibold text-sm mb-1', labelClass]"> {{ label }}
      <span v-if="required" class="text-red-500">*</span> </label>
    <p v-if="description" :class="['mb-2 text-xs text-gray-500', descriptionClass]">
      {{ description }} </p>

    <CustomDropdown :options="languageOptions" v-model="selectedLanguageId" :label="placeholder" :show-search="true" :loading="loading" @update:modelValue="handleLanguageChange" @buttonClick.prevent/>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from 'vue'
import axios from '@/plugins/axiosInstance'
import { assets } from '@/networking/urlManager'
import CustomDropdown from '@/components/inputs/customDropdown.vue'

const props = defineProps({
  modelValue: [String, Number],
  label: { type: String, default: '' },
  description: { type: String, default: '' },
  required: { type: Boolean, default: false },
  labelClass: { type: String, default: 'text-secondary' },
  descriptionClass: { type: String, default: '' },
  placeholder: { type: String, default: '' },
})

const emit = defineEmits(['update:modelValue'])

const componentRef = ref(null)
const languages = ref([])
const loading = ref(true)
const selectedLanguageId = ref(props.modelValue)

const languageOptions = computed(() => {
  return languages.value.map(lang => ({
    value: lang.id,
    label: lang.name,
  }))
})

const handleLanguageChange = (languageId) => {
  emit('update:modelValue', languageId)
}

const fetchLanguages = async () => {
  try {
    loading.value = true
    const response = await axios.get(assets.languages)
    languages.value = response.data.data
    loading.value = false
  } catch (error) {
    console.error('Error fetching languages:', error)
    loading.value = false
  }
}

onMounted(() => {
  fetchLanguages()
})

watch(() => props.modelValue, (newValue) => {
  selectedLanguageId.value = newValue
})
</script>