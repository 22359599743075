<template>
  <div class="relative" ref="dropdown">
    <button class="p-1.5 rounded-full text-gray-600 hover:bg-gray-100 focus:outline-none focus:text-primary relative flex items-center" id="notifications-menu" aria-label="Notifications" @click="toggleDropdown">
      <span class="material-symbols-outlined notification-icon">notifications</span>
      <span v-if="unreadNotifications.length > 0" class="absolute top-0 right-0 inline-flex items-center justify-center px-1 py-0.5 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full min-w-[16px] h-[16px]">
        {{ unreadNotifications.length }}
      </span>
    </button>

    <transition enter-active-class="transition ease-out duration-200" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
      <div v-if="dropdownOpen" ref="floatingEl" :style="{
        position: strategy,
        top: `${y ?? 0}px`,
        left: `${x ?? 0}px`,
        width: '280px',
      }" class="bg-white rounded-md shadow-lg overflow-hidden z-50 text-sm" role="menu" aria-orientation="vertical" aria-labelledby="notifications-menu">
        <div class="px-3 py-1.5 text-base font-medium text-gray-700 border-b border-gray-200">
          {{ t('partitions.navbar.components.notifications.title') }}
        </div>
        <div class="flex border-b border-gray-200">
          <button @click="currentTab = 'new'" :class="['flex-1 py-1.5 text-xs font-medium', currentTab === 'new' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500']">
            {{ t('partitions.navbar.components.notifications.new') }}
          </button>
          <button @click="currentTab = 'all'" :class="['flex-1 py-1.5 text-xs font-medium', currentTab === 'all' ? 'text-blue-600 border-b-2 border-blue-600' : 'text-gray-500']">
            {{ t('partitions.navbar.components.notifications.all') }}
          </button>
        </div>
        <div class="max-h-56 overflow-y-auto">
          <template v-if="currentTab === 'new'">
            <div v-if="unreadNotifications.length === 0" class="px-3 py-2 text-xs text-gray-500">
              {{ t('partitions.navbar.components.notifications.noNewNotifications') }}
            </div>
            <div v-else v-for="(notification, index) in unreadNotifications" :key="index" class="px-3 py-1.5 hover:bg-gray-100 flex justify-between items-center">
              <div>
                <p class="text-xs font-medium text-gray-900">{{ notification.message }}</p>
                <p class="text-xs text-gray-500">{{ notification.time }}</p>
              </div>
              <button @click="markAsRead(notification)" class="text-xs text-blue-600 hover:text-blue-800">
                {{ t('partitions.navbar.components.notifications.markAsRead') }}
              </button>
            </div>
          </template>
          <template v-else>
            <div v-if="allNotifications.length === 0" class="px-3 py-2 text-xs text-gray-500">
              {{ t('partitions.navbar.components.notifications.noNotifications') }}
            </div>
            <div v-else v-for="(notification, index) in allNotifications" :key="index" class="px-3 py-1.5 hover:bg-gray-100">
              <p class="text-xs font-medium text-gray-900">{{ notification.message }}</p>
              <p class="text-xs text-gray-500">{{ notification.time }}</p>
            </div>
          </template>
        </div>
        <div v-if="allNotifications.length > 0" class="px-3 py-1.5 bg-gray-50 text-right">
          <button @click="markAllAsRead" class="text-xs text-blue-600 hover:text-blue-800">
            {{ t('partitions.navbar.components.notifications.markAllAsRead') }}
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, nextTick } from 'vue'
import { useFloating, autoUpdate, offset, flip, shift } from '@floating-ui/vue'
import { useI18n } from 'vue-i18n'

const {t} = useI18n()

const dropdownOpen = ref(false)
const currentTab = ref('new')
const allNotifications = ref([])
const unreadNotifications = ref([])
const dropdown = ref(null)
const floatingEl = ref(null)

const { x, y, strategy, update } = useFloating(dropdown, floatingEl, {
  placement: 'bottom-end',
  middleware: [
    offset({ mainAxis: 18, crossAxis: 0 }),
    flip(),
    shift({ padding: 8 }),
  ],
})

onMounted(() => {
  document.addEventListener('click', outsideClick)
})

onBeforeUnmount(() => {
  document.removeEventListener('click', outsideClick)
})

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value
  if (dropdownOpen.value) {
    nextTick(() => {
      update()
      autoUpdate(dropdown.value, floatingEl.value, update)
    })
  }
}

const outsideClick = (e) => {
  if (dropdown.value && !dropdown.value.contains(e.target)) {
    dropdownOpen.value = false
  }
}

const addNotification = (notification) => {
  const newNotification = {
    ...notification,
    time: new Date().toLocaleTimeString(),
    read: false,
  }
  allNotifications.value.unshift(newNotification)
  unreadNotifications.value.unshift(newNotification)
}

const markAsRead = (notification) => {
  notification.read = true
  unreadNotifications.value = unreadNotifications.value.filter(n => !n.read)
}

const markAllAsRead = () => {
  allNotifications.value.forEach(n => n.read = true)
  unreadNotifications.value = []
}
</script>

<style scoped>
.notification-icon {
  @apply !text-[18px];
}
</style>