<template>
  <div class="device-chart bg-white p-4 shadow-md rounded-md">
    <h3 class="text-lg font-semibold mb-4 text-secondary">Cihaz Dağılımı</h3>
    <div class="relative chart-container">
      <div ref="chartContainer" class="w-full h-full"></div>
      <div v-if="loading" class="absolute inset-0 flex justify-center items-center bg-opacity-75 bg-white">
        <span class="material-symbols-outlined text-6xl text-primary animate-spin">progress_activity</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, nextTick, computed } from 'vue'
import * as echarts from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { TitleComponent, TooltipComponent, LegendComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

echarts.use([PieChart, TitleComponent, TooltipComponent, LegendComponent, CanvasRenderer])

const props = defineProps({
  data: Array,
  loading: Boolean,
})

const chartContainer = ref(null)
let chart = null

const isMobile = ref(false)

const updateMobileStatus = () => {
  isMobile.value = window.innerWidth < 768
}

const colorPalette = ['#FC002B', '#FC9F00', '#4f46e5', '#1e293b', '#22c55e']

const chartOptions = computed(() => {
  const totalUsers = props.data?.reduce((sum, item) => sum + (item.activeUsers || 0), 0) || 0
  const minPercentage = 0.1 // Minimum yüzde değeri (0.1%)
  const sortedData = (props.data || [])
      .map(item => ({
        name: item.device || 'Bilinmeyen',
        value: Math.max(item.activeUsers || 0, totalUsers * minPercentage / 100),
        realValue: item.activeUsers || 0,
        percentage: totalUsers > 0 ? ((item.activeUsers || 0) / totalUsers * 100).toFixed(2) : '0.00',
      }))
      .sort((a, b) => b.realValue - a.realValue)

  return {
    color: colorPalette,
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        const item = sortedData.find(d => d.name === params.name) || {}
        return `${params.name}: ${item.realValue || 0} (${item.percentage || '0.00'}%)`
      },
    },
    legend: {
      orient: isMobile.value ? 'horizontal' : 'vertical',
      left: isMobile.value ? 'center' : '5%',
      top: isMobile.value ? 'bottom' : 'middle',
      itemWidth: 10,
      itemHeight: 10,
      icon: 'circle',
      formatter: (name) => {
        const item = sortedData.find(d => d.name === name) || {}
        return `${name}: ${item.percentage || '0.00'}%`
      },
    },
    series: [
      {
        name: 'Cihaz Türü',
        type: 'pie',
        radius: isMobile.value ? ['40%', '70%'] : ['0%', '75%'],
        center: isMobile.value ? ['50%', '40%'] : ['65%', '50%'],
        data: sortedData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        label: {
          show: false,
        },
        labelLine: {
          show: false,
        },
      },
    ],
  }
})

const initChart = () => {
  if (chartContainer.value) {
    chart = echarts.init(chartContainer.value)
    updateChart()
  }
}

const updateChart = () => {
  if (!chart || !props.data) return
  chart.setOption(chartOptions.value)
  chart.resize()
}

const handleResize = () => {
  updateMobileStatus()
  if (chart) {
    chart.resize()
    updateChart()
  }
}

onMounted(() => {
  updateMobileStatus()
  window.addEventListener('resize', handleResize)
  if (!props.loading) {
    nextTick(() => {
      initChart()
    })
  }
})

onUnmounted(() => {
  window.removeEventListener('resize', handleResize)
  if (chart) {
    chart.dispose()
  }
})

watch(() => props.data, updateChart, { deep: true })

watch(() => props.loading, (newVal) => {
  if (!newVal) {
    nextTick(() => {
      if (chart) {
        chart.resize()
        updateChart()
      } else {
        initChart()
      }
    })
  }
})

watch(isMobile, updateChart)
</script>

<style scoped>
.chart-container {
  height: 300px;
}

@media (min-width: 768px) {
  .chart-container {
    height: 400px;
  }
}
</style>