<template>
  <tab-navigation :tabs="tabs" :initialTab="route.query.tab || 'calendar'" @change-tab="handleTabChange">
    <template #right-content>
      <!--      <custom-button @click="saveTabSettings" button-text="Kaydet" color="header" :isLoading="buttonLoading" :loading-text="'Kaydediliyor'" size="sm"/>-->
    </template>
  </tab-navigation>

  <main>
    <div class="layout-spacing">
      <component :is="currentTabComponent" ref="tabRef" @saving="(val) => buttonLoading = val"/>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, markRaw } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import TabNavigation from '@/components/navigation/tabNavigation.vue'
import Calendar from '@/views/scheduleAssistant/components/calendar/calendar.vue'

const router = useRouter()
const route = useRoute()

const buttonLoading = ref(false)
const tabRef = ref(null)
const currentTab = ref(route.query.tab || 'calendar')

const tabs = [
  { name: 'calendar', label: 'Takvim', component: markRaw(Calendar), header: 'Takvim', description: 'İçerik takvimini görüntüleyin ve yönetin.' },
]

const currentTabComponent = computed(() => {
  const tab = tabs.find(tab => tab.name === currentTab.value)
  return tab ? tab.component : null
})

const handleTabChange = (tabName) => {
  currentTab.value = tabName
  router.push({ query: { ...route.query, tab: tabName } })
}

const saveTabSettings = async () => {
  try {
    if (tabRef.value && typeof tabRef.value.save === 'function') {
      await tabRef.value.save()
    } else {
      console.warn(`Save method not found for tab: ${currentTab.value}`)
    }
  } catch (error) {
    console.error('Error saving settings:', error)
  }
}
</script>