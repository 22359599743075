<template>
  <div class="dashboard space-y-4">
    <div v-if="!isAuthorized || !hasPropertyId" class="flex flex-col items-center justify-center min-h-[400px] p-8 bg-white rounded-lg shadow-sm">
      <div class="text-center space-y-4 mb-6">
        <h3 class="text-xl font-semibold text-gray-900">{{ authMessage.title }}</h3>
        <p class="text-gray-600 max-w-md">{{ authMessage.description }}</p>
      </div>

      <custom-button button-text="Ayarlara Git" color="primary" size="sm" :show-icon="true" icon="settings" @click="navigateToIntegrations"/>
    </div>

    <template v-else>
      <div class="p-4 component-layout">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <MetricCard title="Toplam Ziyaretçi" :value="processedData.summary?.totalActiveUsers ?? 0" :change="0" :loading="loading"/>
          <MetricCard title="Toplam Oturum" :value="processedData.summary?.totalSessions ?? 0" :change="0" :loading="loading"/>
          <MetricCard title="Hemen Çıkma Oranı" :value="formatBounceRate(processedData.summary?.averageBounceRate)" :change="0" :loading="loading"/>
        </div>
      </div>

      <VisitorChart :data="visitorChartData" :loading="loading"/>

      <div class="component-layout">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <CountryChart :data="countryChartData" :loading="loading"/>
          <DeviceChart :data="deviceChartData" :loading="loading"/>
        </div>
      </div>

      <div class="component-layout">
        <SourceTable :data="sourceTableData" :loading="loading"/>
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axios from '@/plugins/axiosInstance'
import { dashboard } from '@/networking/urlManager'
import VisitorChart from './components/visitorChart.vue'
import MetricCard from './components/metricCard.vue'
import CountryChart from './components/countryChart.vue'
import DeviceChart from './components/deviceChart.vue'
import SourceTable from './components/sourceTable.vue'
import CustomButton from '@/components/buttons/customButton.vue'
import { responseHandlerService } from '@/plugins/responseHandlerService'

const store = useStore()
const router = useRouter()

const processedData = ref({
  summary: {},
  byDate: {},
  byCountry: {},
  byDevice: {},
  bySource: {},
})
const loading = ref(false)
const isAuthorized = ref(true)
const hasPropertyId = ref(true)

const authMessage = computed(() => {
  if (!isAuthorized.value) {
    return {
      title: 'Google Entegrasyonu Gerekli',
      description: 'Google Analytics verilerini görüntüleyebilmek için öncelikle entegrasyon ayarlarını tamamlamanız gerekmektedir.',
    }
  } else if (!hasPropertyId.value) {
    return {
      title: 'Google Analytics Mülk Seçimi Gerekli',
      description: 'Verileri görüntüleyebilmek için lütfen ayarlardan bir Google Analytics mülkü seçiniz.',
    }
  }
  return {
    title: '',
    description: '',
  }
})

const navigateToIntegrations = () => {
  router.push('/settings?tab=integrations')
}

const formatBounceRate = (rate) => {
  return rate ? (rate * 100).toFixed(2) + '%' : '0%'
}

const visitorChartData = computed(() => {
  if (!processedData.value.byDate) return []
  return Object.entries(processedData.value.byDate).map(([date, data]) => ({
    date,
    activeUsers: data.activeUsers,
    sessions: data.sessions,
  }))
})

const countryChartData = computed(() => {
  if (!processedData.value.byCountry) return []
  return Object.entries(processedData.value.byCountry)
      .sort((a, b) => b[1].activeUsers - a[1].activeUsers)
      .slice(0, 10)
      .map(([country, data]) => ({
        country,
        activeUsers: data.activeUsers,
      }))
})

const deviceChartData = computed(() => {
  if (!processedData.value.byDevice) return []
  return Object.entries(processedData.value.byDevice).map(([device, data]) => ({
    device,
    activeUsers: data.activeUsers,
  }))
})

const sourceTableData = computed(() => {
  if (!processedData.value.bySource) return []
  return Object.entries(processedData.value.bySource)
      .sort((a, b) => b[1].activeUsers - a[1].activeUsers)
      .slice(0, 10)
      .map(([source, data]) => ({
        source,
        activeUsers: data.activeUsers,
        sessions: data.sessions,
      }))
})

const fetchAnalyticsData = async () => {
  try {
    loading.value = true
    const response = await axios.get(dashboard.getAnalyticsData, {
      params: { assetId: store.state.assetId },
    })

    if (response.data.data) {
      if (response.data.data.authorized === false) {
        isAuthorized.value = false
        return
      }

      if (!response.data.data.hasPropertyId) {
        hasPropertyId.value = false
        return
      }

      processedData.value = response.data.data.processedGeneralData
      isAuthorized.value = true
      hasPropertyId.value = true
    }
  } catch (error) {
    if (error.response?.data?.data?.authorized === false) {
      isAuthorized.value = false
    } else if (error.response?.data?.data?.hasPropertyId === false) {
      hasPropertyId.value = false
    } else {
      responseHandlerService.handleError(error)
    }
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await fetchAnalyticsData()
})
</script>